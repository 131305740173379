<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="gywm">
    <!-- <div style="">....</div> -->
    <div class="gywm_item">
      <img :src="aboutUs.web.img_1" alt="">
      <div class="gywm_info">
        <span>{{ aboutUs.web.item_1 }}</span>
        <span>{{ aboutUs.web.item_2 }}</span>
        <span>{{ aboutUs.web.item_3 }}</span>
      </div>
    </div>
    <!-- 员工 -->
    <div class="gywm_item2">
      <div class="gywm_info2 clearfix">
        <img :src="aboutUs.web.img_2" alt="">
        <div class="gywm_info2_div">
          <span>{{ aboutUs.web.item_4 }}</span>
          <span>{{ aboutUs.web.item_5 }}</span>
          <span>{{ aboutUs.web.item_6 }}</span>
        </div>
      </div>
      <div class="gywm_info2_next">
        <div class="gywm_info2_nextdiv">
          <img class="img1" :src="aboutUs.web.img_3" alt="">
          <img class="img2" :src="aboutUs.mobile.img_1" alt="">
          <div class="numall">
            <div class="num">
              <span>{{ aboutUs.web.item_7 }}</span><span>+</span>
            </div>
            <span class="mess">{{ aboutUs.web.item_8 }}</span>
          </div>
        </div>
        <div class="gywm_info2_nextdiv">
          <img class="img1" :src="aboutUs.web.img_4" alt="">
          <img class="img2" :src="aboutUs.mobile.img_2" alt="">
          <div class="numall">
            <div class="num">
              <span>{{ aboutUs.web.item_9 }}</span><span>+</span>
            </div>
            <span class="mess">{{ aboutUs.web.item_10 }}</span>
          </div>
        </div>
        <div class="gywm_info2_nextdiv">
          <img class="img1" :src="aboutUs.web.img_5" alt="">
          <img class="img2" :src="aboutUs.mobile.img_3" alt="">
          <div class="numall">
            <div class="num">
              <span>{{ aboutUs.web.item_11 }}</span><span>+M²</span>
            </div>
            <span class="mess">{{ aboutUs.web.item_12 }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 发展历程 -->
    <div class="ppyx_item5">
      <div class="ppyx_info5">
        <span></span>
        <span>{{ aboutUs.web.item_13 }}</span>
        <span>{{ aboutUs.web.item_14 }}</span>
      </div>
      <!-- 发展历程 移动端-->
      <div class="ppyx_info5_mobile">
        <span>{{ aboutUs.mobile.item_1 }}</span>
        <span>{{ aboutUs.mobile.item_2 }}</span>
        <span></span>
        <span></span>
      </div>
      <div class="history">
        <div v-for="(item,i) in history" :key="item.num">
          <div class="history_top">
            <div class="history_top_line">
              <img :src="aboutUs.mobile.img_4" alt="">
              <div class="heng" :class="i===history.length-1?'none':''"></div>
            </div>
          </div>
          <div class="history_bottom">
            <span>
              {{ item.time }}
            </span>
            <span>{{ item.info }}</span>
          </div>
        </div>
        
      </div>
    </div>
    <!-- 行业荣誉 -->
    <div class="ppyx_item4">
      <div class="ppyx_info4">
        <span></span>
        <span>{{ aboutUs.web.item_15 }}</span>
        <span>{{ aboutUs.web.item_16 }}</span>
      </div>
      <!-- 行业荣誉 移动端-->
      <div class="ppyx_info4_mobile">
        <span>{{ aboutUs.mobile.item_3 }}</span>
          <span>{{ aboutUs.mobile.item_4 }}</span>
        <span></span>
        <span></span>
      </div>
      <div class="ppyx_info4_text">
        <!-- <div class="ppyx_info4_textdiv">
          <div class="wenzi">
            <span>{{ aboutUs.web.item_17 }} </span>
            <span>{{ aboutUs.web.item_18 }}</span>
          </div>
          <div class="ppyx_info4_textimg" >
            <img :src="item.imgurl" alt="" v-for="item in rongyu1" :key="item.num">
          </div>
        </div> -->
        <div class="ppyx_info4_textdiv">
          <div class="wenzi">
            <span>{{ aboutUs.web.item_19 }}</span>
            <span>{{ aboutUs.web.item_20 }}</span>
          </div>
          <div class="ppyx_info4_textimg">
            <p class="ppyx_info4_textrongyu" v-for="item in rongyu2" :key="item.num">
              {{ item.sub_1 }}<span> · </span>{{item.sub_2}}
            </p>
            <!-- <p></p> -->
            <!-- <div > -->
            <!-- <img :src="item.imgurl" alt="" v-for="item in rongyu2" :key="item.num"> -->
          </div>
        </div>
        <div class="ppyx_info4_textdiv2">
          <div>
            <!-- height="26vw" -->
            <el-carousel :height="luoboImgHeight+'px'" >
              <el-carousel-item>
                <div class="sy_img">
                  <img ref="image" :src="aboutUs.web.img_6" alt="">
                <!-- <span class="slider-1-text">数据、内容与服务驱动的智能整合营销服务商</span>
              <span>Based on “Data,Creativity an( Customized Services"</span>
                <span>we provide major brands an integratec and intelligent marketing service</span> -->
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="sy_img">
                  <img :src="aboutUs.web.img_6" alt="">
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <!-- <img :src="aboutUs.web.img_6" alt=""> -->
          <span>{{ aboutUs.web.item_21 }}</span>
          <span>{{ aboutUs.web.item_22 }}</span>
        </div>
      </div>
    </div>
    <!-- 团队配置 -->
    <div class="ppyx_item3" :style="{ 'background-image': 'url(' + aboutUs.mobile.img_5 + ')' }">
      <div class="ppyx_info3">
        <span></span>
        <span>{{ aboutUs.web.item_23 }}</span>
        <span>{{ aboutUs.web.item_24 }}</span>
      </div>
      <!-- 团队配置 移动端-->
      <div class="ppyx_info3_mobile">
        <span>{{ aboutUs.mobile.item_5 }}</span>
        <span>{{ aboutUs.mobile.item_6 }}</span>
        <span></span>
        <span></span>
      </div>
      <div class="ppyx_info3_text">
        <div class="ppyx_info3_textdiv" v-for="item in team" :key="item.num">
          <img :src="item.imgurl" alt="">
          <div class="ppyx_info3_span">
            <span>{{ item.name }}</span>
            <span>{{ item.english }}</span>
            <span>{{ item.info }}</span>
          </div>
        </div>
      </div>
      <div class="ppyx_info3_yuan">
        <div class="info3_yuan" v-for="item in data" :key="item.num">
          <div class="yuannei">{{ item.person }}</div>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <!-- 服务流程 -->
    <!-- <div class="gywm_item6">
      <div class="gywm_info6">
        <div class="gywm_info6_div">
          <span></span>
          <span>{{ aboutUs.web.item_25 }}</span>
        </div>
        <span class="span">
          {{ aboutUs.web.item_26 }}
        </span>
        <span class="nextdiv">{{ aboutUs.web.item_27 }}</span>
      </div> -->
      <!-- 服务流程 移动端-->
      <!-- <div class="gywm_info6_mobile">
        <span>{{ aboutUs.mobile.item_7 }}</span>
        <span>{{ aboutUs.mobile.item_8 }}</span>
        <span></span>
        <span></span>
        <span class="nextdiv">{{ aboutUs.mobile.item_9 }}</span>
      </div>
      <img :src="aboutUs.web.img_7" alt="">
    </div> -->

  </div>
</template>

<script>
import { aboutUs } from '../config/environment';
export default {
  data(){
    return{
      luoboImgHeight: "",
      aboutUs,
      // 行业荣誉
      // rongyu1:[
      //   {
      //     num:1,
      //     imgurl: aboutUs.web.img_8,
      //   },
      //   {
      //     num:2,
      //     imgurl: aboutUs.web.img_9,
      //   },
      //   {
      //     num:3,
      //     imgurl: aboutUs.web.img_10,
      //   },
      //   {
      //     num:4,
      //     imgurl: aboutUs.web.img_11,
      //   },
      //   {
      //     num:5,
      //     imgurl: aboutUs.web.img_12,
      //   },
      //   {
      //     num:6,
      //     imgurl: aboutUs.web.img_13,
      //   },
      //   {
      //     num:7,
      //     imgurl: aboutUs.web.img_14,
      //   },
      //   {
      //     num:8,
      //     imgurl: aboutUs.web.img_15,
      //   },
      //   {
      //     num:9,
      //     imgurl: aboutUs.web.img_16,
      //   },
      //   {
      //     num:10,
      //     imgurl: aboutUs.web.img_17,
      //   }
      // ],
      rongyu2:[
        {
          num:1,
          imgurl: aboutUs.web.img_18,
          sub_1: aboutUs.web.honor_1,
          sub_2: aboutUs.web.honor_2
        },
        {
          num:2,
          imgurl: aboutUs.web.img_19,
          sub_1: aboutUs.web.honor_3,
          sub_2: aboutUs.web.honor_4,
        },
        {
          num:3,
          imgurl: aboutUs.web.img_20,
          sub_1: aboutUs.web.honor_5,
          sub_2: aboutUs.web.honor_6,
        },
        {
          num:4,
          imgurl: aboutUs.web.img_21,
          sub_1: aboutUs.web.honor_7,
          sub_2: aboutUs.web.honor_8,
        },
        {
          num:5,
          imgurl: aboutUs.web.img_22,
          sub_1: aboutUs.web.honor_9,
          sub_2: aboutUs.web.honor_10,
        },
        {
          num:6,
          imgurl: aboutUs.web.img_23,
          sub_1: aboutUs.web.honor_11,
          sub_2: aboutUs.web.honor_12,
        },
        {
          num:7,
          imgurl: aboutUs.web.img_24,
          sub_1: aboutUs.web.honor_13,
          sub_2: aboutUs.web.honor_14,
        },
        {
          num:8,
          imgurl: aboutUs.web.img_25,
          sub_1: aboutUs.web.honor_15,
          sub_2: aboutUs.web.honor_16,
        },
        {
          num:9,
          imgurl: aboutUs.web.img_26,
          sub_1: aboutUs.web.honor_17,
          sub_2: aboutUs.web.honor_18,
        },
        {
          num:10,
          imgurl: aboutUs.web.img_27,
          sub_1: aboutUs.web.honor_19,
          sub_2: aboutUs.web.honor_20,
        }
      ],
      // 团队配置
      team:[
        {
          num:1,
          imgurl: aboutUs.web.img_28,
          name: aboutUs.web.team_1,
          english: aboutUs.web.team_2,
          info: aboutUs.web.team_3,
        },
        {
          num:2,
          imgurl: aboutUs.web.img_29,
          name: aboutUs.web.team_4,
          english: aboutUs.web.team_5,
          info: aboutUs.web.team_6,
        },
        {
          num:3,
          imgurl: aboutUs.web.img_30,
          name: aboutUs.web.team_7,
          english: aboutUs.web.team_8,
          info: aboutUs.web.team_9,
        },
        {
          num:4,
          imgurl: aboutUs.web.img_31,
          name: aboutUs.web.team_10,
          english: aboutUs.web.team_11,
          info: aboutUs.web.team_12,
        }
      ],
      data:[
        {
          num:1,
          person: aboutUs.web.data_1,
          name: aboutUs.web.data_2,
        },
        {
          num:2,
          person: aboutUs.web.data_3,
          name: aboutUs.web.data_4,
        },
        {
          num:3,
          person: aboutUs.web.data_5,
          name: aboutUs.web.data_6,
        },
        {
          num:4,
          person: aboutUs.web.data_7,
          name: aboutUs.web.data_8,
        },
        {
          num:5,
          person: aboutUs.web.data_9,
          name: aboutUs.web.data_10,
        },
        {
          num:6,
          person: aboutUs.web.data_11,
          name: aboutUs.web.data_12,
        }
      ],
      history:[
        {
          num:1,
          time: aboutUs.web.history_1,
          info: aboutUs.web.history_2,
        },
        {
          num:2,
          time: aboutUs.web.history_3,
          info: aboutUs.web.history_4,
        },
        {
          num:3,
          time: aboutUs.web.history_5,
          info: aboutUs.web.history_6,
        },
        {
          num:4,
          time: aboutUs.web.history_7,
          info: aboutUs.web.history_8,
        },
        {
          num:5,
          time: aboutUs.web.history_9,
          info: aboutUs.web.history_10,
        },
        {
          num:6,
          time: aboutUs.web.history_11,
          info: aboutUs.web.history_12,
        },
        {
          num:7,
          time: aboutUs.web.history_13,
          info: aboutUs.web.history_14,
        },
        {
          num:8,
          time: aboutUs.web.history_15,
          info: aboutUs.web.history_16,
        },
        {
          num:9,
          time: aboutUs.web.history_17,
          info: aboutUs.web.history_18,
        },
        {
          num:10,
          time: aboutUs.web.history_19,
          info: aboutUs.web.history_20,
        },
        // {
        //   num:11,
        //   time: aboutUs.web.history_21,
        //   info: aboutUs.web.history_22,
        // }
      ]
    }
  },
  methods: {
    luoboImgLoad() {
      this.$nextTick(function() {
        this.luoboImgHeight = document.body.clientWidth * 1/4 + 40
      });
    }
  },
  mounted() {
    this.luoboImgLoad()
    window.addEventListener("resize", () => {
      // this.luoboImgHeight = this.$refs.image[0].height;
      this.luoboImgHeight = this.$refs.image.height + 40
      console.info(this.$refs.image.height)
    });
  }
}
</script>

<style lang="less" scoped>
// 屏幕大于 750px 时应用该样式 
@media screen and (min-width: 751px) {
  .gywm{
    margin-top: 1.01rem;
  }
  .gywm_item .gywm_info {
    width: 49%;
  }

  .gywm .gywm_info span:nth-child(2) {
    font-size: 0.32rem;
  }

  .gywm .gywm_info span:nth-child(3) {
    font-size: 0.18rem;
  }

  .gywm_item2 .gywm_info2 img {
    width: 45%;
  }

  .gywm_info2_div {
    width: 53%;
  }

  .gywm_item2 .gywm_info2 {
    background-color: #f6f6f6;
    // border-radius: 20px;
    padding: 4%;
    // margin-bottom: 2%;
  }
  .gywm_info2_nextdiv{
    padding:2%;
    height: 1.6rem;
    .numall{
      height: 0.7rem;
      display: flex;
      flex-direction: column;
    }
  }
  .gywm_info2_nextdiv img {
    width: 28%!important;
    height:auto;
    // height: 65%;
    margin-left: 10%;
    margin-right: 10%;
  }

  .img1 {
    display: block !important;
  }

  .img2 {
    display: none !important;
  }

  .num {
    font-size: 0.36rem;
  }

  .mess {
    font-size: 0.25rem;
  }
  .ppyx_info3,.ppyx_info4,.ppyx_info5,.gywm_info6{
    span:nth-child(1) {
      width: 0.07rem;
      height: 0.4rem;
      background-color: #5383ec;
      display: inline-block;
      border-radius: 0.04rem;
      margin-right: 0.1rem;
    }
    span:nth-child(2) {
      font-family: MiSans-Bold;
      color: black;
      font-size: 0.45rem;
    }
    span:nth-child(3) {
      font-family: MiSans-Bold;
      color: #999;
      font-size: 0.3rem;
      margin-left: 0.2rem;
    }
  }
  .ppyx_info3_mobile,
  .ppyx_info4_mobile ,
  .ppyx_info5_mobile,
  .gywm_info6_mobile{
    display: none;
  }

  .gywm_item6 {
    .gywm_info6_div{
      margin-bottom:-0.53rem;
    }
    img {
      width: 55%!important;
    }
    .nextdiv {
      display: block;
      color: #5383ec;
      font-size: 0.24rem;
      margin-left: 0.2rem;
      line-height: 0.32rem;
      width: 100%;
      margin-top: 0.1rem;
    }
  }

  .ppyx_item4 {
    .ppyx_info4_text {
      justify-content: space-between;

      .ppyx_info4_textdiv {
        font-family: MiSans-Nomal;
        display: flex;
        flex-direction: column;
        width: 45%;
        padding: 2%;
        .wenzi{
          display: flex;
          align-items: center;
          // margin-top: -0.8rem;
          span:nth-child(1){
            font-size: 0.24rem!important;
            margin-right:0.1rem;
          }
          span:nth-child(2){
            font-size: 0.16rem!important;
          }
        }
      }

      span:nth-child(1) {
        font-size: 0.26rem;
      }

      span:nth-child(2) {
        font-size: 0.18rem;
        // display: block;
      }
    }

    .ppyx_info4_textdiv2 {
      width: 45%;
      display: flex;
      flex-direction: column;
    }
  }

  .ppyx_item3 {
    .ppyx_info3_text {
      justify-content: space-between;
    }

    .ppyx_info3_textdiv {
      width: 24%;

      .ppyx_info3_span span:nth-child(2) {
        color: #666;
      }
    }

    .ppyx_info3_yuan {
      justify-content: space-between;
      .info3_yuan span{
        font-size: 0.22rem;
      }
    }
  }
}

/* // 屏幕小于 750px 时应用该样式 */
@media screen and (max-width: 750px) {
  .gywm_item img:nth-child(1) {
    height: 2.5rem;
  }

  .gywm .gywm_info{
    span:nth-child(1) {
      font-size: 0.28rem!important;
      margin-top:5%;
    }
    span:nth-child(2) {
      font-size: 0.18rem;
    }

     span:nth-child(3) {
      font-size: 0.14rem;
      margin-top:5%;
    }
  } 

  .gywm_item2 .gywm_info2 {
    padding: 6%;
    img {
      margin-bottom: 5%;
    }
    .gywm_info2_div span{
      font-size: 0.16rem!important;
    }
  }

  .gywm_info2_next {
    padding: 0 6%;

    .gywm_info2_nextdiv {
      flex-direction: column;
      padding: 6%;
      img {
        width: 78% !important;
        height:65%;
      }
      .numall{
        display: flex;
        flex-direction: column;
        align-items: center;
        .num {
          font-size: 0.18rem;
        }

        .mess {
          font-size: 0.14rem;
        }
      }
    }

    .img1 {
      display: none !important;
    }

    .img2 {
      display: block !important;
    }
  }

  .ppyx_info4,
  .ppyx_info3,
  .gywm_info6 ,
  .ppyx_info5{
    display: none;
  }

  .ppyx_info3_mobile,
  .ppyx_info4_mobile,
  .gywm_info6_mobile ,
  .ppyx_info5_mobile{
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6%;

    span:nth-child(1) {
      font-family: MiSans-Bold;
      font-size: 0.28rem;
    }

    span:nth-child(3) {
      width: 50%;
      display: block;
      height: 0.02rem;
      background-color: #999;
      margin: 0.14rem 0;
    }

    span:nth-child(4) {
      width: 14%;
      display: block;
      height: 0.08rem;
      border-radius: 0.04rem;
      background-color: #5383ec;
      margin-top: -0.18rem;
      margin-bottom: 0.14rem;
    }

    span:nth-child(2) {
      font-size: 0.18rem;
      letter-spacing: 2px;
      color: #666;
    }
  }
  .wenzi{
    margin-top: -0.7rem;
    span:nth-child(1){
      font-size: 0.22rem!important;
    }
    span:nth-child(2){
      font-size: 0.18rem!important;
    }
  }
  .gywm_item6 {
    flex-direction: column;

    .nextdiv {
      font-family: MiSans-Nomal;
      text-align: center;
      font-size: 0.16rem!important;
    }
  }

  .ppyx_item4 {
    .ppyx_info4_text {
      flex-direction: column;
      padding: 5%;

      .ppyx_info4_textdiv {
        font-family: MiSans-Nomal;
        padding: 4%;
        margin-bottom: 5%;
        .ppyx_info4_textimg{
          margin-top:0!important;
        }
      }

      span:nth-child(1) {
        font-size: 0.35rem;
      }

      span:nth-child(2) {
        font-size: 0.25rem;
      }
    }

    .ppyx_info4_textdiv2{
      span:nth-child(2) {
        text-align: center;
        display: block;
        font-size: 0.18rem!important;
        line-height: 0.5rem!important;
      }
      span:nth-child(3){
        font-size: 0.16rem!important;
      }
    } 
  }

  .ppyx_item3 {
    padding:4%!important;
    .ppyx_info3_text {
      flex-wrap: wrap;
      padding: 5%;
    }

    .ppyx_info3_textdiv {
      width: 48%;
      margin-right: 2%;
      margin-bottom: 4%;

      .ppyx_info3_span{
        span:nth-child(1){
          font-size: 0.2rem!important;
        }
        span:nth-child(2) {
          color: #5383ec;
          font-size: 0.14rem!important;
        }
        span:nth-child(3){
          font-size: 0.14rem!important;
        }
      } 
    }

    .ppyx_info3_yuan {
      flex-wrap: wrap;

      .info3_yuan {
        width: 33%;
        margin-bottom: 5%;
        span{
         font-size: 0.16rem;
        }
        .yuannei{
          width:1rem!important;
          height:1rem!important;
          line-height: 0.8rem!important;
          font-size: 0.24rem!important;
        }
      }
    }
  }
}

.sy_img {
  img {
    object-fit: cover;
  }
}
.gywm {
   img {
    width: 100%;
    display: block;
  }

   .gywm_info{
    span:nth-child(1) {
      display: block;
      color: #fff;
      font-family: MiSans-Bold;
      font-size: 0.4rem;
    }

    span:nth-child(2) {
      color: #5383ec;
      font-family: MiSans-Bold;
      display: block;
    }

     span:nth-child(3) {
      display: block;
      font-family: MiSans-Normal;
      color: #ccc;
      letter-spacing: 2px;
    }
   } 

  .gywm_item {
    position: relative;
  }

  .gywm_item .gywm_info {
    position: absolute;
    top: 20%;
    left: 8%;
  }
}

/* 员工开始 */
.gywm_item2 {
  width: 100%;
  padding: 5%;

   .gywm_info2 {
    border-radius: 0.2rem;
    margin-bottom: 2%;
  }

   .gywm_info2 img {
    height: auto;
    border-radius: 0.2rem;
    float: left;
  }

  .gywm_info2_div {
    display: inline;
    float: left;
    margin-left: 2%;
  }

   .gywm_info2 span {
    display: block;
    color: #666;
    font-size: 0.18rem;
    font-family: MiSans-Normal;
    line-height: 0.3rem;
    margin-bottom: 0.1rem;
  }

  .gywm_info2_next {
    display: flex;
    justify-content: space-between;
    height: 21%;
  }

  .gywm_info2_nextdiv {
    width: 31%;
    display: flex;
    align-items: center;
    background-color: #f3f7fe;
    border-radius: 0.15rem;
  }

  // .gywm_info2_nextdiv img {
    // height: 65%;
  // }

  .num {
    font-family: MiSans-Bold;
    color: black;
  }

  .num span:nth-child(2) {
    color: #5383ec;
  }

  .mess {
    font-family: MiSans-Normal;
    color: #444547;
    white-space: nowrap;
  }
}

.clearfix::after {
  content: '';
  clear: both;
  display: block;
}

/* 员工结束 */
/* 服务流程开始 */
.gywm_item6 {
  width: 100%;
  display: flex;
  padding: 5% 7%;

   .gywm_info6 {
    width: 45%;
  }

  //  .gywm_info6 .gywm_info6_div {
    // margin-top: 10%;
  // }

   .gywm_info6 .gywm_info6_div span:nth-child(1) {
    width: 0.07rem;
    height: 0.4rem;
    background-color: #5383ec;
    display: inline-block;
    border-radius: 0.04rem;
    margin-right: 0.1rem;
  }

   .gywm_info6 .gywm_info6_div span:nth-child(2) {
    font-family: MiSans-Bold;
    color: black;
    font-size: 0.45rem;
  }

   .gywm_info6 .span {
    font-family: MiSans-Bold;
    color: #999;
    font-size: 0.3rem;
    margin-left: 0.2rem;
  }

  .nextdiv {
    display: block;
    color: #5383ec!important;
    font-size: 0.24rem;
    margin-left: 0.2rem!important;
    font-family: MiSans-Nomal!important;
    line-height: 0.32rem;
    width: 100%;
    margin-top: 0.1rem;
  }

  img {
    border-radius: 0.15rem;
  }
}

/* 服务流程结束 */
/* 团队配置 */
.ppyx_item3 {
  width: 100%;
  padding: 7%;
  // background-image: url(../assets/image/02web端切图（1X）/web_banner04.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;

   .ppyx_info3_text {
    display: flex;
    margin-top: 4%;
    margin-bottom: 4%;
  }

   .ppyx_info3_textdiv {
    display: flex;
    flex-direction: column;
    border-radius: 0.15rem;
    background-color: #f6f6f6;
  }

   .ppyx_info3_textdiv img {
    // height: 40% !important;
    border-top-left-radius: 0.15rem;
    border-top-right-radius: 0.15rem;
  }

   .ppyx_info3_textdiv .ppyx_info3_span {
    padding: 5% 8% 10% 8%;
    height: 60%;
  }

   .ppyx_info3_textdiv .ppyx_info3_span span {
    display: block;
  }

   .ppyx_info3_textdiv .ppyx_info3_span span:nth-child(1) {
    font-size: 0.28rem;
    font-family: 'MiSans-Bold';
    padding-top: 6%;
  }

   .ppyx_info3_textdiv .ppyx_info3_span span:nth-child(2) {
    font-family: MiSans-Bold;
    font-size: 0.17rem;
    // color: #666;
    margin-bottom: 5%;
  }

   .ppyx_info3_textdiv .ppyx_info3_span span:nth-child(3) {
    font-size: 0.14rem;
    line-height: 0.28rem;
    color: #666;
  }

  .ppyx_info3_yuan {
    display: flex;
    // align-items: center;
  }

  .ppyx_info3_yuan .info3_yuan {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .yuannei {
    text-align: center;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    line-height: 1.1rem;
    border: 0.1rem solid #b3c8f1;
    background-color: #6994ed;
    font-size: 0.35rem;
    color: #fff;
    font-family: 'MiSans-Bold';
  }

  .ppyx_info3_yuan .info3_yuan span {
    // font-size: 0.22rem;
    margin-top: 8%;
  }
}

/* 行业荣誉 */
.ppyx_item4 {
  width: 100%;
  padding: 7%;

  .ppyx_info4_text {
    width: 100%;
    display: flex;
    margin-top: 3%;
  }

   .ppyx_info4_textdiv {
    background-color: #f3f7fe;
    border-radius: 0.15rem;
  }

   .ppyx_info4_textdiv span:nth-child(1) {
    color: #5383fc;
    font-family: 'MiSans-Bold';
  }

   .ppyx_info4_textdiv span:nth-child(2) {
    color: #333;
    font-family: 'MiSans-Normal';
    white-space: nowrap;
  }

   .ppyx_info4_textimg {
    // display: flex;
    // justify-content: flex-start;
    // flex-wrap: wrap;
    margin-top: 0.2%;
  }

  .ppyx_info4_textrongyu {
    // display: flex;
    // flex-direction: column;
    font-size: 0.2rem;
    margin-top: 4%;
    // margin-top: 5%;
  }

   .ppyx_info4_textimg img {
    width: 40%;
    margin-right: 10%;
    margin-bottom: 3%;
    border-radius: 0.15rem;
  }
  .ppyx_info4_textdiv2{
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      border-radius: 0.15rem;
    }
    span:nth-child(2) {
      font-family: 'MiSans-Bold';
      font-size: 0.29rem;
      line-height: 0.82rem;
      text-align: center;
    }
    span:nth-child(3) {
      font-family: 'MiSans-Normal';
      font-size: 0.18rem;
      color: #666;
      line-height: 0.3rem;
      text-align: left;
    }
  }
}
// 发展历程
.ppyx_item5{
  width:100%;
  padding:7%;
  background-color: #f6f6f6;
  .history{
    display: flex;
    overflow-x: scroll;
    padding:0 0.4rem;
    width: 100%;
    position: relative;
    padding-left: 0.5rem;
    margin-top:5%;
  
    .history_top{
      width:3.5rem;
      height:0.6rem;
      margin-bottom:0.3rem;
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      border-radius:0.3rem;
      margin-left:-0.5rem;
      .history_top_line{
        display: flex;
        align-items: center;
        img{
          width:0.4rem;
          height:0.4rem;
        }
        .heng{
          margin:0 10px;
          width: 2rem;
          height:1px ;
          background-color: #ccc;
        }
        .none{
          display: none;
        }
      }
    }
    .history_bottom{
      width:2rem;
      margin-left:-0.5rem;
      span{
        display: block;
      }
      span:nth-child(1){
        font-size: 0.24rem;
        font-family:MiSans-Bold;
      }
      span:nth-child(2){
        font-size: 0.18rem;
        color:#999;
        font-family:MiSans-Nomal ;
      }
    }
  }
}
</style>