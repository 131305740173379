<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="xgyx">
    <!-- 营销数据整合与洞察 -->
    <div class="xgyx_item">
      <img :src="marketingData.web.img_1" alt="">
      <div class="xgyx_info">
        <span>{{marketingData.web.item_1}}</span>
        <span>{{ marketingData.web.item_2 }}</span>
      </div>
    </div>
    <!-- 度量、洞察、优化 -->
    <div class="xgyx_item2">
      <div class="xgyx_info2">
        <span>{{ marketingData.web.item_3 }}</span>
        <span>{{ marketingData.web.item_4 }}</span>
        <div class="xgyx_info2_div">
          <span>{{marketingData.web.item_41}}</span>
          <span>{{ marketingData.web.item_5 }}</span>
          <span>{{ marketingData.web.item_6 }}</span>
        </div>
      </div>
      <img :src="marketingData.web.img_2" alt="">
    </div>
    <!-- 度量、洞察、优化  移动端 -->
    <div class="xgyx_item2_mobile">
      <div class="xgyx_info2_mobile">
        <span>{{ marketingData.mobile.item_1 }}</span>
        <span>{{ marketingData.mobile.item_2 }}</span>
        <span></span>
        <span></span>
      </div>
      <img :src="marketingData.web.img_3" alt="">
      <div class="xgyx_info2_div_mobile">
        <span>{{ marketingData.mobile.item_49 }}</span>
        <span>{{ marketingData.mobile.item_3 }}</span>
        <span>{{ marketingData.mobile.item_4 }}</span>
      </div>
    </div>
    <!-- 服务 -->
    <div class="ppyx_item3" :style="{ 'background-image': 'url(' + marketingData.web.img_9 + ')' }">
      <div class="ppyx_info3">
        <span></span>
        <span>{{ marketingData.web.item_7 }}</span>
        <span>{{ marketingData.web.item_8 }}</span>
      </div>
      <!-- 服务 移动端  -->
      <div class="ppyx_info3_mobile">
        <span>{{ marketingData.mobile.item_5 }}</span>
        <span>{{ marketingData.mobile.item_6 }}</span>
        <span></span>
        <span></span>
      </div>
      <div class="ppyx_info3_text">
        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <div class="ppyx_info3_textdiv" v-for="its in server" :key="its.num">
          <img :src="its.imgurl" alt="">
          <div class="ppyx_info3_span">
            <span>{{ its.name }}</span>
            <span>{{ its.info }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 案例 -->
    <!-- <div class="ppyx_item4">
      <div class="ppyx_in">
        <span></span>
        <span>{{ marketingData.web.item_9 }}</span>
        <span>{{ marketingData.web.item_10 }}</span>
      </div> -->
      <!-- 案例   移动端-->
      <!-- <div class="ppyx_in_mobile">
        <span>{{ marketingData.mobile.item_7 }}</span>
        <span>{{ marketingData.mobile.item_8 }}</span>
        <span></span>
        <span></span>
      </div> -->
      <!-- 案例   移动端 -->
      <!-- <div class="ppyx_item4_info_mobile" v-for="item in cases" :key="item.num">
        <div class="ppyx_info4">
          <span>{{ item.name }}</span>
          <span>{{ item.itter }}</span>
          <span>{{ item.info }}</span>
          <span>{{ marketingData.mobile.item_9 }}<span class="blue2"> {{ item.pu }}</span> </span>
          <span>{{ marketingData.mobile.item_10 }}<span class="blue2"> {{ item.hu }}</span></span>
        </div>
        <img :src="item.imgurl" alt="">
      </div>
      <div class="ppyx_item4_info">
        <div class="ppyx_info4">
          <span>{{ marketingData.web.item_11 }}</span>
          <span>{{ marketingData.web.item_12 }}</span>
          <span>{{ marketingData.web.item_13 }}</span>
          <span>{{ marketingData.web.item_14 }}<span class="blue2"> {{ marketingData.web.item_15 }}</span> {{ marketingData.web.item_16 }}<span class="blue2"> {{ marketingData.web.item_17 }}</span></span>
          <img :src="marketingData.web.img_4" alt="">
        </div>
        <img :src="marketingData.web.img_5" alt="">
      </div>
      <div class="ppyx_item4_text">
        <img :src="marketingData.web.img_6" alt="">
        <div class="ppyx_item4_textdiv">
          <div class="ppyx_item4_textspan">
            <span>{{ marketingData.web.item_18 }}</span>
            <span>{{ marketingData.web.item_19 }}</span>
            <span>{{ marketingData.web.item_20 }}</span>
          </div>
          <div class="ppyx_item4_textimg" :style="{ 'background-image': 'url(' + marketingData.mobile.img_14 + ')' }">
            <div class="textinfo">
              <span class="imgtext">{{ marketingData.web.item_21 }}<span class="blue2"> {{ marketingData.web.item_22 }}</span> </span>
              <span class="imgtext">{{ marketingData.web.item_23 }}<span class="blue2"> {{ marketingData.web.item_24 }}</span></span>
            </div>
          </div>
        </div>
        <div class="ppyx_item4_textdiv">
          <div class="ppyx_item4_textspan">
            <span>{{ marketingData.web.item_25 }}</span>
            <span>{{ marketingData.web.item_26 }}</span>
            <span>{{ marketingData.web.item_27 }}</span>
          </div>
          <div class="ppyx_item4_textimg" :style="{ 'background-image': 'url(' + marketingData.mobile.img_15 + ')' }">
            <div class="textinfo">
              <span class="imgtext">{{ marketingData.web.item_28 }}<span class="blue2"> {{ marketingData.web.item_29 }}</span> </span>
              <span class="imgtext">{{ marketingData.web.item_30 }}<span class="blue2"> {{ marketingData.web.item_31 }}</span></span>
            </div>
          </div>
        </div>
        <div class="ppyx_item4_textdiv">
          <div class="ppyx_item4_textspan">
            <span>{{ marketingData.web.item_32 }}</span>
            <span>{{ marketingData.web.item_33 }}</span>
            <span>{{ marketingData.web.item_34 }}</span>
          </div>
          <div class="ppyx_item4_textimg" :style="{ 'background-image': 'url(' + marketingData.mobile.img_16 + ')' }">
            <div class="textinfo">
              <span class="imgtext">{{ marketingData.web.item_35 }}<span class="blue2"> {{ marketingData.web.item_36 }}</span> </span>
              <span class="imgtext">{{ marketingData.web.item_37 }}<span class="blue2"> {{ marketingData.web.item_38 }}</span></span>
            </div>
          </div>
        </div>
        <img :src="marketingData.web.img_7" alt="">
      </div>
    </div> -->
    <!-- 7*24 -->
    <div class="ppyx_item6">
      <div class="ppyx_info6">
        <span></span>
        <span>{{ marketingData.web.item_39 }} </span>
        <span>{{ marketingData.web.item_40 }}</span>
      </div>
      <!-- 7*24 yidong-->
      <div class="ppyx_info6_mobile">
        <span>{{ marketingData.mobile.item_11 }} </span>
        <span>{{ marketingData.mobile.item_12 }}</span>
        <span></span>
        <span></span>
      </div>
      <img class="img1" :src="marketingData.web.img_8" alt="">
      <img class="img2" :src="marketingData.mobile.img_1" alt="">
      <div class="ppyx_info6_img">
        <div class="ppyx_info6_imgdiv" v-for="item in seven" :key="item.num">
          <img :src="item.imgurl" alt="">
          <span>{{ item.name }}</span>
          <span>{{ item.info }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { coreBusiness } from "../config/environment";
export default {
  data() {
    return {
      marketingData: coreBusiness.marketingData,

      // 服务
      server: [
        {
          num: '1',
          imgurl: coreBusiness.marketingData.mobile.img_2,
          name: coreBusiness.marketingData.mobile.item_13,
          info: coreBusiness.marketingData.mobile.item_14,
        },
        {
          num: '2',
          imgurl: coreBusiness.marketingData.mobile.img_3,
          name: coreBusiness.marketingData.mobile.item_15,
          info: coreBusiness.marketingData.mobile.item_16,
        },
        // {
        //   num: '3',
        //   imgurl: coreBusiness.marketingData.mobile.img_4,
        //   name: coreBusiness.marketingData.mobile.item_17,
        //   info: coreBusiness.marketingData.mobile.item_18,
        // },
        // {
        //   num: '4',
        //   imgurl: coreBusiness.marketingData.mobile.img_5,
        //   name: coreBusiness.marketingData.mobile.item_19,
        //   info: coreBusiness.marketingData.mobile.item_20,
        // }
      ],
      // 案例 移动端
      cases: [
        // {
        //   num: 1,
        //   name: coreBusiness.marketingData.mobile.item_21,
        //   itter: coreBusiness.marketingData.mobile.item_22,
        //   info: coreBusiness.marketingData.mobile.item_23,
        //   pu: coreBusiness.marketingData.mobile.item_24,
        //   hu: coreBusiness.marketingData.mobile.item_25,
        //   imgurl: coreBusiness.marketingData.mobile.img_6,
        // },
        // {
        //   num: 2,
        //   name: coreBusiness.marketingData.mobile.item_26,
        //   itter: coreBusiness.marketingData.mobile.item_27,
        //   info: coreBusiness.marketingData.mobile.item_28,
        //   pu: coreBusiness.marketingData.mobile.item_29,
        //   hu: coreBusiness.marketingData.mobile.item_30,
        //   imgurl: coreBusiness.marketingData.mobile.img_7,
        // },
        // {
        //   num: 3,
        //   name: coreBusiness.marketingData.mobile.item_31,
        //   itter: coreBusiness.marketingData.mobile.item_32,
        //   info: coreBusiness.marketingData.mobile.item_33,
        //   pu: coreBusiness.marketingData.mobile.item_34,
        //   hu: coreBusiness.marketingData.mobile.item_35,
        //   imgurl: coreBusiness.marketingData.mobile.img_8,
        // },
        // {
        //   num: 4,
        //   name: coreBusiness.marketingData.mobile.item_36,
        //   itter: coreBusiness.marketingData.mobile.item_37,
        //   info: coreBusiness.marketingData.mobile.item_38,
        //   pu: coreBusiness.marketingData.mobile.item_39,
        //   hu: coreBusiness.marketingData.mobile.item_40,
        //   imgurl: coreBusiness.marketingData.mobile.img_9,
        // }
      ],
      // 7*12
      seven:[
        {
          num:1,
          imgurl: coreBusiness.marketingData.mobile.img_10,
          name: coreBusiness.marketingData.mobile.item_41,
          info: coreBusiness.marketingData.mobile.item_42,
        },
        {
          num:2,
          imgurl: coreBusiness.marketingData.mobile.img_11,
          name: coreBusiness.marketingData.mobile.item_43,
          info: coreBusiness.marketingData.mobile.item_44,
        },
        {
          num:3,
          imgurl: coreBusiness.marketingData.mobile.img_12,
          name: coreBusiness.marketingData.mobile.item_45,
          info: coreBusiness.marketingData.mobile.item_46,
        },
        {
          num:4,
          imgurl: coreBusiness.marketingData.mobile.img_13,
          name: coreBusiness.marketingData.mobile.item_47,
          info: coreBusiness.marketingData.mobile.item_48,
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
// 屏幕大于 750px 时应用该样式 
@media screen and (min-width: 751px) {
  .xgyx_item {
    display: block;
  }

  .xgyx_item2 {
    display: block;
  }

  .xgyx_item2_mobile {
    display: none !important;
  }
  .ppyx_item3{
    // background-image: url(../assets/image/02web端切图（1X）/web_banner04.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .ppyx_item4 {
    padding: 6%;
  }

  .ppyx_info3,
  .ppyx_in,
  .ppyx_info6 {
    display: block;
  }

  .ppyx_info3_mobile,
  .ppyx_in_mobile,
  .ppyx_item4_info_mobile,
  .ppyx_info6_mobile {
    display: none;
  }

  .ppyx_info3_text {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;

    .ppyx_info3_textdiv {
      // display: flex;
      // flex-direction: column;
      height: 450px;
      width: 24%;
      // border-radius: 15px;
      background-color: #f6f6f6;

      .ppyx_info3_span span:nth-child(1) {
        font-size: 0.26rem;
        font-family: MiSans-Normal;
        padding-top: 6%;
        padding-bottom: 9%;
      }

      .ppyx_info3_span span:nth-child(2) {
        font-family: MiSans-Bold;
        font-size: 0.18rem;
        color: #666;
      }
    }
  }

  .ppyx_item4_info,
  .ppyx_item4_text {
    display: block;
  }
  .img1{
    display: block;
  }
  .img2{
    display: none!important;
  }
  .ppyx_info6_imgdiv {
    width: 23%;
    span:nth-child(3){
      padding:0 12%;
    }
  }
}

/* // 屏幕小于 750px 时应用该样式 */
@media screen and (max-width: 750px) {
  .xgyx_item {
    display: none;
  }

  .xgyx_item2 {
    display: none !important;
  }

  .xgyx_item2_mobile {
    display: block !important;
  }

  .ppyx_item3 {
    padding-bottom: 0 !important;
    background-color: #f6f6f6!important;
  }

  .ppyx_info3,
  .ppyx_in,
  .ppyx_item4_info,
  .ppyx_item4_text,
  .ppyx_info6 {
    display: none !important;
  }

  .ppyx_info3_mobile,
  .ppyx_in_mobile,
  .ppyx_info6_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6% 0;

    span:nth-child(1) {
      font-family: MiSans-Bold;
      font-size: 0.28rem;
    }

    span:nth-child(3) {
      width: 50%;
      display: block;
      height: 0.02rem;
      background-color: #999;
      margin: 0.14rem 0;
    }

    span:nth-child(4) {
      width: 14%;
      display: block;
      height: 0.08rem;
      border-radius: 0.04rem;
      background-color: #5383ec;
      margin-top: -0.18rem;
      margin-bottom: 0.14rem;
    }

    span:nth-child(2) {
      font-size: 0.18rem;
      letter-spacing: 2px;
      color: #666;
    }
  }

  .ppyx_info3_text {
    padding: 5%;

    .ppyx_info3_textdiv {
      width: 100%;
      margin-bottom: 5%;
      background-color: #fff;

      .ppyx_info3_span span:nth-child(1) {
        font-size: 0.28rem;
        font-family: MiSans-Bold;
        // padding-top: 6%;
        padding-bottom: 6%;
      }

      .ppyx_info3_span span:nth-child(2) {
        font-family: MiSans-Normal;
        font-size: 0.18rem;
        color: #666;
      }
    }
  }

  .ppyx_item4 {
    padding: 10%;
  }

  .ppyx_item4_info_mobile {
    display: block;
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6;
    border-radius: 0.15rem;
    margin-bottom: 5%;

    .ppyx_info4 {
      display: flex;
      flex-direction: column;
      padding: 7%;

      span:nth-child(1) {
        font-family: MiSans-Bold;
        font-size: 0.28rem;
      }

      span:nth-child(2) {
        color: #9c9c9c;
        font-family: MiSans-Normal;
        font-size: 0.2rem;
        padding-bottom: 4%;
        border-bottom: 1px solid #ccc;
      }

      span:nth-child(3) {
        font-size: 0.16rem;
        color: #999;
        margin-bottom: 4%;
        padding-top: 4%;
        line-height: 0.3rem;
      }

      span:nth-child(4),
      span:nth-child(5) {
        font-size: 0.24rem;
      }

      .blue2 {
        font-size: 0.3rem !important;
      }
    }

    img {
      height: 40%;
      width: 100%;
      border-bottom-left-radius: 0.15rem;
      border-bottom-right-radius: 0.15rem;
    }
  }
  .img1{
    display: none!important;
  }
  .img2{
    display: block;
  }
  .ppyx_info6_imgdiv {
    width: 48%;
    margin-bottom:5%;
    span:nth-child(2){
      font-size: 0.24rem!important;
    }
    span:nth-child(3){
      padding:0 15%;
      font-size: 0.16rem!important;
    }
  }
}

/* 直播与短视频解决方案 */
.xgyx img {
  width: 100%;
  display: block;
}

.xgyx .xgyx_info span:nth-child(1) {
  display: block;
  color: #fff;
  font-family: MiSans-Bold;
  font-size: 0.47rem;
}

.xgyx .xgyx_info span:nth-child(2) {
  color: #5383ec;
  font-family: MiSans-Bold;
  display: block;
  font-size: 0.32rem;
}

.xgyx_item {
  position: relative;
}

.xgyx_item .xgyx_info {
  position: absolute;
  top: 18%;
  left: 8%;
  width: 49%;
}

/* 媒体即卖场 */
.xgyx_item2 {
  width: 100%;
  padding: 6%;
  display: flex;
  justify-content: space-between;

  .xgyx_info2 span {
    display: block;
  }

   .xgyx_info2 span:nth-child(1) {
    font-family: MiSans-Bold;
    color: black;
    font-size: 0.5rem;
  }

   img {
    width: 42%;
    border-radius: 0.15rem;
  }

   .xgyx_info2 {
    width: 50%;
  }

   .xgyx_info2 span:nth-child(2) {
    font-family: MiSans-Normal;
    font-size: 0.28rem;
    color: #666;
    /* line-height: 40px; */
    /* width: 57%; */
    margin-top: 5%;
    margin-bottom: 5%;
  }

   .xgyx_info2 .xgyx_info2_div {
    display: flex;
    justify-content: space-between;
  }

   .xgyx_info2 .xgyx_info2_div span {
    font-size: 0.17rem;
    color: black !important;
    ;
    font-family: 'MiSans-Normal';
    background-color: #f6f6f6;
    border-radius: 0.15rem;
    // text-align: center;
    width: 48%;
    padding: 5%;
    letter-spacing: 2px;
    line-height: 0.3rem;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 20px;
  }
}

/* 内容+运营+数据 移动端 */
.xgyx_item2_mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10%;

  .xgyx_info2_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6%;

    span:nth-child(1) {
      font-family: MiSans-Bold;
      font-size: 0.28rem;
    }

    span:nth-child(3) {
      width: 50%;
      display: block;
      height: 0.02rem;
      background-color: #999;
      margin: 0.14rem 0;
    }

    span:nth-child(4) {
      width: 14%;
      display: block;
      height: 0.08rem;
      border-radius: 0.04rem;
      background-color: #5383ec;
      margin-top: -0.18rem;
      margin-bottom: 0.14rem;
    }

    span:nth-child(2) {
      font-size: 0.18rem;
      letter-spacing: 2px;
      color: #666;
    }
  }

  img {
    border-radius: 0.15rem;
    margin-bottom: 5%;
  }

  .xgyx_info2_div_mobile {
    display: flex;
    flex-direction: column;
    font-size: 0.18rem;
    font-family: MiSans-Normal;
    line-height: 0.4rem;
    letter-spacing: 2px;
  }
}

/* 服务 */
.ppyx_item3 {
  width: 100%;
  padding: 7%;
  // background-image: url(../assets/image/02web端切图（1X）/web_banner04.png);
  background-size: cover;
  background-repeat: no-repeat;
}

/* 7*12start */
.ppyx_item6 {
  width: 100%;
  padding: 6% 7%;

  img {
    width: 100%;
    margin-top: 3%;
    border-radius: 0.15rem;
  }

  .ppyx_info6_img {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3%;

    .ppyx_info6_imgdiv {
    // width: 23%;
    margin-right: 2%;
    padding: 4% 0;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    background-color: #f3f7fe;
    border-radius: 0.15rem;

    img {
      width: 35%;
      margin-bottom: 4%;
    }
    span:nth-child(2) {
      font-size: 0.34rem;
      font-family: 'MiSans-Bold';
      line-height: 0.64rem;
    }
    span:nth-child(3) {
      color: #666;
      font-size: 0.22rem;
      display: block;
      // padding: 0px 12%;
      letter-spacing: 2px;
    }
    }
  }
}

/* 7*12end */
.ppyx_item3 .ppyx_info3 span:nth-child(1),
.ppyx_item4 .ppyx_in span:nth-child(1),
.ppyx_item6 .ppyx_info6 span:nth-child(1) {
  width: 0.07rem;
  height: 0.4rem;
  background-color: #5383ec;
  display: inline-block;
  border-radius: 0.04rem;
  margin-right: 0.1rem;
}

.ppyx_item3 .ppyx_info3 span:nth-child(2),
.ppyx_item4 .ppyx_in span:nth-child(2),
.ppyx_item6 .ppyx_info6 span:nth-child(2) {
  font-family: MiSans-Bold;
  color: black;
  font-size: 0.45rem;
}

.ppyx_item3 .ppyx_info3 span:nth-child(3),
.ppyx_item4 .ppyx_in span:nth-child(3),
.ppyx_item6 .ppyx_info6 span:nth-child(3) {
  font-family: MiSans-Bold;
  color: #999;
  font-size: 0.3rem;
  margin-left: 0.2rem;
}

.ppyx_item3 .ppyx_info3_text {
  // display: flex;
  // justify-content: space-between;
  margin-top: 5%;
}

.ppyx_item3 .ppyx_info3_textdiv {
  display: flex;
  flex-direction: column;
  // width: 24%;
  border-radius: 0.15rem;
  margin-right: 5%;
  // background-color: #f6f6f6;
}

.ppyx_item3 .ppyx_info3_textdiv img {
  height: 40% !important;
  border-top-left-radius: 0.15rem;
  border-top-right-radius: 0.15rem;
  object-fit: cover;
}

.ppyx_item3 .ppyx_info3_textdiv .ppyx_info3_span {
  padding: 5% 8% 10% 8%;
  height: 70%;
}

.ppyx_item3 .ppyx_info3_textdiv .ppyx_info3_span span {
  display: block;
}

// .ppyx_item3 .ppyx_info3_textdiv .ppyx_info3_span span:nth-child(1){
//   font-size: 26px;
//   font-family: MiSans-Normal;
//   padding-top: 6%;
//   padding-bottom: 9%;
// }
.ppyx_item3 .ppyx_info3_textdiv .ppyx_info3_span span:nth-child(2) {
  font-family: MiSans-Bold;
  font-size: 0.18rem;
  color: #666;
}

/* 案例 */
.ppyx_item4 {
  width: 100%;
  padding: 6%;
  padding-bottom: 10%;
}

.ppyx_item4 .ppyx_in {
  margin-bottom: 5%;
}

.ppyx_item4_info {
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f6;
  border-radius: 0.15rem;
}

.ppyx_item4_info img {
  width: 42%;
  border-top-right-radius: 0.15rem;
  border-bottom-right-radius: 0.15rem;
}

.ppyx_item4_info .ppyx_info4 {
  width: 52%;
  padding: 3% 5%;
}

.ppyx_info4 img {
  width: 50px;
  height: 50px;
  margin-top: 5%;
}

/* .ppyx_info4 img:hover{
  scale: 1.3;
} */
.ppyx_item4_info .ppyx_info4 span {
  display: block;
}

.ppyx_item4_info .ppyx_info4 span:nth-child(1) {
  font-family: MiSans-Bold;
  font-size: 0.3rem;
}

.ppyx_item4_info .ppyx_info4 span:nth-child(2) {
  color: #9c9c9c;
  font-family: MiSans-Normal;
  font-size: 0.26rem;
  margin-bottom: 5%;
}

.ppyx_item4_info .ppyx_info4 span:nth-child(3) {
  font-size: 0.18rem;
  color: #999;
  margin-bottom: 4%;
  line-height: 0.3rem;
}

.ppyx_item4_info .ppyx_info4 span:nth-child(4) {
  font-size: 0.24rem;
}

.blue2 {
  color: #5383ec !important;
  font-size: 0.3rem !important;
  font-family: MiSans-Bold !important;
  display: inline !important;
}

.ppyx_item4_text {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
  position: relative;
}

.ppyx_item4_text .ppyx_item4_textdiv {
  width: 32%;
  background-color: #f3f7fe;
  border-radius: 0.15rem;
}

.ppyx_item4_text .ppyx_item4_textdiv .ppyx_item4_textspan {
  padding: 9% 6%;
  height: 60%;
}

.ppyx_item4_text .ppyx_item4_textdiv .ppyx_item4_textspan span {
  display: block;
}

.ppyx_item4_text .ppyx_item4_textdiv .ppyx_item4_textspan span:nth-child(1) {
  font-size: 0.26rem;
  font-family: MiSans-Bold;
}

.ppyx_item4_text .ppyx_item4_textdiv .ppyx_item4_textspan span:nth-child(2) {
  font-size: 0.22rem;
  font-family: MiSans-Normal;
  color: #999;
  padding-bottom: 5%;
  border-bottom: 1px solid #ccc;
}

.ppyx_item4_text .ppyx_item4_textdiv .ppyx_item4_textspan span:nth-child(3) {
  color: #666;
  font-size: 0.18rem;
  line-height: 0.3rem;
  padding-top: 5%;
  font-family: MiSans-Normal;
}

.ppyx_item4_text .ppyx_item4_textdiv .ppyx_item4_textimg {
  padding: 9% 6%;
  height: 60%;
  // background-image: url(../assets/image/02web端切图（1X）/web_banner18.png);
  background-size: 100%;
  background-repeat: no-repeat;
  border-bottom-left-radius: 0.15rem;
  border-bottom-right-radius: 0.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textinfo {
  width: auto;
  text-align: center;
}

.imgtext {
  display: block;
  color: #fff;
  font-family: MiSans-Normal;
  font-size: 0.24rem;
}

.ppyx_item4_text img {
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
}

.ppyx_item4_text img:nth-child(1) {
  top: 55%;
  left: -5%;
  transform: rotate(180deg);
}

.ppyx_item4_text img:nth-child(5) {
  top: 55%;
  right: -5%;
}

.ppyx_item4_text img:hover {
  scale: 1.2;
}
</style>