// 1.引入路由
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 引入组件
import Index from '../components/Index'
import sy from '../pages/sy'
import gywm from '../pages/gywm'
import hxyw from '../pages/hxyw'
import hzzy from '../pages/hzzy'
import jrwm from '../pages/jrwm'
import lxwm from '../pages/lxwm'

import ljhz from '../pages/ljhz'

import ppyx from '../pages/ppyx'
import xgyx from '../pages/xgyx'
import zbydsp from '../pages/zbydsp'
import yxsjzh from '../pages/yxsjzh'

// 路由配置规则
const routes = [
    {
        path: '/index',
        component: Index,
        children:[
            {
                path:'sy',//访问/index/home  首页
                component: sy,
                meta: {
                    title: '让每一次连接，更有价值——新普互联（北京）科技有限公司'
                }
            },
            {
                path:'gywm',//关于我们
                component: gywm,
                meta: {
                    title: '关于我们——新普互联（北京）科技有限公司'
                }
            },
            {
                path:'hxyw',//核心业务
                component: hxyw,
                children:[
                    {
                        path:'ppyx',//品牌营销
                        component: ppyx,
                        meta: {
                            title: '品牌营销——新普互联（北京）科技有限公司'
                        }
                    },
                    {
                        path:'xgyx',//效果营销
                        component: xgyx,
                        meta: {
                            title: '效果营销——新普互联（北京）科技有限公司'
                        }
                    },
                    {
                        path: 'zbydsp',//直播与短视频
                        component: zbydsp,
                        meta: {
                            title: '直播与短视频解决方案——新普互联（北京）科技有限公司'
                        }
                    },
                    {
                        path: 'yxsjzh',//营销数据整合
                        component: yxsjzh,
                        meta: {
                            title: '营销数据整合与洞察——新普互联（北京）科技有限公司'
                        }
                    },
                    {
                        path:'',
                        redirect: '/index/hxyw/ppyx'
                    }
                ]
            },
            {
                path:'hzzy',//合作资源
                component: hzzy,
                meta: {
                    title: '合作资源——新普互联（北京）科技有限公司'
                }
            },
            {
                path:'jrwm',//加入我们
                component: jrwm,
                meta: {
                    title: '加入我们——新普互联（北京）科技有限公司'
                }
            },
            {
                path:'lxwm',//联系我们
                component: lxwm,
                meta: {
                    title: '联系我们——新普互联（北京）科技有限公司'
                }
            },
            {
                path:'',
                redirect: '/index/sy'
            }
        ]
    },
    {
        path:'/ljhz',//立即合作-移动端
        component: ljhz
    },
    {
        path: '*', //任何不存在的路径
        redirect: '/index'
    }
]

const router = new VueRouter({
    routes
})

router.afterEach((to) => {
    if (to.meta.title) {
        document.title = to.meta.title
    } else {
        document.title = 'sinponet'
    }
})

export default router