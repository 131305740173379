<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <!-- web端布局 -->
    <div class="web">
      <Header>
        <img :src="headerData.web.img_1" alt="">
        <div class="list">
          <router-link to="/index/sy" active-class="active">
            <span>{{ headerData.web.item_1 }}</span>
          </router-link>
          <router-link to="/index/gywm" active-class="active">
            <span>{{ headerData.web.item_2 }}</span>
          </router-link>
          <router-link to="/index/hxyw" active-class="active">
            <span>{{ headerData.web.item_3 }}</span>
          </router-link>
          <router-link to="/index/hzzy" active-class="active">
            <span>{{ headerData.web.item_4 }}</span>
          </router-link>
          <router-link to="/index/jrwm" active-class="active">
            <span>{{ headerData.web.item_5 }}</span>
          </router-link>
          <router-link to="/index/lxwm" active-class="active">
            <span>{{ headerData.web.item_6 }}</span>
          </router-link>
        </div>
        <button @click="dialogFormVisible = true">{{ headerData.web.btn_1 }}</button>
      </Header>
    </div>
    <!-- 移动端布局 -->
    <div class="mobile">
      <Header>
        <div class="header">
          <img :src="headerData.mobile.img_1" alt="">
          <img class="anniu" v-show="isListTrue" @click="isListTrue=false" :src="headerData.mobile.img_2" alt="">
          <img class="anniu" v-show="!isListTrue" @click="isListTrue=true" :src="headerData.mobile.img_3" alt="">
        </div>
        <div class="list" v-show="!isListTrue">
          <router-link to="/index/sy" active-class="active">
            <span>{{ headerData.mobile.item_1 }}</span>
          </router-link>
          <router-link to="/index/gywm" active-class="active">
            <span>{{ headerData.mobile.item_2 }}</span>
          </router-link>
          <router-link to="/index/hxyw" active-class="active">
            <span>{{ headerData.mobile.item_3 }}</span>
          </router-link>
          <router-link to="/index/hzzy" active-class="active">
            <span>{{ headerData.mobile.item_4 }}</span>
          </router-link>
          <router-link to="/index/jrwm" active-class="active">
            <span>{{ headerData.mobile.item_5 }}</span>
          </router-link>
          <router-link to="/index/lxwm" active-class="active">
            <span>{{ headerData.mobile.item_6 }}</span>
          </router-link>
          <router-link to="/ljhz" active-class="active">
            <span>{{ headerData.mobile.item_7 }}</span>
          </router-link>
        </div>
      </Header>
    </div>
    <router-view></router-view>
    <Footer></Footer>
    <!-- 立刻合作弹窗 -->
    <el-dialog title="立即合作 COOPERATION" :visible.sync="dialogFormVisible">
      <el-form ref="form" :model="form" label-width="0.8rem">
        <div class="row">
          <el-form-item label="姓名">
            <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
          </el-form-item>
          <el-form-item label="公司">
            <el-input v-model="form.company" placeholder="请输入您的公司名称"></el-input>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="邮箱">
            <el-input v-model="form.email" placeholder="请输入您的邮箱"></el-input>
          </el-form-item>
          <el-form-item label="电话">
            <el-input v-model="form.phone" placeholder="请输入您的联系方式"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="需求方向">
          <el-checkbox-group v-model="form.type">
            <el-checkbox label="品牌营销" name="type"></el-checkbox>
            <el-checkbox label="效果营销" name="type"></el-checkbox>
            <el-checkbox label="直播与短视频" name="type"></el-checkbox>
            <el-checkbox label="其他" name="type"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="业务需求">
          <el-input type="textarea" v-model="form.desc"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogFormVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogFormVisible = false">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Footer from '../Footer'
import { headerData } from '../../config/environment'

export default {
  components: {
    Footer,
  },
  data() {
    return {
      headerData,
      dialogFormVisible: false,
      form: {
        name: '',
        company: '',
        email: '',
        phone: '',
        type: [],
        desc: ''
      },
      formLabelWidth: '1.2rem',
      isListTrue: true
    }
  }
}
</script>

<style lang="less" scoped>
// 屏幕大于 750px 时应用该样式
@media screen and (min-width: 751px) {
  .web {
    display: block;
  }

  .mobile {
    display: none;
  }
}

// 屏幕小于 750px 时应用该样式
@media screen and (max-width: 750px) {
  .web {
    display: none;
  }

  .mobile {
    display: block;
  }
}

// web端样式开始
.web {
  Header {
    width: 100%;
    height: 1.01rem;
    // padding-top: 0.3rem;
    background-color: black;
    // float: left;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-top: 0.31rem;
  }

  Header img {
    display: inline-block;
    margin-left: 1rem;
    height: 0.7rem;
    width: 2.4rem;
    vertical-align: middle;
  }

  Header .list {
    /* float: left; */
    display: inline-block;
    font-family: MiSans-Normal;
    font-size: 0.14rem;
    margin-left: 2rem;
  }

  .list a {
    display: inline-block;
    height: 0.7rem;
    line-height: 0.7rem;
    margin-right: 0.3rem;
    color: #fff;
  }

  .list a:hover {
    color: #ccc;
  }

  .active {
    font-family: MiSans-Bold;
    border-bottom: 0.04rem solid #5383ec;
  }

  Header button {
    width: 1.1rem;
    height: 0.36rem;
    text-align: center;
    line-height: 0.36rem;
    border-radius: 0.18rem;
    font-size: 0.18rem;
    border: 0;
    font-family: MiSans-Normal;
  }
}

// 移动端样式开始
.mobile{
  Header{
    width: 100%;
    .header{
      width: 100%;
      height: 1;
      padding-top: 0.3rem;
      background-color: black;
      padding: 7% 8% 1% 8%;
      display: flex;
      justify-content: space-between;

      img:nth-child(1){
        width:40%;
      }
      .anniu{
        width: 10%;
        height:auto;
      }
    }
    .list{
      padding: 4%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      a {
        width: 22%;
        height:0.4rem;
        border-radius:0.15rem;
        font-family: MiSans-Nomal;
        background-color: #f6f6f6;
        color:black;
        margin-right:3%;
        margin-bottom:0.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
          line-height: 0.6rem;
          font-size: 0.14rem;
        }
      }
      // a:hover{
      //   background-color: #ccc;
      // }
      .active{
        font-family: MiSans-Bold;
        background-color: black;
        color:#fff;
      }
    }
  }
  
}

/* 立即合作弹窗 */
/deep/.el-dialog {
    border-radius: 0.15rem;
    font-family: MiSans-Normal;
    color: black;
    background-color: #f9f9f9;
  }

  /deep/.el-dialog__title {
    font-size: 0.28rem;
    color: black;
    text-align: center;
    font-family: MiSans-Bold;
    display: block;
    line-height: 0.45rem;
  }

  /deep/.el-dialog__body {
    padding-top: 0;
  }

  /deep/.el-form-item {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin: 0;
  }

  /deep/.el-form-item__content {
    margin-left: 0.4rem !important;
    ;
  }

  .row {
    display: flex;
  }

  /deep/.el-input__inner {
    border-radius: 0.1rem;
    font-size: 0.16rem;
  }
  /deep/.el-form-item__label {
    text-align: left;
    margin-left: 0.43rem;
  }

  /deep/.el-form-item__content {
    line-height: 0px;
  }

  /deep/.el-checkbox-group {
    display: flex;
  }

  /deep/.el-checkbox__label {
    font-size: 0.16rem;
  }

  /deep/.el-checkbox__inner {
    width: 0.18rem;
    height: 0.18rem;
  }

  /deep/.el-textarea__inner {
    border-radius: 0.1rem;
    line-height: 3.5;
    width: 215%;
  }
  /deep/.el-button--primary {
    width: 1.8rem;
    height: 0.4rem;
    border-radius: 0.2rem;
    background-color: black;
    border: none;
  }
  /deep/.el-button--primary:hover {
    // width: 180px;
    // height: 40px;
    // border-radius: 20px;
    background-color: #999;
    border: none;
  }

  /deep/.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/.el-dialog__headerbtn .el-dialog__close {
    font-size: 0.25rem;
  }
</style>