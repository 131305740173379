<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="ljhz_item">
      <img @click="$router.go(-1)" src="../assets/image/01移动端切图（2X）/mobile_icon15.png" alt="">
      <div style="display:flex;justify-content: center;">
        <span>立即合作</span>
      </div>
    </div>
    <el-form ref="form" :model="form">
        <el-form-item label="姓名">
          <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
        </el-form-item>
        <el-form-item label="公司">
          <el-input v-model="form.company" placeholder="请输入您的公司名称"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="form.email" placeholder="请输入您的邮箱"></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="form.phone" placeholder="请输入您的联系方式"></el-input>
        </el-form-item>
      <el-form-item label="需求方向">
        <el-checkbox-group v-model="form.type">
          <el-checkbox label="品牌营销" name="type"></el-checkbox>
          <el-checkbox label="效果营销" name="type"></el-checkbox><br>
          <el-checkbox label="直播与短视频" name="type"></el-checkbox>
          <el-checkbox label="其他" name="type"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="业务需求">
        <el-input type="textarea" placeholder="请输入您的需求" v-model="form.desc"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button>提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: '',
        company: '',
        email: '',
        phone: '',
        type: [],
        desc: ''
      },
    }
  }
}
</script>

<style lang="less" scoped>
.el-form{
  background-color: #f6f6f6;
  padding: 8%;

  .el-form-item{
    display: flex;
    flex-direction: column;
    margin-bottom:0.1rem!important;
  }
  /deep/.el-form-item__label{
    text-align: left;
    color: black;
    font-size: 0.18rem;
  }
  /deep/.el-input__inner{
    height: 0.5rem;
    border-radius: 0.15rem;
    font-size: 0.18rem;
  }
  /deep/.el-checkbox__inner{
    width: 0.2rem;
    height: 0.2rem;
  }
  /deep/.el-checkbox__label{
    font-size: 0.18rem;
    color:black;
    font-family: MiSans-Normal;
  }
  /deep/.el-checkbox{
    margin-right: 0;
    width: 50%;
  }
  /deep/.el-textarea__inner{
    line-height: 3.5;
    border-radius:0.15rem;
    font-size: 0.18rem;
  }
  /deep/.el-button{
    width: 60%;
    height: 0.5rem;
    background-color: black;
    color: #fff;
    border-radius: 0.3rem;
    font-size: 0.18rem;
    font-family: 'MiSans-Normal';
    margin-left: 20%;
  }
}
.ljhz_item {
  width: 100%;
  height: 0.8rem;
  background-color: black;
  color: #fff;
  position: relative;
  // display: flex;
  img{
    width:7%;
    position: absolute;
    left: 5%;
    bottom: 10%;
  }
  span{
    position: absolute;
    bottom: 10%;
    // left: 45%;
    font-size: 0.22rem;
    font-family: MiSans-Bold;
  }
}
</style>