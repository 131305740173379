"use strict"

let headerData = { // 头部导航
    web: {
        img_1: "http://offweb-res.perfect-cn.cn/offweb/web/web_logo.png",
        item_1: "首页",
        item_2: "关于我们",
        item_3: "核心业务",
        item_4: "合作资源",
        item_5: "加入我们",
        item_6: "联系我们",
        btn_1: "立即合作"
    },
    mobile: {
        img_1: "http://offweb-res.perfect-cn.cn/offweb/mobile/mobile_logo.png",
        img_2: "http://offweb-res.perfect-cn.cn/offweb/mobile/mobile_icon01.png",
        img_3: "http://offweb-res.perfect-cn.cn/offweb/mobile/mobile_icon02.png",
        item_1: "首页",
        item_2: "关于我们",
        item_3: "核心业务",
        item_4: "合作资源",
        item_5: "加入我们",
        item_6: "联系我们",
        item_7: "立即合作"
    }
}

let footerData = { // 底部导航
    web: {
        img_0: "http://offweb-res.perfect-cn.cn/offweb/web/web_bg1.png",
        img_1: "http://offweb-res.perfect-cn.cn/offweb/web/web_logo.png",
        img_2: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon02.png",
        img_3: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon03.png",
        img_4: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon04.png",
        img_5: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon05.png",
        img_6: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon06.png",
        img_7: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon07.png",
        address: "红庄国际文化保税创新园E5",
        tel: "010-53730708",
        mail: "sinponet@perfect-cn.cn",
        company: "新普互联（北京）科技有限公司",

    }
}

let homeData = { // 首页
    web: {
        img_1: "http://offweb-res.perfect-cn.cn/offweb/web/头部01.jpg",
        img_2: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner01.png",
        img_3: "http://offweb-res.perfect-cn.cn/offweb/web/首页01.png",
        img_4: "http://offweb-res.perfect-cn.cn/offweb/web/首页02.png",
        img_5: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon01.png",
        img_6: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon01.png",
        img_7: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner03.png",
        img_8: "http://offweb-res.perfect-cn.cn/offweb/web/首页屏3.jpg",
        img_9: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon01.png",
        img_10: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon01.png",
        img_11: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner06.png",
        img_12: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon01.png",
        img_13: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon01.png",
        img_14: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner06.png",
        img_15: "http://offweb-res.perfect-cn.cn/offweb/web/首页03.png",
        img_16: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon01.png",
        img_17: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon01.png",
        // img_18: "http://offweb-res.perfect-cn.cn/offweb/web/首页04.png",
        img_18: "http://offweb-res.perfect-cn.cn/offweb/web/首页屏6.jpg",
        img_19: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon01.png",
        img_20: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon01.png",
        img_21: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner06.png",
        hezuohuoban_1: "http://offweb-res.perfect-cn.cn/offweb/logo/%E5%A4%A9%E7%8C%ABLogo.png",
        hezuohuoban_2: "http://offweb-res.perfect-cn.cn/offweb/logo/HiPhiLogo.png",
        hezuohuoban_3: "http://offweb-res.perfect-cn.cn/offweb/logo/神州租车Logo.png",
        hezuohuoban_4: "http://offweb-res.perfect-cn.cn/offweb/logo/OppoLogo.png",
        hezuohuoban_5: "http://offweb-res.perfect-cn.cn/offweb/logo/新东方Logo.png",
        hezuohuoban_6: "http://offweb-res.perfect-cn.cn/offweb/logo/莉莉丝游戏Logo.png",
        hezuohuoban_7: "http://offweb-res.perfect-cn.cn/offweb/logo/点淘Logo.png",
        hezuohuoban_8: "http://offweb-res.perfect-cn.cn/offweb/logo/威马汽车Logo.png",
        hezuohuoban_9: "http://offweb-res.perfect-cn.cn/offweb/logo/瓜子Logo.png",
        hezuohuoban_10: "http://offweb-res.perfect-cn.cn/offweb/logo/戴尔Logo.png",
        hezuohuoban_11: "http://offweb-res.perfect-cn.cn/offweb/logo/有道Logo.png",
        hezuohuoban_12: "http://offweb-res.perfect-cn.cn/offweb/logo/奇妙派对Logo.png",
        hezuohuoban_13: "http://offweb-res.perfect-cn.cn/offweb/logo/淘宝Logo.png",
        hezuohuoban_14: "http://offweb-res.perfect-cn.cn/offweb/logo/极狐Logo.png",
        hezuohuoban_15: "http://offweb-res.perfect-cn.cn/offweb/logo/优信Logo.png",
        hezuohuoban_16: "http://offweb-res.perfect-cn.cn/offweb/logo/国美电器Logo.png",
        hezuohuoban_17: "http://offweb-res.perfect-cn.cn/offweb/logo/高徒Logo.png",
        hezuohuoban_18: "http://offweb-res.perfect-cn.cn/offweb/logo/波克城市Logo.png",
        hezuohuoban_19: "http://offweb-res.perfect-cn.cn/offweb/logo/饿了么Logo.png",
        hezuohuoban_20: "http://offweb-res.perfect-cn.cn/offweb/logo/吉利Logo.png",
        hezuohuoban_21: "http://offweb-res.perfect-cn.cn/offweb/logo/易车Logo.png",
        hezuohuoban_22: "http://offweb-res.perfect-cn.cn/offweb/logo/伊利Logo.png",
        hezuohuoban_23: "http://offweb-res.perfect-cn.cn/offweb/logo/学而思Logo.png",
        hezuohuoban_24: "http://offweb-res.perfect-cn.cn/offweb/logo/游族网络Logo.png",
        hezuohuoban_25: "http://offweb-res.perfect-cn.cn/offweb/logo/美团Logo.png",
        hezuohuoban_26: "http://offweb-res.perfect-cn.cn/offweb/logo/宝马Logo.png",
        hezuohuoban_27: "http://offweb-res.perfect-cn.cn/offweb/logo/爱空间Logo.png",
        hezuohuoban_28: "http://offweb-res.perfect-cn.cn/offweb/logo/小红书Logo.png",
        hezuohuoban_29: "http://offweb-res.perfect-cn.cn/offweb/logo/斑马Logo.png",
        hezuohuoban_30: "http://offweb-res.perfect-cn.cn/offweb/logo/比心游戏Logo.png",
        hezuohuoban_31: "http://offweb-res.perfect-cn.cn/offweb/logo/支付宝Logo.png",
        hezuohuoban_32: "http://offweb-res.perfect-cn.cn/offweb/logo/奥迪Logo.png",
        hezuohuoban_33: "http://offweb-res.perfect-cn.cn/offweb/logo/Boss直聘Logo.png",
        hezuohuoban_34: "http://offweb-res.perfect-cn.cn/offweb/logo/腾讯视频Logo.png",
        hezuohuoban_35: "http://offweb-res.perfect-cn.cn/offweb/logo/猿辅导Logo.png",
        hezuohuoban_36: "http://offweb-res.perfect-cn.cn/offweb/logo/ZPLAYLogo.png",
        home_logo1: "http://offweb-res.perfect-cn.cn/offweb/web/首页客户Logo.png",
        home_logo2: "http://offweb-res.perfect-cn.cn/offweb/web/首页客户Logo2.png",
        home_logo_background: "http://offweb-res.perfect-cn.cn/offweb/web/首页合作伙伴北京.png",
        item_1: "让每一次连接，更有价值",
        // item_2: "作为国内领先的智能整合营销服务商，我们帮助客户洞察市场趋势变化、精准触达潜在目标受众、解决商业问题、实现长效增长。使商业伙伴的价值最大化。",
        item_2: "数据、内容与服务驱动的智能整合营销服务商",
        item_3: "专注致力于智能整合营销",
        item_4: "FOCUS ON INTELLIGENT INTEGRATED MARKETING",
        item_5: "",
        item_6: "面对流动的媒体、流动的内容，流动的用户，以解决方案迭代服务，以技术演化营销科学。我们正在塑造下一个营销时代。",
        item_7: "了解更多",

        item_8: "品牌营销",
        item_9: "BRAND MARKETING",
        item_10: "“内容+运营+数据”，一站式解决客户不同阶段的商业痛点。",

        item_11: "效果营销",
        item_12: "PERFORMANCE MARKETING",
        item_13: "创意为流量提效：以专业的优化运营团队，保障效果营销品质。",
        item_14: "直播与短视频解决方案",
        item_15: "STREAMING MEDIA",
        item_16: "SOLUTIONS",
        item_17: "媒体即卖场，以短链闭环助力品牌实现长效经营。",
        item_18: "营销数据整合与洞察",
        item_19: "MARKETING DATA",
        item_20: "INTEGRATION&INSIGHT",
        item_21: "度量、洞察、优化，帮助客户发现生意增长新机会。",
        item_22: "我们的服务客户",
        item_23: "我们与合作伙伴密切合作，将品牌与消费者联系起来，提供专业服务,全球1000+客户的选择，感谢您信赖我们",
        item_24: "", // 最成功的企业优先考虑自己和客户的成功，我们希望与客户共同创造产品增值
        item_25: "立即合作 COOPERATION",
        item_26: "姓名",
        item_27: "请输入您的姓名",
        item_28: "公司",
        item_29: "请输入您的公司名称",
        item_30: "邮箱",
        item_31: "请输入您的邮箱",
        item_32: "电话",
        item_33: "请输入您的联系方式",
        item_34: "需求方向",
        item_35: "品牌营销",
        item_36: "效果营销",
        item_37: "直播与短视频",
        item_38: "其他",
        item_39: "业务需求",
        btn_1: "立即合作",
        btn_2: "提交"
    },
    mobile: {
        img_1: "http://offweb-res.perfect-cn.cn/offweb/mobile/mobile_banner02.png",
        img_2: "http://offweb-res.perfect-cn.cn/offweb/mobile/mobile_banner03.png",
        img_3: "http://offweb-res.perfect-cn.cn/offweb/mobile/mobile_banner07.png",
        item_1: "专注致力于智能整合营销",
        item_2: "面对流动的媒体、流动的内容，流动的用户，以解决方案迭代服务，以技术演化营销科学。我们正在塑造下一个营销时代。",
        item_3: "品牌营销",
        item_4: "BRAND MARKETING",
        item_5: "效果营销",
        item_6: "PERFORMANCE MARKETING",
        item_7: "直播与短视频解决方案",
        item_8: "STREAMING MEDIA SOLUTIONS",
        item_9: "营销数据整合与洞察",
        item_10: "MARKETING DATA INTEGRATION&INSIGHT",
        btn_1: "立即合作",
        btn_2: "联系我们"


    }
}

let joinUsData = { // 加入我们
    web: {
        img_1: "http://offweb-res.perfect-cn.cn/offweb/web/web_bg2.png",
        img_2: "http://offweb-res.perfect-cn.cn/offweb/web/加入我们01.jpeg",
        img_3: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon14.png",
        img_4: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon12.png",
        img_5: "http://offweb-res.perfect-cn.cn/offweb/web/加入我们02.jpeg",
        img_6: "http://offweb-res.perfect-cn.cn/offweb/web/加入我们03.jpeg",
        img_7: "http://offweb-res.perfect-cn.cn/offweb/web/加入我们04.jpeg",
        item_1: "加入我们",
        item_2: "JOIN US",
        item_3: "我们与资源合作，将主动营销与被动营销结合。锁定新生代消费主力，定向覆盖多元营销场景，满足不同客户差异化需求。",
        item_4: "营销界的新成员?",
        item_5: "寻找新的工作机会?",
        item_6: "我们急需一批在技能上有“杀手锏”",
        item_7: "有激情、有活力、更具创新精神的新时代力量的加入。",
        item_8: "致力于为公司培养尖端的人才",
        item_9: "在这里，你也可以拥有高于业界的特殊薪酬待遇、全国各地的工作机会及视野、开拓创新的机会!",
        item_10: "致力于为公司培养尖端的人才",
        item_11: "在这里，你也可以拥有高于业界的特殊薪酬待遇、全国各地的工作机会及视野、开拓创新的机会!",
        item_12: "投递简历",
        item_13: "投递简历邮件请注明“应聘+职位”+“工作作地点”+“信息来源”,招聘相关信息请参考BOSS直聘，搜索关键词“新普互联",
        item_14: "联系方式",
        item_15: "010-53730708",
        item_16: "HR@perfect-cn.cn",
        item_17: "关注员工成长",
        item_18: "致力于公司员工成长与突破，我们以企业战略为导向，建立完善的培训体系、制定针对性内外部课程，持续提升员工职业化表现，践行内部讲师培养及激励计划。",
        item_19: "工作与生活的平衡",
        item_20: "身体健康和心灵健康对于我们至关重要，如果我们没有健康，那么再出色的成绩也没有意义。我们不鼓励加班，让我们的员工都能有工作和生活的平衡，带着对生活更饱满的热爱投入工作。",
        item_21: "成为更好的我们",
        item_22: "您将加入一个由300+人组成的团队。在这里，你可以拥有高于业界的薪酬待遇、全国各地的工作机会、开拓视野、自主创新的机会，让我们一起成为更好的我们吧！"
    },
    mobile: {
        // img_1: "http://offweb-res.perfect-cn.cn/offweb/mobile/dng",
    }
}

let contactUs = { // 联系我们
    web: {
        img_1: "http://offweb-res.perfect-cn.cn/offweb/web/web_bg2.png",
        img_2: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner11.png",
        img_3: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon13.png",
        img_4: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon14.png",
        img_5: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon12.png",
        img_6: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner12.png",
        img_7: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon13.png",
        img_8: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon14.png",
        img_9: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon12.png",
        img_10: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner13.png",
        img_11: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon13.png",
        img_12: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon14.png",
        img_13: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon12.png",
        item_1: "联系我们",
        item_2: "CONTACT US",
        item_3: "期待您与我们联系!",

        item_4: "北京 BEIJING (总部)",
        item_5: "北京市朝阳区红庄国际文化保税创新园E5",
        item_6: "18612799709",
        item_7: "sinponet@perfect-cn.cn",

        item_8: "上海 SHANGHAI",
        item_9: "上海市普陀区长寿路868号中港汇大厦9F-903",
        item_10: "021-62036893",
        item_11: "sinponet@perfect-cn.cn",

        item_12: "深圳 SHENZHEN",
        item_13: "广东省深圳市南山区深圳湾科技生态园三区10栋B座11楼1104",
        item_14: "0755-84862716",
        item_15: "sinponet@perfect-cn.cn",
    }
}

let cooperativeResources = { // 合作资源
    web: {
        img_1: "http://offweb-res.perfect-cn.cn/offweb/web/web_bg2.png",
        img_2: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源01.png",
        img_3: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源02.png",
        img_4: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源03.png",
        img_5: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源04.png",
        img_6: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源05.png",
        img_7: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源06.png",
        img_8: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源07.png",
        img_9: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源08.png",
        img_10: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源09.png",
        img_11: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源10.png",
        img_12: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源11.png",
        img_13: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源12.png",
        img_14: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源13.png",
        img_15: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源14.png",
        img_16: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源15.png",
        img_17: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源16.png",
        img_18: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源17.png",
        img_19: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源18.png",
        img_20: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源19.png",
        img_21: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源20.png",
        background01: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源背景1.png",
        background02: "http://offweb-res.perfect-cn.cn/offweb/web/合作资源背景2.png",
        depth_bytedance01: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left01-1.png",
        depth_bytedance02: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left01-2.png",
        depth_bytedance03: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left01-3.png",
        depth_bytedance04: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left01-4.png",
        depth_bytedance05: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left01-5.png",
        depth_bytedance06: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left01-6.png",
        depth_bytedance07: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left01-7.png",
        depth_bytedance08: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left01-8.png",
        depth_bytedance09: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left01-9.png",
        depth_bytedance10: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left01-10.png",
        depth_bytedance11: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left01-11.png",
        depth_bytedance12: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left01-12.png",

        depth_baidu01: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left02-1.png",
        depth_baidu02: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left02-2.png",
        depth_baidu03: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left02-3.png",
        depth_baidu04: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left02-4.png",
        depth_baidu05: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left02-5.png",
        depth_baidu06: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left02-6.png",
        depth_baidu07: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left02-7.png",
        depth_baidu08: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left02-8.png",
        depth_baidu09: "http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left02-9.png",

        all_media_1: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作01.png",
        all_media_2: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作02.png",
        all_media_3: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作03.png",
        all_media_4: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作04.png",
        all_media_5: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作05.png",
        all_media_6: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作06.png",
        all_media_7: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作07.png",
        all_media_8: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作08.png",
        all_media_9: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作09.png",
        all_media_10: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作10.png",
        all_media_11: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作11.png",
        all_media_12: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作12.png",
        all_media_13: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作13.png",
        all_media_14: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作14.png",
        all_media_15: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作15.png",
        all_media_16: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作16.png",
        all_media_17: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作17.png",
        all_media_18: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作18.png",
        all_media_19: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作19.png",
        all_media_20: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作20.png",
        all_media_21: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作21.png",
        all_media_22: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作22.png",
        all_media_23: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作23.png",
        all_media_24: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作24.png",
        all_media_25: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作25.png",
        all_media_26: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作26.png",
        all_media_27: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作27.png",
        all_media_28: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作28.png",
        all_media_29: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作29.png",
        all_media_30: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作30.png",
        all_media_31: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作31.png",
        all_media_32: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作32.png",
        all_media_33: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作33.png",
        all_media_34: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作34.png",
        all_media_35: "http://offweb-res.perfect-cn.cn/offweb/web/全媒体合作35.png",

        item_1: "资源整合",
        item_2: "RESOURCES FOR COOPERATION",
        item_3: "我们与资源合作，将主动营销与被动营销结合。锁定新生代消费主力，定向覆盖多元营销场景，满足不同客户差异化需求。",
        item_4: "深度合作媒体资源",
        item_5: "COOPERATIVE MEDIA RESOURCES",
        item_6: "全媒体合作资源",
        item_7: "ALL-MEDIA COOPERATION RESOURCES",
        item_8: "整合社交、社区、资讯等各类头部媒体资源，针对不同客户优化全域营销的实现路径，达到多屏触达、多渠道管理的营销价值最大化",
        item_9: "深耕字节跳动与百度的媒体合作，将主动营销与被动营销结合，锁定新生代消费主力，定向覆盖多元营销场景，满足不同客户差异化需求。",

    },
    mobile: {
        item_1: "深度合作媒体资源",
        item_2: "COOPERATIVE MEDIA RESOURCES",
        item_3: "全媒体合作资源",
        item_4: "ALL-MEDIA COOPERATION RESOURCES",
    }
}

let aboutUs = { // 关于我们
    web: {
        img_1: "http://offweb-res.perfect-cn.cn/offweb/web/web_bg2.png",
        // img_2: "http://offweb-res.perfect-cn.cn/offweb/web/关于我们01.png",
        img_2: "http://offweb-res.perfect-cn.cn/offweb/web/关于我们01.jpg",
        img_3: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon09.png",
        img_4: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon10.png",
        img_5: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon08.png",
        img_6: "http://offweb-res.perfect-cn.cn/offweb/web/关于我们02.png",
        img_7: "http://offweb-res.perfect-cn.cn/offweb/web/关于我们07.png",
        img_8: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_9: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_10: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_11: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_12: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_13: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_14: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_15: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_16: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_17: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_18: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_19: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_20: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_21: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_22: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_23: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_24: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_25: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_26: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_27: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner05.png",
        img_28: "http://offweb-res.perfect-cn.cn/offweb/web/关于我们03.jpeg",
        img_29: "http://offweb-res.perfect-cn.cn/offweb/web/关于我们04.jpeg",
        img_30: "http://offweb-res.perfect-cn.cn/offweb/web/关于我们05.jpeg",
        img_31: "http://offweb-res.perfect-cn.cn/offweb/web/关于我们06.jpeg",
        item_1: "关于新普互联",
        item_2: "ABOUT SINPONET",
        item_3: "国内领先的智能整合营销服务商，致力使商业伙伴的价值最大化。",
        item_4: "新普互联成立于2008年，基于服务&技术(Service &Technology)的模式，为各大品牌客户提供品牌营销与效果营销服务，帮助客户洞察市场趋势变化、精准触达潜在目标受众、解决商业问题、实现长效增长。",
        item_5: "作为国内领先的智能整合营销服务商，新普互联自2018年聚焦在线上短视频与直播营销领域，横跨搜索、信息流及视频媒体。并获得英飞拓、深投控青睐，完成资本化升级。",
        item_6: "公司总部位于北京，分别在上海、深圳设立分支机构。公司近300人规模，拥有近半数由媒体大咖、留学归国人才组成的专业化服务团队，累计服务包括快消、3C智能、汽车、美妆个护、电商、教育、金融、游戏、健康等各个行业，为超过1,000家头部品牌客户提供全域营销服务。",
        item_7: "100",
        item_8: "员工",
        item_9: "1000",
        item_10: "客户服务",
        item_11: "2000",
        item_12: "独家场地",
        item_13: "发展历程",
        item_14: "DEVELOPMENT HISTORY",
        item_15: "行业荣誉",
        item_16: "INDUSTRY HONOR",
        item_17: "10余项",
        item_18: "头部企业核心代理资格",
        item_19: "20余项",
        item_20: "行业顶级广告大赛奖项",
        item_21: "用信誉与高专业度为业务保驾护航",
        item_22: "我们不断成长，以高效的标准化流程、专业团队以及丰富的硬件设施，依靠技术与产品驱动，配置专业团队构建一站式的营销解决方案，为客户提供高品质营销服务，获得行业认可。",
        item_23: "团队配置",
        item_24: "TEAM CONFIGURATION",
        item_25: "服务流程",
        item_26: "SERVICE PROCESS",
        item_27: "标准化服务，把Big Idea转化为商业价值",
        team_1: "内容运营",
        team_2: "CONTENT OPERATION",
        team_3: "团队拥有资深内容营销及效果，深度洞悉并把握引流视频创意制作与直播间搭建。同步向现场团队输出脚本、话术以及节奏策略。",
        team_4: "数据运营",
        team_5: "DATA OPERATION",
        team_6: "团队拥有成熟的投放及承载品牌相关投放与数据复盘及用户归因与洞察，制定并迭代相应的目标人群、人货匹配、流量博弈、预算分配以及投放策略。",
        team_7: "创意制作",
        team_8: "CREATIVE PRODUCTION",
        team_9: "前沿视觉设计团队:专业视频创意团队，共计80人+多年服务经验，深耕创意内容创作，持续输出“爆款”内容为品牌赋能。",
        team_10: "现场执行",
        team_11: "ON-SITE EXECUTION",
        team_12: "基于客户品牌与产品.提供专业意见，提出解决方案，监控项目现场执行进度及质量,确保项目合理进度和客户满意度，根据现场实际情况制定工作内容和作出工作调整。",
        data_1: "80%",
        data_2: "专业技术人员",
        data_3: "90%",
        data_4: "百度认证",
        data_5: "60%",
        data_6: "巨量千川认证",
        data_7: "70%",
        data_8: "头条初级营销师",
        data_9: "10人",
        data_10: "百度认证专家",
        data_11: "5人",
        data_12: "百度商业产品布道师",

        history_1: "2008年",
        history_2: "北京总部成立",
        history_3: "2012年",
        history_4: "成为百度KA授权代理商",
        history_5: "2014年",
        history_6: "设立上海分公司、百度授权成为百度KA核心代理商、成为腾讯广点通授权服务商",
        history_7: "2015年",
        history_8: "被评为百度SEM“五星级”服务商、成为搜狗核心代理商",
        history_9: "2017年",
        history_10: "百度择金项目首批合作代理商、成为360品牌核心代理商",
        history_11: "2018年",
        history_12: "公司获得英飞拓、深投控所青睐，完成资本化升级",
        history_13: "2019年",
        history_14: "获得头条一级代理认证，成立四大事业部，布局全链路智能整合营销",
        history_15: "2020年",
        history_16: "巨量渠道年度服务突破合作伙伴",
        history_17: "2021年",
        history_18: "深耕短视频及直播营销解决方案",
        history_19: "2022年",
        history_20: "累计为行业千余头部客户提供智能整合营销服务",
        history_21: "",
        history_22: "",
        
        honor_1: "虎啸奖",
        honor_2: "优秀奖",
        honor_3: "金投赏",
        honor_4: "商业创意奖",
        honor_5: "ADMEN",
        honor_6: "实战金案",
        honor_7: "新广告狂人大赛",
        honor_8: "银奖",
        honor_9: "阿里",
        honor_10: "白金合作伙伴",
        honor_11: "百度",
        honor_12: "旅游行业创新营销大赛 行业拓新奖",
        honor_13: "百度",
        honor_14: "新业务杰出贡献奖 特等奖",
        honor_15: "百度",
        honor_16: "KA生态合作伙伴 行业攻坚奖",
        honor_17: "巨量引擎",
        honor_18: "服务突破合作伙伴",
        honor_19: "巨量引擎",
        honor_20: "创意营销案例奖",
    },
    mobile: {
        img_1: "http://offweb-res.perfect-cn.cn/offweb/mobile/mobile_icon09.png",
        img_2: "http://offweb-res.perfect-cn.cn/offweb/mobile/mobile_icon11.png",
        img_3: "http://offweb-res.perfect-cn.cn/offweb/mobile/mobile_icon10.png",
        img_4: "http://offweb-res.perfect-cn.cn/offweb/mobile/mobile_yuan.png",
        img_5: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner04.png",
        
        item_1: "发展历程",
        item_2: "DEVELOPMENT HISTORY",
        item_3: "行业荣誉",
        item_4: "INDUSTRY HONOR",
        item_5: "团队配置",
        item_6: "TEAM CONFIGURATION",
        item_7: "服务流程",
        item_8: "SERVICE PROCESS",
        item_8: "标准化服务，把Big Idea转化为商业价值",
    }
}

let coreBusiness = { // 核心业务
    web: {
        img_1: "http://offweb-res.perfect-cn.cn/offweb/web/web_bg2.png",
        item_1: "核心业务",
        item_2: "CORE BUSINESS",
        item_3: "期待您与我们联系!",
        item_4: "品牌营销",
        item_5: "效果营销",
        item_6: "直播与短视频解决方案",
        item_7: "营销数据整合与洞察",
    },
    mobile: {},
    brandMarketing: { // 品牌营销
        web: {
            img_1: "http://offweb-res.perfect-cn.cn/offweb/web/web_bg2.png",
            // img_2: "http://offweb-res.perfect-cn.cn/offweb/web/品牌营销01.png",
            img_2: "http://offweb-res.perfect-cn.cn/offweb/web/首页屏3.jpg",
            img_3: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner14.png",
            img_4: "http://offweb-res.perfect-cn.cn/offweb/web/品牌营销02.jpg",
            img_5: "http://offweb-res.perfect-cn.cn/offweb/web/品牌营销03.jpg",
            img_6: "http://offweb-res.perfect-cn.cn/offweb/web/品牌营销04.jpg",
            img_7: "http://offweb-res.perfect-cn.cn/offweb/web/品牌营销05.jpg",
            img_8: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon11.png",
            img_9: "http://offweb-res.perfect-cn.cn/offweb/web/品牌营销06.png",
            img_10: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon11.png",
            img_11: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon11.png",
            
            img_12: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon20.png",
            img_13: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon19.png",
            img_14: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon18.png",
            img_15: "http://offweb-res.perfect-cn.cn/offweb/web/品牌营销06.png",
            img_16: "http://offweb-res.perfect-cn.cn/offweb/web/品牌营销07.png",
            img_17: "http://offweb-res.perfect-cn.cn/offweb/web/品牌营销08.png",
            img_18: "http://offweb-res.perfect-cn.cn/offweb/web/品牌营销09.png",

            img_19: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner04.png",

            img_20: "http://offweb-res.perfect-cn.cn/offweb/web/品牌营销10.png",

            item_1: "品牌营销",
            item_2: "BRAND MARKETING",
            item_3: "内容+运营+数据",
            item_4: "一站式解决客户不同阶段的商业痛点",
            item_5: "服务",
            item_6: "SERVICES",

            item_7: "策略洞察",
            item_8: "探索潜在目标受众的消费动机，解决客户不同阶段的商业问题。",

            item_9: "整合营销",
            item_10: "全链覆盖，整合营销，高效组织与分发内容，解决客户增长痛点。",

            item_11: "创意输出",
            item_12: "创新交互形式，建立与消费者之间更深层次的心智连接。",

            item_13: "资源采购",
            item_14: "基于增效度量，组合优势媒体与KOL/KOC资源，合理分配预算。",

            item_15: "案例",
            item_16: "SHOWCASE",

            item_17: "威马汽车",
            item_18: "品牌全案营销",
            item_19: "联合头部Hero App强势曝光新车上市信息，同时聚焦圈层和竞品人群，依托oCPM高效集资为销售赋能。",
            item_20: "总曝光量",
            item_21: "28亿+",
            item_22: "相关互动量",
            item_23: "30万+",

            item_24: "伊利",
            item_25: "百度好奇夜",
            item_26: "在奶粉行业存量竞争时代下，提升消费者对伊利品牌好感度，建立品牌信任助力伊利品牌红海破局。",
            item_27: "总曝光量",
            item_28: "24亿+",
            item_29: "相关互动量",
            item_30: "150亿+",

            item_31: "国美电器",
            item_32: "抖音跨年派对",
            item_33: "通过定制抖音超级挑战赛，整合非标和硬广资源，覆盖用户入口，控制成本拉升ROI，持续获取新用户。",
            item_34: "总播放量",
            item_35: "18.8亿+",
            item_36: "点赞总数",
            item_37: "4587万+",

            item_38: "QJmotor",
            item_39: "新品发布",
            item_40: "依据行业发展趋势和消费者研究建立QJMOTOR企业品牌个性及主张，通过与消费者沟通概念，借势双新亮相，助力产品销售。",
            item_41: "总曝光量",
            item_42: "3000万+",
            item_43: "当天售罄",
            item_44: "999辆",

            item_45: "以",
            item_46: "「数据」",
            item_47: "为基础，洞察市场趋势，影响用户消费决策 ",

            item_48: "以",
            item_49: "「内容」",
            item_50: "为载体，击穿用户心智，扩大传播影响力",

            item_51: "以",
            item_52: "「智能」",
            item_53: "为驱动，提升运营效率，放大营销效果",

            item_54: "老凤祥",
            item_55: "品牌TVC",
            item_56: "为老凤祥品牌提供主播培训，优化直播间选品与互动玩法，提升直播间产品点击率、用户停留时长、产品展示质感和消费者视觉体验。",
            item_57: "播放量",
            item_58: "6000万+"


        },
        mobile: {
            img_1: "http://offweb-res.perfect-cn.cn/offweb/web/品牌营销07.png",
            img_2: "http://offweb-res.perfect-cn.cn/offweb/web/品牌营销08.png",
            img_3: "http://offweb-res.perfect-cn.cn/offweb/web/品牌营销09.png",
            img_4: "http://offweb-res.perfect-cn.cn/offweb/web/品牌营销10.png",
            item_1: "内容+运营+数据",
            item_2: "一站式解决客户不同阶段的商业痛点",
            item_3: "服务",
            item_4: "SERVICES",

            item_5: "案例",
            item_6: "SHOWCASE",
            item_7: "总曝光量",
            item_8: "相关互动量",

            item_9: "威马汽车",
            item_10: "品牌全案营销",
            item_11: "联合头部Hero App强势曝光新车上市信息，同时聚焦圈层和竞品人群，依oCPM高效集资为销售赋能。",
            item_12: "28亿+",
            item_13: "30万+",

            item_14: "伊利",
            item_15: "百度好奇夜",
            item_16: "在奶粉行业存量竞争时代下，提升消费者对伊利品牌好感度，建立品牌信任助力伊利品牌红海破局。",
            item_17: "28亿+",
            item_18: "30万+",

            item_19: "国美电器",
            item_20: "抖音跨年派对",
            item_21: "通过定制抖音超级挑战赛，整合非标和硬广资源，覆盖用户入口，控制成本拉升ROI，持续获取新用户。",
            item_22: "18.8亿+",
            item_23: "4587万+",

            item_24: "QJmotor",
            item_25: "新品发布",
            item_26: "依据行业发展趋势和消费者研究建立QJMOTOR企业品牌个性及主张，通过与消费者沟通概念，借势双新亮相，助力产品销售。",
            item_27: "3000万+",
            item_28: "999辆",

            item_30: "总曝光量",
            item_31: "相关互动量",
            item_32: "总曝光量",
            item_33: "相关互动量",
            item_34: "总播放量",
            item_35: "点赞总数",
            item_36: "总曝光量",
            item_37: "当天售罄",

            item_38: "老凤祥",
            item_39: "品牌TVC",
            item_40: "为老凤祥品牌提供主播培训，优化直播间选品与互动玩法，提升直播间产品点击率、用户停留时长、产品展示质感和消费者视觉体验。",
            item_41: "播放量",
            item_42: "6000万+"

        }
    },
    effectivenessMarketing: { // 效果营销
        web: {
            img_0: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner04.png",
            img_1: "http://offweb-res.perfect-cn.cn/offweb/web/web_bg2.png",
            img_2: "http://offweb-res.perfect-cn.cn/offweb/web/效果营销01.png",
            img_3: "http://offweb-res.perfect-cn.cn/offweb/web/效果营销01.png",
            img_4: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon11.png",
            img_5: "http://offweb-res.perfect-cn.cn/offweb/web/效果营销05.png",
            img_6: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon11.png",
            img_7: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon11.png",

            img_8: "http://offweb-res.perfect-cn.cn/offweb/web/效果营销02.jpg",
            img_9: "http://offweb-res.perfect-cn.cn/offweb/web/效果营销03.jpg",
            img_10: "http://offweb-res.perfect-cn.cn/offweb/web/效果营销04.png",

            img_11: "http://offweb-res.perfect-cn.cn/offweb/web/效果营销05.png",
            img_12: "http://offweb-res.perfect-cn.cn/offweb/web/效果营销06.png",
            img_13: "http://offweb-res.perfect-cn.cn/offweb/web/效果营销07.png",
            img_14: "http://offweb-res.perfect-cn.cn/offweb/web/效果营销08.png",
            img_15: "http://offweb-res.perfect-cn.cn/offweb/web/效果营销09.png",
            img_16: "http://offweb-res.perfect-cn.cn/offweb/web/效果营销10.png",
            
            item_1: "效果营销",
            item_2: "PERFORMANCE MARKETING",
            item_3: "创意为流量提效",
            item_4: "专业的优化运营团队保障效果营销品质",
            item_5: "以营销科学解决实际商业问题，提供灵活可靠的数据解决方案。",
            item_6: "自研iPaaS数据科学与机器学习平台，高效融合多方数据，保障数据安全合规。",
            item_7: "服务",
            item_8: "SERVICES",
            item_9: "案例",
            item_10: "SHOWCASE",

            item_11: "度小满",
            item_12: "百度国潮季",
            item_13: "打造国潮热议话题，传递度小满金融科技品牌理念，跨屏互动强化用户认知，全媒体连环热议引发现象级社会共鸣。",
            item_14: "总曝光量",
            item_15: "2.23亿+",
            item_16: "相关互动量",
            item_17: "115亿+",

            item_18: "点淘",
            item_19: "巨量引擎",
            item_20: "为顺应电商行业直播趋势，点淘推出直播购物新模式，通过进一步渗透市场，巩固市场地位，提升用户对于点淘的使用率。",
            item_21: "日耗峰值",
            item_22: "500万+",
            item_23: "次留提升",
            item_24: "65%",

            item_25: "美团",
            item_26: "百度SEM&信息流",
            item_27: "通过闪投、意图词模板等，快速实现素材迭代；利用商店直投+深度次留模型唤醒沉睡用户，每月18日固定时间投放福利刺激实现拉活目标。",
            item_28: "总曝光量",
            item_29: "25亿+",
            item_30: "点唤起",
            item_31: "300万+",

            item_32: "神州租车",
            item_33: "百度&头条",
            item_34: "通过渗透不同媒体上“看后搜&搜后看”功能，全面承接租车需求。基于搜索引擎平台和社交媒体平台制定相应投放传播策略，激发用户的神州租车欲望。",
            item_35: "21年总消耗",
            item_36: "3000万+",
            item_37: "总订单量",
            item_38: "60万+",

            item_39: "学而思",
            item_40: "持续拓量",
            item_41: "K12在线教育行业已进入沉淀阶段，市场资本愈发向头部企业集中。然而，K12在线教育市场的寡头仍未出现，头部客户仍需不断扩充流量。",
            item_42: "日耗峰值",
            item_43: "125万+",
            item_44: "单日转化",
            item_45: "3200+",

            item_46: "有道",
            item_47: "提升市占率",
            item_48: "利用低价课引导用户，增加转化。拓展面向家长层级的高中家长课堂以及小初教辅类图书产品。",
            item_49: "日耗峰值",
            item_50: "35万+",
            item_51: "视频完播放率",
            item_52: "30%+",


        },
        mobile: {
            img_1: "http://offweb-res.perfect-cn.cn/offweb/web/效果营销06.png",
            img_2: "http://offweb-res.perfect-cn.cn/offweb/web/效果营销07.png",
            img_3: "http://offweb-res.perfect-cn.cn/offweb/web/效果营销08.png",
            img_4: "http://offweb-res.perfect-cn.cn/offweb/web/效果营销09.png",
            img_5: "http://offweb-res.perfect-cn.cn/offweb/web/效果营销10.png",
            item_1: "创意为流量提效",
            item_2: "以专业的优化运营团队保障效果营销品质",
            item_3: "以营销科学解决实际商业问题，提供灵活可靠的数据解决方案。",
            item_4: "自研iPaaS数据科学与机器学习平台，高效融合多方数据，保障数据安全合规。",
            item_5: "服务",
            item_6: "SERVICES",
            item_7: "案例",
            item_8: "SHOWCASE",

            item_9: "信息流广告",
            item_10: "主动营销:",
            item_11: "结合品类与用户特征匹配，内容即广告。以效果考核，全面赋能业务增长。",
            item_12: "服务涵盖:",
            item_13: "内容创意，投放与优化。",


            item_14: "搜索广告",
            item_15: "被动营销:",
            item_16: "深度归因用户搜索链路，精准收割。以效果考核，全面解决用户增长。",
            item_17: "服务涵盖:",
            item_18: "内容创意，竞价与广告词优化。",

            item_19: "搜索引擎优化",
            item_20: "柔性营销:",
            item_21: "以用户视角，多渠道优化内容，拉升积极信息权重，释放品牌影响力。",
            item_22: "服务涵盖:",
            item_23: "舆情监控，口碑提升与负面管理。",


            item_24: "度小满",
            item_25: "百度国潮季",
            item_26: "打造国潮热议话题，传递度小满金融科技品牌理念，跨屏互动强化用户认知，全媒体连环热议引发现象级社会共鸣。",
            item_27: "总曝光量",
            item_28: "2.23亿+",
            item_29: "相关互动量",
            item_30: "115亿+",

            item_31: "点淘",
            item_32: "巨量引擎",
            item_33: "为顺应电商行业直播趋势，点淘推出直播购物新模式，通过进一步渗透市场，巩固市场地位，提升用户对于点淘的使用率。",
            item_34: "日耗峰值",
            item_35: "500万+",
            item_36: "次留提升",
            item_37: "65%",

            item_38: "美团",
            item_39: "百度SEM&信息流",
            item_40: "通过闪投、意图词模板等，快速实现素材迭代；利用商店直投+深度次留模型唤醒沉睡用户，每月18日固定时间投放福利刺激实现拉活目标。",
            item_41: "总曝光量",
            item_42: "25亿+",
            item_43: "点唤起",
            item_44: "300万+",

            item_45: "神州租车",
            item_46: "百度&头条",
            item_47: "通过渗透不同媒体上“看后搜&搜后看”功能，全面承接租车需求。基于搜索引擎平台和社交媒体平台制定相应投放传播策略，激发用户的神州租车欲望。",
            item_48: "21年总消耗",
            item_49: "3000万+",
            item_50: "总订单量",
            item_51: "60万+",

            item_52: "学而思",
            item_53: "持续拓量",
            item_54: "K12在线教育行业已进入沉淀阶段，市场资本愈发向头部企业集中。然而，K12在线教育市场的寡头仍未出现，头部客户仍需不断扩充流量。",
            item_55: "日耗峰值",
            item_56: "125万+",
            item_57: "单日转化",
            item_58: "3200+",

            item_59: "有道",
            item_60: "提升市占率",
            item_61: "利用低价课引导用户，增加转化。拓展面向家长层级的高中家长课堂以及小初教辅类图书产品。",
            item_62: "日耗峰值",
            item_63: "35万+",
            item_64: "视频完播放率",
            item_65: "30%+",
        }
        
    },
    liveVideo: { // 直播与短视频解决方案
        web: {
            img_1: "http://offweb-res.perfect-cn.cn/offweb/web/web_bg2.png",
            img_2: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频01.jpeg",
            img_3: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频01.jpeg",
            
            img_4: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频11.png",
            img_5: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频12.png",
            img_6: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频13.png",
            img_7: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频14.png",
            img_8: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频15.png",
            img_9: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频16.png",
            img_10: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频17.png",
            img_11: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频18.png",

            img_12: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon11.png",
            img_13: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频06.png",
            img_14: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon11.png",
            img_15: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon11.png",
            img_16: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon11.png",
            img_17: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner04.png",

            item_1: "直播与短视频解决方案",
            item_2: "STREAMING MEDIA SOLUTIONS",
            item_3: "媒体即卖场",
            item_4: "以短链闭环助力品牌实现长效经营",
            item_5: "以技术与产品驱动，量身定制全新增长点，持续交付。",
            item_6: "以FACT经营协同O-5A链路，帮助品牌沉淀关系、内容及数据资产。",
            item_7: "服务",
            item_8: "SERVICES",
            item_9: "短视频拍摄及直播场地",
            item_10: "SHOOTING PLACE",
            item_11: "多类型演员与主播",
            item_12: "PERFORMERS",
            item_13: "案例",
            item_14: "SHOWCASE",
            item_15: "以目标为导向，精细化运营关键过程结果，管理直播生命周期。",

            item_16: "天猫",
            item_17: "专场定制",
            item_18: "打造理想生活空间，双十一预热前配合出现明星直播，是天猫在北京新零售消费的新尝试。",
            item_19: "总曝光量",
            item_20: "5365万+",
            item_21: "相关互动量",
            item_22: "10万+",

            item_23: "璞浪",
            item_24: "代播代投",
            item_25: "专业投放运营团队全程跟播，实时优化提升直播间UV价值&GPM，营造新的饮酒消费场景。",
            item_26: "GPM",
            item_27: "4000+",
            item_28: "ROI",
            item_29: "破5",

            item_30: "茶小壶",
            item_31: "代播代投",
            item_32: "通过小店DSR运营、CRM系统工具优化，协助品牌快速提高口碑分。",
            item_33: "成交转化率",
            item_34: "20%+",
            item_35: "ROI",
            item_36: "破3",

            item_37: "花印",
            item_38: "代播代投",
            item_39: "根据直播间不同时段产出分秒级脚本，把控直播节奏，形成直播推荐正向循环。",
            item_40: "3H场观",
            item_41: "3万+",
            item_42: "ROI",
            item_43: "破5",

            item_44: "奥意娜",
            item_45: "品牌自播",
            item_46: "结合电商罗盘人群分析，优化直播间产品组合策略和营销玩法，提升品牌直播间点击率与转化率。",
            item_47: "点击率提升",
            item_48: "182%",
            item_49: "ROI",
            item_50: "破2",


        },
        mobile: {
            img_1: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频02.png",
            img_2: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频03.png",
            img_3: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频04.png",
            img_4: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频05.png",

            img_5: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频06.png",
            img_6: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频07.png",
            img_7: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频08.png",
            img_8: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频09.png",

            img_9: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon25.png",
            img_10: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon26.png",
            img_11: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon27.png",
            img_12: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon28.png",
            img_13: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频07.png",
            img_14: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频08.png",
            img_15: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频09.png",
            img_16: "http://offweb-res.perfect-cn.cn/offweb/web/直播与短视频010.png",

            item_1: "媒体即卖场",
            item_2: "以短链闭环助力品牌实现长效经营",
            item_3: "以目标为导向，精细化运营关键过程结果，管理直播生命周期;",
            item_4: "以 FACT经营协同O-5A链路，帮助品牌沉淀关系、内容及数据资产;",
            item_5: "服务",
            item_6: "SERVICES",
            item_7: "短视频拍摄及直播场地",
            item_8: "SHOOTING PLACE",
            item_9: "多类型演员与主播",
            item_10: "PERFORMERS",
            item_11: "案例",
            item_12: "SHOWCASE",

            item_13: "总曝光量",
            item_14: "相关互动量",

            item_15: "品牌直播",
            item_16: "灵活适配直播营销不同生命周期的增长目标，提供咨询与优化。服务涵盖：策略输出与落地、团队搭建与培训、场景搭建与优化、小店购物体验设计与优化。",

            item_17: "达人数选",
            item_18: "根据用户画像深度分析，合理优化预算分配，适   配KOL/KOC的短视频及直播合作，组织与分发内容，获得破圈营销效果。",

            item_19: "代播代投",
            item_20: "基于抖音巨量云图、星图与千川，助力客户长效经营。量身定制一站式直播与短视频营销解决方案及投放优化服务。",

            item_21: "专场定制",
            item_22: "创意与流量协同，定制有声量、有销量的专场直播，新品上市、营销大促等不同营销事件的传播与转化诉求。",

            item_23: "天猫",
            item_24: "专场定制",
            item_25: "打造理想生活空间，双十一预热前配合出现明星直播，是天猫在北京新零售消费的新尝试。",
            item_26: "5365万+",
            item_27: "10万+",

            item_28: "璞浪",
            item_29: "代播代投",
            item_30: "专业投放运营团队全程跟播，实时优化提升直播间UV价值&GPM，营造新的饮酒消费场景。",
            item_31: "4000+",
            item_32: "破5",

            item_33: "茶小壶",
            item_34: "代播代投",
            item_35: "通过小店DSR运营、CRM系统工具优化，协助品牌快速提高口碑分。",
            item_36: "20%+ ",
            item_37: "破3",

            item_38: "花印",
            item_39: "代播代投",
            item_40: "根据直播间不同时段产出分秒级脚本，把控直播节奏，形成直播推荐正向循环。",
            item_41: "3万+",
            item_42: "破5",

            item_43: "2000",
            item_44: "M²+",
            item_45: "独家场地",
            item_46: "1000",
            item_47: "条+",
            item_48: "独家场地",
            item_49: "2000",
            item_50: "条+",
            item_51: "独家场地",
            item_52: "40",
            item_53: "+",
            item_54: "独家场地",
            item_55: "以技术与产品驱动，量身定制全新增长点，持续交付。",


            item_56: "总曝光量",
            item_57: "线下人流",
            item_58: "GPM",
            item_59: "ROI",
            item_60: "成交转化率",
            item_61: "ROI",
            item_62: "3H场观",
            item_63: "ROI",

            item_64: "奥意娜",
            item_65: "品牌自播",
            item_66: "结合电商罗盘人群分析，优化直播间产品组合策略和营销玩法，提升品牌直播间点击率与转化率。",
            item_67: "点击率提升",
            item_68: "182%",
            item_69: "ROI",
            item_70: "破2",

        }
    },
    marketingData: { // 营销数据整合与洞察
        web: {
            img_1: "http://offweb-res.perfect-cn.cn/offweb/web/web_bg2.png",
            // img_2: "http://offweb-res.perfect-cn.cn/offweb/web/营销数据整合与洞察01.jpeg",
            img_2: "http://offweb-res.perfect-cn.cn/offweb/web/首页屏6.jpg",
            img_3: "http://offweb-res.perfect-cn.cn/offweb/web/营销数据整合与洞察01.jpeg",
            img_4: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon11.png",
            img_5: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner14.png",
            img_6: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon11.png",
            img_7: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon11.png",
            img_8: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner19.png",
            img_9: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner04.png",

            item_1: "营销数据整合与洞察",
            item_2: "STREAMING MEDIA SOLUTIONS",
            item_3: "度量、洞察、优化",
            item_4: "帮助客户发现生意增长新机会",
            item_5: "以营销科学解决实际商业问题，提供灵活可靠的数据解决方案。",
            item_6: "自研iPaaS数据科学与机器学习平台，高效融合多方数据，保障数据安全合规。",
            item_7: "服务",
            item_8: "SERVICES",
            // item_9: "案例",
            // item_10: "SHOWCASE",
            // item_11: "威马汽车",
            // item_12: "品牌全案营销",
            // item_13: "联合头部Hero App强势曝光新车上市信息，同时聚焦圈层和竞品人群，依oCPM高效集资为销售赋能。",
            // item_14: "总曝光量",
            // item_15: "28亿+",
            // item_16: "相关互动量",
            // item_17: "30万+",
            // item_18: "威马汽车威马汽车威马马...",
            // item_19: "品牌全案营销品牌全案营销2品...",
            // item_20: "联合头部Hero App强势曝光新车上市信息，同时聚焦圈层和竞品人群，依oCPM高效集资为销售赋能。",
            // item_21: "总曝光量",
            // item_22: "28亿+",
            // item_23: "相关互动量",
            // item_24: "30万+",
            // item_25: "威马汽车威马汽车威马马...",
            // item_26: "品牌全案营销品牌全案营销2品...",
            // item_27: "联合头部Hero App强势曝光新车上市信息，同时聚焦圈层和竞品人群，依oCPM高效集资为销售赋能。",
            // item_28: "总曝光量",
            // item_29: "28亿+",
            // item_30: "相关互动量",
            // item_31: "30万+",
            // item_32: "威马汽车威马汽车威马马...",
            // item_33: "品牌全案营销品牌全案营销2品...",
            // item_34: "联合头部Hero App强势曝光新车上市信息，同时聚焦圈层和竞品人群，依oCPM高效集资为销售赋能。",
            // item_35: "总曝光量",
            // item_36: "28亿+",
            // item_37: "相关互动量",
            // item_38: "30万+",
            item_39: "7*24小时响应",
            item_40: "7*24 HOURS RESPONSE",
            item_41: "赋能业务决策，加速数据变现"


        },
        mobile: {
            img_1: "http://offweb-res.perfect-cn.cn/offweb/mobile/mobile_banner14.png",
            img_2: "http://offweb-res.perfect-cn.cn/offweb/web/营销数据整合与洞察02.jpg",
            img_3: "http://offweb-res.perfect-cn.cn/offweb/web/营销数据整合与洞察03.jpg",
            img_4: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner15.png",
            img_5: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner16.png",
            img_6: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner14.png",
            img_7: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner14.png",
            img_8: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner14.png",
            img_9: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner14.png",
            img_10: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon21.png",
            img_11: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon21.png",
            img_12: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon21.png",
            img_13: "http://offweb-res.perfect-cn.cn/offweb/web/web_icon21.png",
            img_14: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner18.png",
            img_15: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner18.png",
            img_16: "http://offweb-res.perfect-cn.cn/offweb/web/web_banner18.png",

            item_1: "度量、洞察、优化",
            item_2: "帮助客户发现生意增长新机会",
            item_3: "以营销科学解决实际商业问题，提供灵活可靠的数据解决方案。",
            item_4: "自研iPaaS数据科学与机器学习平台，高效融合多方数据，保障数据安全合。",
            item_5: "服务",
            item_6: "SERVICES",
            // item_7: "案例",
            // item_8: "SHOWCASE",
            // item_9: "总曝光量",
            // item_10: "相关互动量",
            item_11: "7*24小时响应",
            item_12: "7*24 HOURS RESPONSE",
            item_13: "品牌营销科学数据策略服务",
            item_14: "针对品牌不同阶段的商业目标，提供灵活可靠的数据解决方案及咨询分析，助力客户每一步营销决策。",
            item_15: "多方数据融合分析与平台建设",
            item_16: "基于隐私计算与联邦学习，整合品牌一方数据，链接媒体二方数据，引入必要三方数据，提供私有化部署或指定云，定制搭建营销数据分析系统与品牌知识图谱。",
            
            // item_17: "策略洞察",
            // item_18: "探索潜在目标受众的消费动机，解决客户不同阶段的商业问题。",
            // item_19: "创意输出",
            // item_20: "探索潜在目标受众的消费动机，解决客户不同阶段的商业问题。",
            // item_21: "威马汽车",
            // item_22: "品牌全案营销",
            // item_23: "联合头部Hero App强势曝光新车上市信息，同时聚焦圈层和竞品人群，依oCPM高效集资为销售赋能。",
            // item_24: "28亿+",
            // item_25: "30万+",
            // item_26: "威马汽车",
            // item_27: "品牌全案营销",
            // item_28: "联合头部Hero App强势曝光新车上市信息，同时聚焦圈层和竞品人群，依oCPM高效集资为销售赋能。",
            // item_29: "28亿+",
            // item_30: "30万+'",
            // item_31: "威马汽车",
            // item_32: "品牌全案营销",
            // item_33: "联合头部Hero App强势曝光新车上市信息，同时聚焦圈层和竞品人群，依oCPM高效集资为销售赋能。",
            // item_34: "28亿+",
            // item_35: "30万+",
            // item_36: "威马汽车",
            // item_37: "品牌全案营销",
            // item_38: "联合头部Hero App强势曝光新车上市信息，同时聚焦圈层和竞品人群，依oCPM高效集资为销售赋能。",
            // item_39: "28亿+",
            // item_40: "30万+",
            item_41: "个性化报告",
            item_42: "根据不同服务与行业，定制化时报、日报、周报、月报，保障持续交付质量",
            item_43: "三方沟通",
            item_44: "客户、媒体与项目负责人，确保常态化沟通与拜访，及时更新变化，对齐目标",
            item_45: "风险控制",
            item_46: "政策面、行业面、舆情面、运营面，面面俱到，保障小时级风险控制",
            item_47: "预案响应",
            item_48: "多点预警预判风险，提高解决问题的效率。抽象高频、突发事件，设计响应预案",
            item_49: "赋能业务决策，加速数据变现",
        }
    }
}
export { headerData, footerData, homeData, joinUsData, contactUs, cooperativeResources, aboutUs, coreBusiness };
