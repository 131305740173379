<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="ppyx">
    <!-- 品牌营销 -->
    <div class="ppyx_item">
      <img :src="brandMarketing.web.img_1" alt="">
      <div class="ppyx_info">
        <span>{{ brandMarketing.web.item_1 }}</span>
        <span>{{ brandMarketing.web.item_2 }}</span>
      </div>
    </div>
    <!-- 内容+运营+数据 -->
    <div class="ppyx_item2">
      <div class="ppyx_info2">
        <span>{{ brandMarketing.web.item_3 }}</span>
        <span>{{ brandMarketing.web.item_4 }}</span>
        <div class="ppyx_info2_div" v-for="item in content" :key="item.num">
          <img :src="item.imgurl" alt="">
          <span>{{ item.info1 }}</span>
          <span class="blue">{{ item.info2_blue }}</span>
          <span>{{ item.info3 }}</span>
        </div>
      </div>
      <img :src="brandMarketing.web.img_2" alt="">
    </div>
    <!-- 内容+运营+数据 移动端-->
    <div class="ppyx_item2_mobile">
      <div class="ppyx_info2_mobile">
        <span>{{ brandMarketing.mobile.item_1 }}</span>
        <span>{{ brandMarketing.mobile.item_2 }}</span>
        <span></span>
        <span></span>
      </div>
      <img :src="brandMarketing.web.img_3" alt="">
      <div class="ppyx_info2_div_mobile">
        <div class="ppyx_info2_div" v-for="item in content" :key="item.num">
          <img :src="item.imgurl" alt="">
          <div style="line-height:0;">
            <span>{{ item.info1 }}</span>
            <span class="blue">{{ item.info2_blue }}</span>
            <span>{{ item.info3 }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 服务 -->
    
    <div class="ppyx_item3" :style="{ 'background-image': 'url(' + brandMarketing.web.img_19 + ')' }">
      <div class="ppyx_info3">
        <span></span>
        <span>{{ brandMarketing.web.item_5 }}</span>
        <span>{{ brandMarketing.web.item_6 }}</span>
      </div>
      <!-- 服务  移动端 -->
      <div class="ppyx_info3_mobile">
        <span>{{ brandMarketing.mobile.item_3 }}</span>
        <span>{{ brandMarketing.mobile.item_4 }}</span>
        <span></span>
        <span></span>
      </div>
      <div class="ppyx_info3_text">
        <div class="ppyx_info3_textdiv">
          <img :src="brandMarketing.web.img_4" alt="">
          <div class="ppyx_info3_span">
            <span>{{ brandMarketing.web.item_7 }}</span>
            <span>{{ brandMarketing.web.item_8 }}</span>
          </div>
        </div>
        <div class="ppyx_info3_textdiv">
          <img :src="brandMarketing.web.img_5" alt="">
          <div class="ppyx_info3_span">
            <span>{{ brandMarketing.web.item_9 }}</span>
            <span>{{ brandMarketing.web.item_10 }}</span>
          </div>
        </div>
        <div class="ppyx_info3_textdiv">
          <img :src="brandMarketing.web.img_6" alt="">
          <div class="ppyx_info3_span">
            <span>{{ brandMarketing.web.item_11 }}</span>
            <span>{{ brandMarketing.web.item_12 }}</span>
          </div>
        </div>
        <div class="ppyx_info3_textdiv">
          <img :src="brandMarketing.web.img_7" alt="">
          <div class="ppyx_info3_span">
            <span>{{ brandMarketing.web.item_13 }}</span>
            <span>{{ brandMarketing.web.item_14 }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 案例 -->
    <div class="ppyx_item4">
      <div class="ppyx_in">
        <span></span>
        <span>{{ brandMarketing.web.item_15 }}</span>
        <span>{{ brandMarketing.web.item_16 }}</span>
      </div>
      <!-- 案例   移动端-->
      <div class="ppyx_in_mobile">
        <span>{{ brandMarketing.mobile.item_5 }}</span>
        <span>{{ brandMarketing.mobile.item_6 }}</span>
        <span></span>
        <span></span>
      </div>
      <!-- 案例   移动端 -->
      <div class="ppyx_item4_info_mobile" v-for="item in cases" :key="item.num">
        <div class="ppyx_info4">
          <span>{{ item.name }}</span>
          <span>{{ item.itter }}</span>
          <span>{{ item.info }}</span>
          <span>{{ item.sub_1 }}<span class="blue2"> {{ item.pu }}</span> </span>
          <span>{{item.sub_2}}<span class="blue2"> {{ item.hu }}</span></span>
        </div>
        <img :src="item.imgurl" alt="">
      </div>
      <div class="ppyx_item4_info">
        <div class="ppyx_info4">
          <span>{{ brandMarketing.web.item_17 }}</span>
          <span>{{ brandMarketing.web.item_18 }}</span>
          <span>{{ brandMarketing.web.item_19 }}</span>
          <span>{{ brandMarketing.web.item_20 }}<span class="blue2"> {{ brandMarketing.web.item_21 }}</span> {{ brandMarketing.web.item_22 }}<span class="blue2"> {{ brandMarketing.web.item_23 }}</span></span>
          <!-- <img :src="brandMarketing.web.img_8" alt=""> -->
        </div>
        <img :src="brandMarketing.web.img_9" :style="{'height': '330px', 'object-fit': 'cover'}" alt="">
        <!-- <img :src="brandMarketing.web.img_3" alt=""> -->
      </div>

      <el-carousel @change="carouselChange" arrow="always" ref="carousel" :initial-index="1" type="card" :autoplay="false" :loop="false" indicator-position="none">
          <el-carousel-item id="0">
            <div class="ppyx_item4_textdiv">
              <div class="ppyx_item4_textspan">
                <span>{{ brandMarketing.web.item_24 }}</span>
                <span>{{ brandMarketing.web.item_25 }}</span>
                <span>{{ brandMarketing.web.item_26 }}</span>
              </div>
              <div class="textinfo">
                  <span class="imgtext">{{ brandMarketing.web.item_27 }}<span class="blue2"> {{ brandMarketing.web.item_28 }}</span></span>
                  <span class="imgtext">{{ brandMarketing.web.item_29 }}<span class="blue2"> {{ brandMarketing.web.item_30 }}</span></span>
              </div>
              <img :src="brandMarketing.mobile.img_1"/>
              <!-- <div class="ppyx_item4_textimg" :style="{ 'background-image': 'url(' + brandMarketing.mobile.img_1 + ')'}">
              </div> -->
            </div>
          </el-carousel-item>
          <el-carousel-item id="1">
            <div class="ppyx_item4_textdiv">
            <div class="ppyx_item4_textspan">
              <span>{{ brandMarketing.web.item_31 }}</span>
              <span>{{ brandMarketing.web.item_32 }}</span>
              <span>{{ brandMarketing.web.item_33 }}</span>
            </div>
            <div class="textinfo">
                <span class="imgtext">{{ brandMarketing.web.item_34 }}<span class="blue2"> {{ brandMarketing.web.item_35 }}</span></span>
                <span class="imgtext">{{ brandMarketing.web.item_36 }}<span class="blue2"> {{ brandMarketing.web.item_37 }}</span></span>
              </div>
              <img :src="brandMarketing.mobile.img_2"/>
            <!-- <div class="ppyx_item4_textimg" :style="{ 'background-image': 'url(' + brandMarketing.mobile.img_2 + ')' }">
            </div> -->
          </div>
          </el-carousel-item>
          <el-carousel-item id="2">
            <div class="ppyx_item4_textdiv">
            <div class="ppyx_item4_textspan">
              <span>{{ brandMarketing.web.item_38 }}</span>
              <span>{{ brandMarketing.web.item_39 }}</span>
              <span>{{ brandMarketing.web.item_40 }}</span>
            </div>
            <div class="textinfo">
                <span class="imgtext">{{ brandMarketing.web.item_41 }}<span class="blue2"> {{ brandMarketing.web.item_42 }}</span></span>
                <span class="imgtext">{{ brandMarketing.web.item_43 }}<span class="blue2"> {{ brandMarketing.web.item_44 }}</span></span>
            </div>
            <img :src="brandMarketing.mobile.img_3"/>
            <!-- <div class="ppyx_item4_textimg" :style="{ 'background-image': 'url(' + brandMarketing.mobile.img_3 + ')' }">
            </div> -->
          </div>
          </el-carousel-item>
          <el-carousel-item id="3">
            <div class="ppyx_item4_textdiv">
            <div class="ppyx_item4_textspan">
              <span>{{ brandMarketing.web.item_54 }}</span>
              <span>{{ brandMarketing.web.item_55 }}</span>
              <span>{{ brandMarketing.web.item_56 }}</span>
            </div>
            <div class="textinfo">
                <span class="imgtext">{{ brandMarketing.web.item_57 }}<span class="blue2"> {{ brandMarketing.web.item_58 }}</span></span>
                <span class="imgtext">&nbsp;<span class="blue2">&nbsp; </span></span>
            </div>
            <img :src="brandMarketing.mobile.img_4"/>
            <!-- <div class="ppyx_item4_textimg" :style="{ 'background-image': 'url(' + brandMarketing.mobile.img_4 + ')' }">
            </div> -->
          </div>
          </el-carousel-item>
        </el-carousel>


      
    </div>
</div>
</template>

<script>
import { coreBusiness } from '../config/environment';
export default {
  data() {
    return {
      brandMarketing: coreBusiness.brandMarketing,
      // 内容+运营+数据
      content: [
        {
          num: 1,
          imgurl: coreBusiness.brandMarketing.web.img_12,
          info1: coreBusiness.brandMarketing.web.item_45,
          info2_blue: coreBusiness.brandMarketing.web.item_46,
          info3: coreBusiness.brandMarketing.web.item_47,
        },
        {
          num: 2,
          imgurl: coreBusiness.brandMarketing.web.img_13,
          info1: coreBusiness.brandMarketing.web.item_48,
          info2_blue: coreBusiness.brandMarketing.web.item_49,
          info3: coreBusiness.brandMarketing.web.item_50,
        },
        {
          num: 3,
          imgurl: coreBusiness.brandMarketing.web.img_14,
          info1: coreBusiness.brandMarketing.web.item_51,
          info2_blue: coreBusiness.brandMarketing.web.item_52,
          info3: coreBusiness.brandMarketing.web.item_53,
        }
      ],
      cases: [
        {
          num: 1,
          name: coreBusiness.brandMarketing.mobile.item_9,
          itter: coreBusiness.brandMarketing.mobile.item_10,
          info: coreBusiness.brandMarketing.mobile.item_11,
          pu: coreBusiness.brandMarketing.mobile.item_12,
          hu: coreBusiness.brandMarketing.mobile.item_13,
          imgurl: coreBusiness.brandMarketing.web.img_15,
          sub_1: coreBusiness.brandMarketing.mobile.item_30,
          sub_2: coreBusiness.brandMarketing.mobile.item_31,
        },
        {
          num: 2,
          name: coreBusiness.brandMarketing.mobile.item_14,
          itter: coreBusiness.brandMarketing.mobile.item_15,
          info: coreBusiness.brandMarketing.mobile.item_16,
          pu: coreBusiness.brandMarketing.mobile.item_17,
          hu: coreBusiness.brandMarketing.mobile.item_18,
          imgurl: coreBusiness.brandMarketing.web.img_16,
          sub_1: coreBusiness.brandMarketing.mobile.item_32,
          sub_2: coreBusiness.brandMarketing.mobile.item_33,
        },
        {
          num: 3,
          name: coreBusiness.brandMarketing.mobile.item_19,
          itter: coreBusiness.brandMarketing.mobile.item_20,
          info: coreBusiness.brandMarketing.mobile.item_21,
          pu: coreBusiness.brandMarketing.mobile.item_22,
          hu: coreBusiness.brandMarketing.mobile.item_23,
          imgurl: coreBusiness.brandMarketing.web.img_17,
          sub_1: coreBusiness.brandMarketing.mobile.item_34,
          sub_2: coreBusiness.brandMarketing.mobile.item_35,
        },
        {
          num: 4,
          name: coreBusiness.brandMarketing.mobile.item_24,
          itter: coreBusiness.brandMarketing.mobile.item_25,
          info: coreBusiness.brandMarketing.mobile.item_26,
          pu: coreBusiness.brandMarketing.mobile.item_27,
          hu: coreBusiness.brandMarketing.mobile.item_28,
          imgurl: coreBusiness.brandMarketing.web.img_18,
          sub_1: coreBusiness.brandMarketing.mobile.item_36,
          sub_2: coreBusiness.brandMarketing.mobile.item_37,
        },
        {
          num: 5,
          name: coreBusiness.brandMarketing.mobile.item_38,
          itter: coreBusiness.brandMarketing.mobile.item_39,
          info: coreBusiness.brandMarketing.mobile.item_40,
          pu: coreBusiness.brandMarketing.mobile.item_42,
          hu: "",
          imgurl: coreBusiness.brandMarketing.web.img_20,
          sub_1: coreBusiness.brandMarketing.mobile.item_41,
          sub_2: "",
        }
      ]
    }
  },
  mounted(){
		this.setPY(1);
	},
  methods:{
		carouselChange:function(now){
      console.log(now,"====new");
			this.setPY(now);
		},
		setPY(now){
      if(now == 0){
        // eslint-disable-next-line no-undef
        $("#1").css("left","33%");
        // eslint-disable-next-line no-undef
        $("#0").css("left","0%");
        // eslint-disable-next-line no-undef
        $("#1").next().css("left","66%");
      }
      else{
        // eslint-disable-next-line no-undef
        $("#"+ now).css("left","33%");
        // eslint-disable-next-line no-undef
        $("#"+ now).prev().css("left","0%");
        // eslint-disable-next-line no-undef
        $("#" +now).next().css("left","66%");
      }
		},
	}
  
}
</script>

<style lang="less" scoped>
// 屏幕大于 750px 时应用该样式 
@media screen and (min-width: 751px) {
  .ppyx_item {
    display: block;
  }

  .ppyx_item2 {
    display: block;
  }

  .ppyx_item2_mobile {
    display: none !important;
  }

  .ppyx_item3 {
    // background-image: url(../assets/image/02web端切图（1X）/web_banner04.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .ppyx_info3,
  .ppyx_in {
    display: block;
  }

  .ppyx_info3_mobile,
  .ppyx_in_mobile,
  .ppyx_item4_info_mobile {
    display: none;
  }

  .ppyx_info3_text {
    display: flex;
    justify-content: space-between;

    .ppyx_info3_textdiv {
      // display: flex;
      // flex-direction: column;
      width: 24%;
      // border-radius: 15px;
      background-color: #f6f6f6;

      .ppyx_info3_span span:nth-child(1) {
        font-size: 0.26rem;
        font-family: MiSans-Normal;
        padding-top: 6%;
        padding-bottom: 9%;
      }

      .ppyx_info3_span span:nth-child(2) {
        font-family: MiSans-Bold;
        font-size: 0.18rem;
        color: #666;
      }
    }
  }

  .ppyx_item4 {
    padding: 6%;
  }

  .ppyx_item4_info,
  .el-carousel--horizontal {
    display: block;
  }
}

/* // 屏幕小于 750px 时应用该样式 */
@media screen and (max-width: 750px) {
  .ppyx_item {
    display: none;
  }

  .ppyx_item2 {
    display: none !important;
  }

  .ppyx_item2_mobile {
    display: block !important;
  }

  .ppyx_item3 {
    padding-bottom: 0 !important;
    background-color: #f6f6f6 !important;
  }

  .ppyx_info3,
  .ppyx_in,
  .ppyx_item4_info,
  .el-carousel--horizontal {
    display: none !important;
  }

  .ppyx_info3_mobile,
  .ppyx_in_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6% 0;

    span:nth-child(1) {
      font-family: MiSans-Bold;
      font-size: 0.28rem;
    }

    span:nth-child(3) {
      width: 50%;
      display: block;
      height: 0.02rem;
      background-color: #999;
      margin: 0.14rem 0;
    }

    span:nth-child(4) {
      width: 14%;
      display: block;
      height: 0.08rem;
      border-radius: 0.04rem;
      background-color: #5383ec;
      margin-top: -0.18rem;
      margin-bottom: 0.14rem;
    }

    span:nth-child(2) {
      font-size: 0.18rem;
      letter-spacing: 2px;
      color: #666;
    }
  }

  .ppyx_info3_text {
    padding: 5%;

    .ppyx_info3_textdiv {
      width: 100%;
      margin-bottom: 5%;
      background-color: #fff;

      .ppyx_info3_span span:nth-child(1) {
        font-size: 0.24rem;
        font-family: MiSans-Bold;
        // padding-top: 6%;
        padding-bottom: 6%;
      }

      .ppyx_info3_span span:nth-child(2) {
        font-family: MiSans-Normal;
        font-size: 0.16rem;
        color: #666;
      }
    }
  }

  .ppyx_item4 {
    padding: 10%;
  }

  .ppyx_item4_info_mobile {
    display: block;
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6;
    border-radius: 0.15rem;
    margin-bottom: 5%;

    .ppyx_info4 {
      display: flex;
      flex-direction: column;
      padding: 7%;

      span:nth-child(1) {
        font-family: MiSans-Bold;
        font-size: 0.28rem;
      }

      span:nth-child(2) {
        color: #9c9c9c;
        font-family: MiSans-Normal;
        font-size: 0.2rem;
        padding-bottom: 4%;
        border-bottom: 1px solid #ccc;
      }

      span:nth-child(3) {
        font-size: 0.16rem;
        color: #999;
        margin-bottom: 4%;
        padding-top: 4%;
        line-height: 0.3rem;
      }

      span:nth-child(4),
      span:nth-child(5) {
        font-size: 0.24rem;
      }

      .blue2 {
        font-size: 0.3rem !important;
      }
    }

    img {
      height: 40%;
      width: 100%;
      border-bottom-left-radius: 0.15rem;
      border-bottom-right-radius: 0.15rem;
    }
  }
}

/* 品牌营销 */
.ppyx img {
  width: 100%;
  display: block;
}

.ppyx .ppyx_info {
  span:nth-child(1) {
    display: block;
    color: #fff;
    font-family: MiSans-Bold;
    font-size: 0.47rem;
  }

  span:nth-child(2) {
    color: #5383ec;
    font-family: MiSans-Bold;
    display: block;
    font-size: 0.32rem;
  }
}

.ppyx_item {
  position: relative;
}

.ppyx_item .ppyx_info {
  position: absolute;
  top: 18%;
  left: 8%;
  width: 49%;
}

/* 内容+运营+数据 */
.ppyx_item2 {
  width: 100%;
  padding: 6%;
  display: flex;
  justify-content: space-between;

  .ppyx_info2 span {
    display: block;
  }

  .ppyx_info2 span:nth-child(1) {
    font-family: MiSans-Bold;
    color: black;
    font-size: 0.5rem;
  }

  img {
    width: 42%;
    border-radius: 0.15rem;
  }

  .ppyx_info2 {
    width: 48%;
  }

  .ppyx_info2 span:nth-child(2) {
    font-family: MiSans-Normal;
    font-size: 0.26rem;
    color: #666;
    line-height: 0.4rem;
  }

  .ppyx_info2 .ppyx_info2_div {
    background-color: #f6f6f6;
    height: 0.7rem;
    line-height: 0.7rem;
    width: auto;
    border-radius: 0.35rem;
    display: flex;
    margin-top: 0.2rem;
  }

  .ppyx_info2 .ppyx_info2_div img {
    width: 0.5rem;
    height: 0.5rem;
    margin-top: 0.1rem;
    margin-left: 0.1rem;
  }

  .ppyx_info2 .ppyx_info2_div span {
    // font-size: 0.2rem;
    // display: inline;
    font-size: 0.18rem;
    display: inline;
    line-height: 0.7rem;
    // margin-top: 0.16rem;
    margin-left: 0.1rem;
  }

  .blue {
    color: #5383ec !important;
    font-family: MiSans-Normal !important;
  }
}

/* 内容+运营+数据 移动端 */
.ppyx_item2_mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10%;

  .ppyx_info2_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6% 0;

    span:nth-child(1) {
      font-family: MiSans-Bold;
      font-size: 0.28rem;
    }

    span:nth-child(3) {
      width: 50%;
      display: block;
      height: 0.02rem;
      background-color: #999;
      margin: 0.14rem 0;
    }

    span:nth-child(4) {
      width: 14%;
      display: block;
      height: 0.08rem;
      border-radius: 0.04rem;
      background-color: #5383ec;
      margin-top: -0.18rem;
      margin-bottom: 0.14rem;
    }

    span:nth-child(2) {
      font-size: 0.16rem;
      letter-spacing: 2px;
      color: #666;
    }
  }

  img {
    border-radius: 0.15rem;
    margin-bottom: 5%;
  }

  .ppyx_info2_div_mobile {
    display: flex;
    justify-content: space-between;

    .ppyx_info2_div {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f3f7fe;
      padding: 5%;
      border-radius: 0.15rem;

      span {
        font-size: 0.12rem;
        // letter-spacing: 0.02rem;
      }

      img {
        width: 80%;
        margin-bottom: 10%;
      }

      .blue {
        color: #5383ec !important;
        font-family: MiSans-Normal !important;
      }
    }
  }
}

/* 服务 */
.ppyx_item3 {
  width: 100%;
  padding: 7%;
  // background-image: url(../assets/image/02web端切图（1X）/web_banner04.png);
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
}

.ppyx_item3 .ppyx_info3 span:nth-child(1),
.ppyx_item4 .ppyx_in span:nth-child(1) {
  width: 0.07rem;
  height: 0.4rem;
  background-color: #5383ec;
  display: inline-block;
  border-radius: 0.04rem;
  margin-right: 0.1rem;
}

.ppyx_item3 .ppyx_info3 span:nth-child(2),
.ppyx_item4 .ppyx_in span:nth-child(2) {
  font-family: MiSans-Bold;
  color: black;
  font-size: 0.45rem;
}

.ppyx_item3 .ppyx_info3 span:nth-child(3),
.ppyx_item4 .ppyx_in span:nth-child(3) {
  font-family: MiSans-Bold;
  color: #999;
  font-size: 0.3rem;
  margin-left: 0.2rem;
}

.ppyx_item3 {
  .ppyx_info3_text {
    // display: flex;
    // justify-content: space-between;
    margin-top: 5%;
  }

  .ppyx_info3_textdiv {
    display: flex;
    flex-direction: column;
    // width: 24%;
    border-radius: 0.15rem;
    // margin-right: 5%;
    // background-color: #f6f6f6;
  }

  .ppyx_info3_textdiv img {
    height: 40% !important;
    border-top-left-radius: 0.15rem;
    border-top-right-radius: 0.15rem;
    object-fit: cover;
  }

  .ppyx_info3_textdiv .ppyx_info3_span {
    padding: 5% 8% 10% 8%;
    height: 60%;
  }

  .ppyx_info3_textdiv .ppyx_info3_span span {
    display: block;
  }
}

/* 案例 */
.ppyx_item4 {
  width: 100%;
  // padding: 6%;
  padding-bottom: 10%;

  .ppyx_in {
    margin-bottom: 5%;
  }

  .ppyx_item4_info {
    display: flex;
    justify-content: space-between;
    background-color: #f6f6f6;
    border-radius: 0.15rem;

    img {
      width: 42%;
      border-top-right-radius: 0.15rem;
      border-bottom-right-radius: 0.15rem;
    }

    .ppyx_info4 {
      width: 52%;
      padding: 3% 5%;
    }
  }

  .ppyx_info4 img {
    width: 0.5rem;
    height: 0.5rem;
    margin-top: 5%;
  }

  .ppyx_item4_info .ppyx_info4 span {
    display: block;
  }

  .ppyx_item4_info .ppyx_info4 span:nth-child(1) {
    font-family: MiSans-Bold;
    font-size: 0.3rem;
  }

  .ppyx_item4_info .ppyx_info4 span:nth-child(2) {
    color: #9c9c9c;
    font-family: MiSans-Normal;
    font-size: 0.26rem;
    margin-bottom: 5%;
  }

  .ppyx_item4_info .ppyx_info4 span:nth-child(3) {
    font-size: 0.18rem;
    color: #999;
    margin-bottom: 4%;
    line-height: 0.3rem;
  }

  .ppyx_item4_info .ppyx_info4 span:nth-child(4) {
    font-size: 0.24rem;
  }

  .blue2 {
    color: #5383ec !important;
    font-size: 0.3rem !important;
    font-family: MiSans-Bold !important;
    display: inline !important;
  }

  .el-carousel--horizontal {
    overflow-x: hidden;
    width: 110%;
    height: auto;
    margin-top: 71px;
    margin-left: -5%;
    margin-right: -5%;
    padding: 5% 5% 0;
}
/deep/.el-carousel__container {
    position: relative;
    height: 740px!important;
    width: 100%!important;
}
/deep/.el-carousel__mask{
  display: none;
}

/deep/.el-carousel__item--card.is-in-stage{
  cursor: default;
  border-bottom-left-radius: 0.15rem;
    border-bottom-right-radius: 0.15rem;
}
/deep/.el-carousel__arrow{
  display: block!important;
  transition: none!important;
  transform: none!important;
}
/deep/.el-carousel__arrow--right{
  right: -35px;
  height: 45px;
  width: 45px;
  font-size: 16px;
}
/deep/.el-carousel__arrow--left{
  left: -61px;
  height: 45px;
  width: 45px;
  font-size: 16px;
}

  .el-carousel__item {
    display: flex;
    justify-content: space-between;
    margin: 0% 0 -2%;
    height: 740px;
    width: 32%;
    margin-right: 1%;
    transform: scale(1)!important;

    .ppyx_item4_textdiv {
      background-color: #f3f7fe;
      border-radius: 0.15rem;

      .ppyx_item4_textspan {
        padding: 9% 6%;
        height: 33%;
        padding-bottom: 0;

        span {
          display: block;
        }

        span:nth-child(1) {
          font-size: 0.26rem;
          font-family: MiSans-Bold;
        }

        span:nth-child(2) {
          font-size: 0.22rem;
          font-family: MiSans-Normal;
          color: #999;
          padding-bottom: 5%;
          border-bottom: 1px solid #ccc;
        }

        span:nth-child(3) {
          color: #666;
          font-size: 0.18rem;
          line-height: 0.3rem;
          padding-top: 5%;
          font-family: MiSans-Normal;
        }
      }

      .ppyx_item4_textimg {
        padding: 9% 6%;
        height: 53%;
        // background-image: url(../assets/image/02web端切图（1X）/web_banner18.png);
        background-size: 100%;
        // background-position: 0 -0px;
        background-repeat: no-repeat;
        border-bottom-left-radius: 0.15rem;
        border-bottom-right-radius: 0.15rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  // .ppyx_item4_text 

  // .ppyx_item4_text .ppyx_item4_textdiv 

  // .ppyx_item4_text .ppyx_item4_textdiv .ppyx_item4_textspan 

  // .ppyx_item4_text .ppyx_item4_textdiv .ppyx_item4_textspan 

  // .ppyx_item4_text .ppyx_item4_textdiv .ppyx_item4_textspan 

  // .ppyx_item4_text .ppyx_item4_textdiv .ppyx_item4_textspan 

  // .ppyx_item4_text .ppyx_item4_textdiv 

  .textinfo {
    width: auto;
    // text-align: center;
    padding-left: 6%;
    padding-bottom: 20px;
  }

  .imgtext {
    display: block;
    // color: #fff;
    font-family: MiSans-Normal;
    font-size: 0.24rem;
  }

  .ppyx_item4_text {
    img {
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
    }

    img:nth-child(1) {
      top: 55%;
      left: -5%;
      transform: rotate(180deg);
    }

    img:nth-child(5) {
      top: 55%;
      right: -5%;
    }

    img:hover {
      scale: 1.2;
    }
  }
}
</style>