<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="hxyw">
      <div class="hxyw_item">
        <img :src="coreBusiness.web.img_1" alt="">
        <div class="hxyw_info">
          <span>{{ coreBusiness.web.item_1 }}</span>
          <span>{{ coreBusiness.web.item_2 }}</span>
          <span>{{ coreBusiness.web.item_3 }}</span>
        </div>
      </div>
      <div class="list-box">
        <div class="list">
          <router-link to="/index/hxyw/ppyx" active-class="active">
            <span>{{ coreBusiness.web.item_4 }}</span>
          </router-link>
          <router-link to="/index/hxyw/xgyx" active-class="active">
            <span>{{ coreBusiness.web.item_5 }}</span>
          </router-link>
          <router-link to="/index/hxyw/zbydsp" active-class="active">
            <span>{{ coreBusiness.web.item_6 }}</span>
          </router-link>
          <router-link to="/index/hxyw/yxsjzh" active-class="active">
            <span>{{ coreBusiness.web.item_7 }}</span>
          </router-link>
      </div>
      </div>
      
      <router-view></router-view>
    </div>
  </template>
  
  <script>
    import { coreBusiness } from "../config/environment";
  export default {
    data() {
      return {
        coreBusiness
      }
    }
    
  }
  </script>
  
  <style lang="less" scoped>
   // 屏幕大于 750px 时应用该样式 
@media screen and (min-width: 751px) {
  .hxyw{
    margin-top:1.01rem;
  }
  .hxyw_item {
    display: none;
  }
  .list{
    margin-left: 45%;
    a{
      margin-right: 0.4rem;
    }
  }
}

/* // 屏幕小于 750px 时应用该样式 */
@media screen and (max-width: 750px) {
  .hxyw_item {
    display: block;

    .hxyw_info{
      top: 30%!important;
      span:nth-child(1) {
        font-size: 0.28rem!important;
      }
      span:nth-child(2) {
        font-size: 0.18rem!important;
      }

      span:nth-child(3) {
        font-size: 0.14rem!important;
        margin-top:5%;
      }
    }
  }
  .list-box {
    width: 100vw;
    overflow-x: scroll;
    background-color: #f6f6f6;

  }
  .list{
    width:auto;
    height:0.6rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    a{
      padding:0 0.6rem;

      span {
        white-space: nowrap;
      }
    }
  }
  .active{
    border-bottom:0.04rem solid #5383ec;
  }
}
  .hxyw{
    .hxyw_item{
      position: relative;
    }
    .hxyw_item .hxyw_info{
      position: absolute;
      top: 14%;
      left: 8%;

      span:nth-child(1){
        display: block;
        color: #fff;
        font-family: MiSans-Bold;
        font-size: 0.4rem;
      }
      span:nth-child(2){
        font-size: 0.24rem;
        color: #5383ec;
        font-family: MiSans-Bold;
        display: block;
      }
        span:nth-child(3){
        font-size: 0.2rem;
        display: block;
        font-family: MiSans-Normal;
        color: #8f8f8f;
        letter-spacing: 2px;
      }
    }
    .hxyw_item img:nth-child(1){
      width:100%;
      display: block;
      height:2.5rem;
    }
  }
  .hxyw .list{
    // display: block;
    font-family: MiSans-Normal;
    font-size: 0.16rem;
  }
  .list a{
    display: inline-block;
    height: 0.6rem;
    line-height: 0.6rem;
    color: black;
  }
  .list a:hover{
    color:#666;
  }
  .active {
    font-family: MiSans-Bold;
  }
  </style>