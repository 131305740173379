<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="footer" :style="{'background-image': 'url(' + footerData.web.img_0 + ')'}">
    <img :src="footerData.web.img_1" alt="">
    <div class="footer_info">
      <div class="footer_item">
        <img :src="footerData.web.img_2" alt="">
        <span>{{ footerData.web.address }}</span>
      </div>
      <!-- <div class="footer_item">
        <img :src="footerData.web.img_4" alt="">
        <span>{{ footerData.web.tel }}</span>
      </div> -->
      <div class="footer_item">
        <img :src="footerData.web.img_3" alt="">
        <span>{{ footerData.web.mail }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;普菲特（海南）科技有限公司</span>
      </div>
    </div>
    <div class="footer_note">
      <span>Copyright ©️ 2023 All Rights Reserved&nbsp;&nbsp;</span>
        <span>{{ footerData.web.company }}&nbsp;&nbsp;</span>
        <span style="padding:10px 0;">
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502036205" style="display:inline-block;text-decoration:none;height:20px;line-height:30px;">
            <img src="http://offweb-res.perfect-cn.cn/offweb/web/备案图标.png" style="float:left;"/>
            <p style="float:left;height:20px;line-height:25px;margin: 0px 0px 0px 5px; color:#666;">京公网安备 11010502036205号</p>
          </a>
          &nbsp;&nbsp;
        </span>
        <span style="padding:10px 0;">
          <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery" style="display:inline-block;text-decoration:none;height:20px;line-height:30px;">
            <p style="float:left;height:20px;line-height:25px;margin: 0px 0px 0px 5px; color:#666;">京ICP备13041270号-1</p>
          </a>
          &nbsp;&nbsp;
        </span>
         <!-- <span>京公网安备 京ICP备13041270号-1</span> -->
      <div class="footer_img">
        <img :src="footerData.web.img_5" alt="">
        <img :src="footerData.web.img_6" alt="">
        <img :src="footerData.web.img_7" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { footerData } from '../../config/environment'

export default {
  data() {
    return {
        footerData
    }
  }
}
</script>

<style lang="less" scoped>
.footer{
  width: 100%;
  // height: 310px;
  // background-image: url(../../assets/image/02web端切图（1X）/web_bg1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 4% 7% 7.5% 8%;
}
.footer img {
  height: 0.7rem;
  width: 2.4rem;
}
.footer span {
  font-family: MiSans-Normal;
  color: #fff;
  font-size: 0.16rem;
}

// 屏幕大于 750px 时应用该样式
@media screen and (min-width: 751px) {
  .footer_info{
    border-bottom: 1px solid #fff;
    display: flex;
    padding-bottom: 2%;
    height:0.7rem;
  }
  .footer_info .footer_item {
    height:0.7rem;
    margin-right: 0.25rem;
    margin-top: -0.9rem;
  }
  .footer_img {
    float: right;
  }
  .footer_note {
    height:0.7rem;
    margin-top: -0.6rem;
  }
  .footer_note img {
  width: 0.25rem;
  height: 0.25rem;
  margin-left:0.1rem;
}
}

// 屏幕小于 750px 时应用该样式
@media screen and (max-width: 750px) {
  // .footer img:nth-child(1){
  //   width:1.8rem;
  // }
  .footer_info{
    border-top: 1px solid #fff;
    // padding-top:5%;
    height: 2rem;
  }
  .footer_info .footer_item {
    // margin-bottom: 2%;
    margin-top: -0.2rem;
    height: 0.7rem;
  }
  .footer_note span{
    display: block;
  }
  .footer_img {
    margin-top: -0.4rem;
  }
  .footer_note{
    margin-top: 0.4rem;

    img {
      width: 0.35rem;
      height: 0.35rem;
      margin-right:0.1rem;
    }
  }
}

.footer_info img {
  width: 0.2rem;
  height: 0.2rem;
  color: #fff;
  // vertical-align: middle;
  margin-right: 0.1rem;
  margin-bottom: -0.05rem;
}
// .footer_note {
  // margin-top: 0.2rem;
// }
.footer_note span{
  color: #666;
  font-size: 0.18rem;
  white-space: nowrap;
}

</style>