<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="sy">
    <div class="sy_item">
      <img class="top1" :src="homeData.web.img_1" alt="">
      <img class="top2" :src="homeData.web.img_1" alt="">
      <div class="top-img-box">
        <span class="info1">{{ homeData.web.item_1 }}</span>
        <span class="small-info1">(Make Every Connection More Valuable)</span>
        <span class="info2">{{ homeData.web.item_2 }}</span>
        <span class="small-info2">(Based on "Data,Creativity and Customized Services", we provide major brands an integrated and intelligent marketing service)</span>
      </div>
    </div>
    <!-- 轮播图 -->
    <!-- <div>
      <el-carousel height="6.53rem">
        <el-carousel-item>
          <div class="sy_img">
            <img :src="homeData.web.img_1" alt="">
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="sy_img">
            <img :src="homeData.web.img_2" alt="">
          </div>
        </el-carousel-item>
      </el-carousel>
    </div> -->

    <!-- 让每一次连接更有意义 -->
    <!-- <div class="sy_item">
      <img class="img1" :src="homeData.web.img_3" alt="">
      <img class="img2" :src="homeData.mobile.img_1" alt="">
      <div class="img-box">
        <span class="info1">{{ homeData.web.item_1 }}</span>
        <span class="info2">{{ homeData.web.item_2 }}</span>
      </div>
    </div> -->
    <!-- 专业致力于智能整合营销 web-->
    <div class="sy_item2">
      <img :src="homeData.web.img_4" alt="">
      <div class="sy_info2">
        <span>{{ homeData.web.item_3 }}</span>
        <span>{{ homeData.web.item_4 }}</span>
        <span>{{ homeData.web.item_5 }}</span>
        <span>{{ homeData.web.item_6 }}</span>
        <!-- <button>{{ homeData.web.item_7 }}<img :src="homeData.web.img_5" alt=""></button> -->
      </div>
    </div>
    <!-- 专业致力于智能整合营销 移动端 -->
    <div class="sy_item2_mobile">
      <div class="sy_info2_mobile">
        <span>{{ homeData.mobile.item_1 }}</span>
        <span></span>
        <span></span>
        <span>{{ homeData.mobile.item_2 }}</span>
        <!-- <button>{{ homeData.web.item_7 }}<img :src="homeData.web.img_6" alt=""></button> -->
      </div>
      <img :src="homeData.web.img_7" alt="">
    </div>
    <!-- 品牌营销 -->
    <div class="sy_item3">
      <img :src="homeData.web.img_8" alt="">
      <div class="sy_info3">
        <span style="color: #fff;">{{ homeData.web.item_8 }}</span>
        <span style="color: #d3d2d2">{{ homeData.web.item_9 }}</span>
        <span style="color: #b5b5b5">{{ homeData.web.item_10 }}</span>
        <button @click="$router.push('/index/hxyw/ppyx')" >
          {{ homeData.web.item_7 }}
          <img :src="homeData.web.img_9" alt="">
        </button>
      </div>
    </div>
    <!-- 品牌营销 移动端-->
    <div class="sy_item3_mobile">
      <div class="sy_info3_mobile">
        <span>{{ homeData.mobile.item_3 }}</span>
        <span>{{ homeData.mobile.item_4 }}</span>
        <button @click="$router.push('/index/hxyw/ppyx')">{{ homeData.web.item_7 }}<img :src="homeData.web.img_10"
            alt=""></button>
      </div>
      <img :src="homeData.mobile.img_2" alt="">
    </div>
    <!-- 效果营销 -->
    <div class="sy_item4">
      <img :src="homeData.web.img_11" alt="">
      <div class="sy_info4">
        <span>{{ homeData.web.item_11 }}</span>
        <span>{{ homeData.web.item_12 }}</span>
        <span>{{ homeData.web.item_13 }}</span>
        <button @click="$router.push('/index/hxyw/xgyx')">{{ homeData.web.item_7 }}<img :src="homeData.web.img_12"
            alt=""></button>
      </div>
    </div>
    <!-- 效果营销 移动端-->
    <div class="sy_item4_mobile">
      <div class="sy_info4_mobile">
        <span>{{ homeData.mobile.item_5 }}</span>
        <span>{{ homeData.mobile.item_6 }}</span>
        <button @click="$router.push('/index/hxyw/xgyx')">{{ homeData.web.item_7 }}<img :src="homeData.web.img_13"
            alt=""></button>
      </div>
      <img :src="homeData.web.img_14" alt="">
    </div>
    <!-- 直播与短视频解决方案 -->
    <div class="sy_item5">
      <img :src="homeData.web.img_15" alt="">
      <div class="sy_info5">
        <span>{{ homeData.web.item_14 }}</span>
        <span>{{ homeData.web.item_15 }}</span>
        <span>{{ homeData.web.item_16 }}</span>
        <span>{{ homeData.web.item_17 }}</span>
        <button @click="$router.push('/index/hxyw/zbydsp')">{{ homeData.web.item_7 }}<img :src="homeData.web.img_16"
            alt=""></button>
      </div>
    </div>
    <!-- 直播与短视频解决方案 移动端-->
    <div class="sy_item5_mobile">
      <div class="sy_info5_mobile">
        <span>{{ homeData.mobile.item_7 }}</span>
        <span>{{ homeData.mobile.item_8 }}</span>
        <button @click="$router.push('/index/hxyw/zbydsp')">{{ homeData.web.item_7 }}<img :src="homeData.web.img_17"
            alt=""></button>
      </div>
      <img :src="homeData.mobile.img_3" alt="">
    </div>
    <!-- 营销数据整合与洞察 -->
    <div class="sy_item6">
      <img :src="homeData.web.img_18" alt="">
      <div class="sy_info6">
        <span>{{ homeData.web.item_18 }}</span>
        <span>{{ homeData.web.item_19 }}</span>
        <span>{{ homeData.web.item_20 }}</span>
        <span>{{ homeData.web.item_21 }}</span>
        <button @click="$router.push('/index/hxyw/yxsjzh')">{{ homeData.web.item_7 }}<img :src="homeData.web.img_19"
            alt=""></button>
      </div>
    </div>
    <!-- 营销数据整合与洞察 移动端-->
    <div class="sy_item6_mobile">
      <div class="sy_info6_mobile">
        <span>{{ homeData.mobile.item_9 }}</span>
        <span>{{ homeData.mobile.item_10 }}</span>
        <button @click="$router.push('/index/hxyw/yxsjzh')">{{ homeData.web.item_7 }}<img :src="homeData.web.img_20"
            alt=""></button>
      </div>
      <img :src="homeData.web.img_21" alt="">
    </div>
    <!-- 我们的服务客户 -->
    <div class="sy_item7" :style="{'background-image': 'url(' + homeData.web.home_logo_background + ')'}">
      <p>{{ homeData.web.item_22 }}</p>
      <p>{{ homeData.web.item_23 }}</p>
      <div class="one" ><img :src="homeData.web.home_logo2" alt=""></div>
      
      <!-- <p></p> -->
      <div class="sy_info7 clearfix">
        <div class="two"><img :src="homeData.web.hezuohuoban_1" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_2" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_3" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_4" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_5" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_6" alt=""></div>

        <div class="two"><img :src="homeData.web.hezuohuoban_7" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_8" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_9" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_10" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_11" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_12" alt=""></div>

        <div class="two"><img :src="homeData.web.hezuohuoban_13" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_14" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_15" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_16" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_17" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_18" alt=""></div>

        <div class="two"><img :src="homeData.web.hezuohuoban_19" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_20" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_21" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_22" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_23" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_24" alt=""></div>

        <div class="two"><img :src="homeData.web.hezuohuoban_25" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_26" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_27" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_28" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_29" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_30" alt=""></div>

        <div class="two"><img :src="homeData.web.hezuohuoban_31" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_32" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_33" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_34" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_35" alt=""></div>
        <div class="two"><img :src="homeData.web.hezuohuoban_36" alt=""></div>
        
        <!-- <div class="two"><img :src="homeData.web.home_logo1" alt=""></div> -->
        <!-- <div class="one">
          <img :src="homeData.web.hezuohuoban_1" alt="">
        </div>
        <div class="one"><img :src="homeData.web.hezuohuoban_2" alt=""></div>
        <div class="one"><img :src="homeData.web.hezuohuoban_3" alt=""></div>
        <div class="one"><img :src="homeData.web.hezuohuoban_4" alt=""></div>
        <div class="one"><img :src="homeData.web.hezuohuoban_5" alt=""></div>
        <div class="one"><img :src="homeData.web.hezuohuoban_6" alt=""></div>
        <div class="one"><img :src="homeData.web.hezuohuoban_7" alt=""></div>
        <div class="one"><img :src="homeData.web.hezuohuoban_8" alt=""></div>
        <div class="one"><img :src="homeData.web.hezuohuoban_9" alt=""></div>
        <div class="one"><img :src="homeData.web.hezuohuoban_10" alt=""></div> -->

      </div>
      <p class="text">{{ homeData.web.item_24 }}</p>
      <!-- web是弹窗 -->
      <button class="button1" @click="dialogFormVisible = true">{{ homeData.web.btn_1 }}</button>
      <!-- 移动端是页面 -->
      <button class="button1_mobile" @click="$router.push('/ljhz')">{{ homeData.mobile.btn_1 }}</button>
      <button @click="$router.push('/index/lxwm')" class="button2">{{ homeData.mobile.btn_2 }}</button>
    </div>
    <!-- 立刻合作弹窗 -->
    <el-dialog :title="homeData.web.item_25" :visible.sync="dialogFormVisible">
      <el-form ref="form" :model="form" label-width="80px">
        <div class="row">
          <el-form-item :label="homeData.web.item_26">
            <el-input v-model="form.name" :placeholder="homeData.web.item_27"></el-input>
          </el-form-item>
          <el-form-item :label="homeData.web.item_28">
            <el-input v-model="form.company" :placeholder="homeData.web.item_29"></el-input>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item :label="homeData.web.item_30">
            <el-input v-model="form.email" :placeholder="homeData.web.item_31"></el-input>
          </el-form-item>
          <el-form-item :label="homeData.web.item_32">
            <el-input v-model="form.phone" :placeholder="homeData.web.item_33"></el-input>
          </el-form-item>
        </div>
        <el-form-item :label="homeData.web.item_34">
          <el-checkbox-group v-model="form.type">
            <el-checkbox :label="homeData.web.item_35" name="type"></el-checkbox>
            <el-checkbox :label="homeData.web.item_36" name="type"></el-checkbox>
            <el-checkbox :label="homeData.web.item_37" name="type"></el-checkbox>
            <el-checkbox :label="homeData.web.item_38" name="type"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item :label="homeData.web.item_39">
          <el-input type="textarea" v-model="form.desc"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogFormVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogFormVisible = false">{{ homeData.web.btn_2 }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { homeData } from '../config/environment';

export default {
  data() {
    return {
      homeData,
      dialogFormVisible: false,
      form: {
        name: '',
        company: '',
        email: '',
        phone: '',
        type: [],
        desc: ''
      },
    }
  }
}
</script>

<style lang="less" scoped>
// 屏幕大于 750px 时应用该样式
@media screen and (min-width: 751px) {
  .sy {
    margin-top: 1.01rem;
  }

  .top1 {
    // height: 650px;
    width: 200px;
    height: 45vw;
    object-fit: fill;
    object-position: 0 17%;
  }
  .top2 {display: none !important;}

  .top-img-box {
    width: 100%;
    position: absolute;
    top: 25% !important;
    // left: 20%!important;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    // height: 40%;
    .info1 {
      font-size: 0.48rem;
      font-family: MiSans-Bold;
    }

    .small-info1 {
      position: relative;
      font-size: 0.15rem;
      // top: -15%;
      // left: -1%;
    }

    .info2 {
      font-family: MiSans-Bold;
      font-size: 0.22rem;
      line-height: 0.4rem;
      // width: 8.36rem;
    }
    .small-info2 {
      position: relative;
      font-size: 0.15rem;
      // top: -15%;
      // left: 4%;
    }
  }

  .img1 {
    display: block !important;
  }

  .img2 {
    display: none !important;
  }

  .sy_img {
    span:nth-child(2) {
      top: 3%;
    }

    span:nth-child(3),
    span:nth-child(4) {
      font-size: 0.2rem;
      top: 12%;
      left: 34%;
    }

    span:nth-child(4) {
      left: 28%;
      top: 16%;
    }
  }

  .sy_item {
    position: relative;

    .img-box {
      position: absolute;
      top: 25% !important;
      left: 8% !important;

      .info1 {
        font-size: 0.48rem;
      }

      .info2 {
        color: #666;
        font-size: 0.22rem;
        width: 5.36rem;
        line-height: 0.4rem;
      }
    }
  }

  .sy_item2,
  .sy_item3,
  .sy_item4,
  .sy_item5,
  .sy_item6 {
    display: block;
    display: flex;
  }

  .sy_item2_mobile,
  .sy_item3_mobile,
  .sy_item4_mobile,
  .sy_item5_mobile,
  .sy_item6_mobile {
    display: none !important;
  }

  .sy_item7 {
    background-size: cover;
    background-size: 100% 100%;
    -moz-background-size:100% 100%;

    .one {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2%;
      img {
        width: 422px;
        height: 53px;
        object-fit: cover;
      }
    }
    .two {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2%;
      width: 16.5%;
      img {
        // width: 188px;
        height: auto;
        object-fit: cover;
      }
    }
    .sy_info7 {
      display: flex;
      flex-wrap: wrap;
      margin-right: 3%;
      margin-left: 3%;

      // .one {
      //   width: 17%;
      //   margin-right: 3%;
      //   height: 1rem;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   img {
      //     height: 40%;
      //     width: auto !important;
      //   }
      // }
    }

    button {
      text-align: center;
      width: 1.6rem;
      height: 0.56rem;
      border-radius: 0.28rem;
      font-size: 0.18rem;
      border: none;
      margin-top: 0.2rem;
    }

    .button1 {
      margin-left: calc(50% - 1.75rem);
    }

    .button1_mobile {
      display: none;
    }
  }

  /* 立即合作弹窗 */
  /deep/.el-dialog {
    border-radius: 0.15rem;
    font-family: MiSans-Normal;
    color: black;
    background-color: #f9f9f9;

    /deep/.el-dialog__title {
      font-size: 0.28rem;
      color: black;
      text-align: center;
      font-family: MiSans-Bold;
      display: block;
      line-height: 0.45rem;
    }

    /deep/.el-dialog__body {
      padding-top: 0;
    }

    /deep/.el-form-item {
      display: flex;
      flex-direction: column;
      width: 45%;
      margin: 0;
    }

    /deep/.el-form-item__content {
      margin-left: 0.4rem !important;
      ;
    }

    .row {
      display: flex;
    }

    /deep/.el-input__inner {
      border-radius: 0.1rem;
      font-size: 0.16rem;
    }

    /deep/.el-form-item__label {
      text-align: left;
      margin-left: 0.43rem;
    }

    /deep/.el-form-item__content {
      line-height: 0px;
    }

    /deep/.el-checkbox-group {
      display: flex;
    }

    /deep/.el-checkbox__label {
      font-size: 0.16rem;
    }

    /deep/.el-checkbox__inner {
      width: 0.18rem;
      height: 0.18rem;
    }

    /deep/.el-textarea__inner {
      border-radius: 0.1rem;
      line-height: 3.5;
      width: 215%;
    }

    /deep/.el-button--primary {
      width: 1.8rem;
      height: 0.4rem;
      border-radius: 0.2rem;
      background-color: black;
      border: none;
    }

    /deep/.dialog-footer {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    /deep/.el-dialog__headerbtn .el-dialog__close {
      font-size: 0.25rem;
    }

  }
}

// 屏幕小于 750px 时应用该样式
@media screen and (max-width: 750px) {
  .top1 {
    display: none !important;
  }
  
  .top2 {
    // height: 500px;
    height: 65vw;
  }

  // .top-img-box {
  //   position: absolute;
  //   top: 12% !important;
  //   color: #fff;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  //   height: 50%;
  //   .info1 {
  //     font-size: 0.48rem;
  //     text-align: center;
  //   }
  //   .info2 {
  //     text-align: left !important;;
  //     font-size: 0.22rem;
  //     line-height: 0.4rem;
  //   }
  // }

  .top-img-box {
    width: 100%;
    position: absolute;
    top: 25% !important;
    // left: 20%!important;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    // height: 40%;
    .info1 {
      font-size: 0.48rem;
      font-family: MiSans-Bold;
    }

    .small-info1 {
      position: relative;
      font-size: 0.15rem;
      // top: -15%;
      // left: -1%;
    }

    .info2 {
      font-family: MiSans-Bold;
      font-size: 0.22rem;
      line-height: 0.4rem;
      // width: 8.36rem;
    }
    .small-info2 {
      position: relative;
      font-size: 0.15rem;
      // top: -15%;
      // left: 4%;
    }
  }

  .img1 {
    display: none !important;
  }

  .img2 {
    display: block !important;
  }

  .sy_img {
    img {
      height: 6.53rem;
    }

    span {
      text-align: center;
    }

    span:nth-child(2) {
      top: 2%;
      width: 70%;
    }

    span:nth-child(3) {
      font-size: 0.18rem;
      top: 19%;
      left: 25%;
    }

    span:nth-child(4) {
      font-size: 0.18rem;
      top: 22%;
      left: 13%;
    }
  }

  .sy_item {
    position: relative;

    .img-box {
      padding: 5% 10%;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 5%;
    }
  }

  .sy_item .info1 {
    color: #fff;
    font-size: 0.24rem;
    text-align: center;
  }

  .sy_item .info2 {
    text-align: center;
    color: #fff;
    font-size: 0.12rem;
    // width:auto !important;
    // padding-right:0.24rem;
    margin-top: 0.08rem;
    line-height: 0.28rem !important;
  }

  .sy_item2_mobile,
  .sy_item3_mobile,
  .sy_item4_mobile,
  .sy_item5_mobile,
  .sy_item6_mobile {
    display: block !important;
  }

  .sy_item2,
  .sy_item3,
  .sy_item4,
  .sy_item5,
  .sy_item6 {
    display: none !important;
  }

  .sy_item7 {
    // .sy_info7 img {
    //   width: 47%;
    //   float: left;
    //   margin-right: 3%;
    //   margin-bottom: 2%;
    // }

  .sy_info7 {
    display: flex;
    flex-wrap: wrap;
  }

  .one {
      display: none;
  }
  .two {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 2%;
      width: 32%;
      img {
        // width: 188px;
        height: auto;
        object-fit: cover;
      }
    }

    button[data-v-b45ede2c] {
      text-align: center;
      width: 30%;
      height: 0.5rem;
      // padding: 0 5px;
      border-radius: 0.35rem;
      font-size: 0.15rem;
      border: none;
      margin-top: 0.2rem;
    }

    .button1 {
      display: none;
    }

    .button1_mobile {
      margin-left: 16%;
    }

    p:nth-child(1) {
      font-size: 0.28rem !important;
      margin-bottom: 3% !important;
    }

    p:nth-child(2) {
      font-size: 0.16rem !important;
      margin-bottom: 3% !important;
      padding: 0 8% !important;
    }
  }

}

.sy {
  width: 100%;
  overflow-x: hidden;
}

.sy img {
  width: 100%;
  display: block;
}

.sy_img {
  position: relative;

  span {
    display: block;
    color: #fff;
    position: absolute;
  }

  span:nth-child(2) {
    font-family: MiSans-Bold;
    font-size: 0.45rem;
    left: 18%;
  }

  span:nth-child(3),
  span:nth-child(4) {
    font-family: MiSans-Normal;
  }
}

.sy_item {

  .img-box {
    display: flex;
    flex-direction: column;

    .info1 {
      font-family: MiSans-Bold;
    }

    .info2 {
      font-family: MiSans-Normal;
      // width: 5.36rem;
      // line-height: 0.4rem;
    }
  }
}

.sy_item2,
.sy_item4,
.sy_item6 {
  width: 100%;
  display: flex;
  align-items: center;
}

.sy_item2 img,
.sy_item4 img,
.sy_item6 img {
  display: block;
  width: 40%;
  height: auto;
  margin-top: 10%;
  margin-bottom: 10%;
  margin-left: 9%;
  border-radius: 0.2rem;
}

.sy_item2 .sy_info2,
.sy_item4 .sy_info4,
.sy_item6 .sy_info6 {
  width: 38%;
  margin-left: 9%;
}

.sy_item2 .sy_info2 span:nth-child(1),
.sy_item3 .sy_info3 span:nth-child(1),
.sy_item4 .sy_info4 span:nth-child(1),
.sy_item5 .sy_info5 span:nth-child(1),
.sy_item6 .sy_info6 span:nth-child(1) {
  display: block;
  font-family: MiSans-Bold;
  font-size: 0.44rem;
}

.sy_item2 .sy_info2 span:nth-child(2),
.sy_item2 .sy_info2 span:nth-child(3),
.sy_item3 .sy_info3 span:nth-child(2),
.sy_item4 .sy_info4 span:nth-child(2),
.sy_item5 .sy_info5 span:nth-child(2),
.sy_item5 .sy_info5 span:nth-child(3),
.sy_item6 .sy_info6 span:nth-child(2),
.sy_item6 .sy_info6 span:nth-child(3) {
  display: block;
  font-family: MiSans-Bold;
  font-size: 0.32rem;
  color: #999;
}

.sy_item2 .sy_info2 span:nth-child(4),
.sy_item3 .sy_info3 span:nth-child(3),
.sy_item4 .sy_info4 span:nth-child(3),
.sy_item5 .sy_info5 span:nth-child(4),
.sy_item6 .sy_info6 span:nth-child(4) {
  display: block;
  font-family: MiSans-Normal;
  font-size: 0.18rem;
  color: #666;
  letter-spacing: 2px;
  line-height: 0.4rem;
  margin-top: 0.15rem;
}

.sy_item2 .sy_info2 button,
.sy_item3 .sy_info3 button,
.sy_item4 .sy_info4 button,
.sy_item5 .sy_info5 button,
.sy_item6 .sy_info6 button {
  width: 1.4rem;
  height: 0.56rem;
  background-color: black;
  border-radius: 0.28rem;
  color: #fff;
  border: none;
  line-height: 0.56rem;
  font-size: 0.18rem;
  font-family: MiSans-Normal;
  margin-top: 0.2rem;
}

.sy_item2 .sy_info2 button:hover,
.sy_item3 .sy_info3 button:hover,
.sy_item4 .sy_info4 button:hover,
.sy_item5 .sy_info5 button:hover,
.sy_item6 .sy_info6 button:hover {
  background-color: #666;
}

.sy_item2 .sy_info2 button img,
.sy_item3 .sy_info3 button img,
.sy_item4 .sy_info4 button img,
.sy_item5 .sy_info5 button img,
.sy_item6 .sy_info6 button img {
  width: 0.2rem;
  height: 0.2rem;
  display: inline;
  margin: 0;
  margin-bottom: 4px;
  vertical-align: middle;
}

.sy_item3,
.sy_item5 {
  position: relative;
}

.sy_item3 .sy_info3,
.sy_item5 .sy_info5 {
  position: absolute;
  top: 32%;
  left: 10%;
}

.sy_item7 {
  width: 100%;
  padding: 8% 5%;
  background-size: cover;
  // background-size: contain;
  // background-color: #f6f6f6;
  // background-image: url("http://offweb-res.perfect-cn.cn/offweb/web/首页合作伙伴北京.png");
  background-size: 100% 100%;
  -moz-background-size:100% 100%;

  p {
    text-align: center;
  }

  p:nth-child(1) {
    color: #FFFFFF;
    font-family: MiSans-Bold;
    font-size: 0.47rem;
  }

  p:nth-child(2) {
    font-size: 0.18rem;
    font-family: MiSans-Normal;
    color: #FFFFFF;
    letter-spacing: 2px;
    line-height: 0.26rem;
    margin-bottom: 3%;
    padding: 0 20%;
  }

  .text {
    display: block;
    font-size: 0.24rem;
    font-family: MiSans-Normal;
    margin-top: 4%;
  }

  .button1,
  .button1_mobile {
    background-color: black;
    color: #fff;
    margin-right: 0.3rem;
  }

  .button1:hover,
  .button1_mobile:hover {
    background-color: #666;
  }

  .button2 {
    background-color: #fff;
    color: black;
  }

  .button2:hover {
    background-color: #ccc;
  }
}

.clearfix::after {
  content: '';
  clear: both;
  display: block;
}


// 专业致力于智能整合营销 移动端
.sy_item2_mobile {
  .sy_info2_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12%;

    span:nth-child(1) {
      font-family: MiSans-Bold;
      font-size: 0.26rem;
    }

    span:nth-child(2) {
      width: 50%;
      display: block;
      height: 0.02rem;
      background-color: #999;
      margin: 0.14rem 0;
    }

    span:nth-child(3) {
      width: 14%;
      display: block;
      height: 0.08rem;
      border-radius: 0.04rem;
      background-color: black;
      margin-top: -0.18rem;
      margin-bottom: 0.14rem;
    }

    span:nth-child(4) {
      font-size: 0.14rem;
      letter-spacing: 2px;
      color: #666;
    }

    button {
      width: 40%;
      height: 0.4rem;
      background-color: black;
      border-radius: 0.35rem;
      color: #fff;
      border: none;
      line-height: 0.4rem;
      font-size: 0.18rem;
      font-family: MiSans-Normal;
      margin-top: 0.2rem;

      img {
        width: 16%;
        height: auto;
        display: inline;
        margin: 0;
        margin-bottom: 0.04rem;
        vertical-align: middle;
      }
    }

    // button:hover {
    //   background-color: #666;
    // }
  }
}

// 品牌营销 移动端
.sy_item3_mobile {
  .sy_info3_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12% 0%;

    button {
      width: 30%;
      height: 0.4rem;
      background-color: black;
      border-radius: 0.35rem;
      color: #fff;
      border: none;
      line-height: 0.4rem;
      font-size: 0.18rem;
      font-family: MiSans-Normal;
      margin-top: 0.2rem;

      img {
        width: 16%;
        height: auto;
        display: inline;
        margin: 0;
        margin-bottom: 0.04rem;
        vertical-align: middle;
      }
    }

    // button:hover {
    //   background-color: #666;
    // }

    span:nth-child(1) {
      font-family: MiSans-Bold;
      font-size: 0.26rem;
    }

    span:nth-child(2) {
      font-family: MiSans-Bold;
      font-size: 0.14rem;
      color: #999;
    }
  }
}

//效果营销 移动端 图片有圆角
.sy_item4_mobile {
  padding: 5%;

  .sy_info4_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 0%;

    button {
      width: 35%;
      height: 0.4rem;
      background-color: black;
      border-radius: 0.35rem;
      color: #fff;
      border: none;
      line-height: 0.4rem;
      font-size: 0.18rem;
      font-family: MiSans-Normal;
      margin-top: 0.2rem;

      img {
        width: 16%;
        height: auto;
        display: inline;
        margin: 0;
        margin-bottom: 0.04rem;
        vertical-align: middle;
      }
    }

    // button:hover {
    //   background-color: #666;
    // }

    span:nth-child(1) {
      font-family: MiSans-Bold;
      font-size: 0.26rem;
    }

    span:nth-child(2) {
      font-family: MiSans-Bold;
      font-size: 0.14rem;
      color: #999;
    }
  }

  img {
    border-radius: 0.15rem;
  }
}

// 直播与短视频解决方案 移动端
.sy_item5_mobile {
  .sy_info5_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12% 0%;

    button {
      width: 30%;
      height: 0.4rem;
      background-color: black;
      border-radius: 0.35rem;
      color: #fff;
      border: none;
      line-height: 0.4rem;
      font-size: 0.18rem;
      font-family: MiSans-Normal;
      margin-top: 0.2rem;

      img {
        width: 16%;
        height: auto;
        display: inline;
        margin: 0;
        margin-bottom: 0.04rem;
        vertical-align: middle;
      }
    }

    // button:hover {
    //   background-color: #666;
    // }

    span:nth-child(1) {
      font-family: MiSans-Bold;
      font-size: 0.26rem;
    }

    span:nth-child(2) {
      font-family: MiSans-Bold;
      font-size: 0.14rem;
      color: #999;
    }
  }
}

//营销数据整合与洞察  移动端  图片有圆角
.sy_item6_mobile {
  padding: 5%;

  .sy_info6_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 0%;

    button {
      width: 35%;
      height: 0.4rem;
      background-color: black;
      border-radius: 0.35rem;
      color: #fff;
      border: none;
      line-height: 0.4rem;
      font-size: 0.18rem;
      font-family: MiSans-Normal;
      margin-top: 0.2rem;

      img {
        width: 16%;
        height: auto;
        display: inline;
        margin: 0;
        margin-bottom: 0.04rem;
        vertical-align: middle;
      }
    }

    // button:hover {
    //   background-color: #666;
    // }

    span:nth-child(1) {
      font-family: MiSans-Bold;
      font-size: 0.26rem;
    }

    span:nth-child(2) {
      font-family: MiSans-Bold;
      font-size: 0.14rem;
      color: #999;
    }
  }

  img {
    border-radius: 0.15rem;
  }
}</style>