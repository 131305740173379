<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="hzzy">
      <!-- <div class="hzzy_item">
        <img :src="cooperativeResources.web.img_1" alt="">
        <div class="hzzy_info">
          <span>{{ cooperativeResources.web.item_1 }}</span>
          <span>{{ cooperativeResources.web.item_2 }}</span>
          <span>{{ cooperativeResources.web.item_3 }}</span>
        </div>
      </div> -->
      <div class="hzzy_item2 " :style="{'background-image': 'url(' + cooperativeResources.web.background01  + ')'}">
        <div class="hzzy_info2">
          <div class="hzzy_info2_div">
            <span></span>
            <span>{{ cooperativeResources.web.item_4 }}</span>
          </div>
          <span class="span">
            {{ cooperativeResources.web.item_5 }}
          </span>
          <span class="nextdiv">{{ cooperativeResources.web.item_9 }}</span>
        </div>
        <!-- 深度合作媒体资源 移动端 -->
        <div class="hzzy_info2_mobile">
          <span>{{ cooperativeResources.mobile.item_1 }}</span>
          <span>{{ cooperativeResources.mobile.item_2 }}</span>
          <span></span>
          <span></span>
        </div>
        <div class="hzzy_info2_img clearfix">
          <div class="hzzy_depth_media">
            <div class="hzzy_depth_media_total">
              <img src="http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left01.png" width="260" height="66.08">
            </div>
            <div class="hzzy_depth_media_logo">
              <img :src="cooperativeResources.web.depth_bytedance01" alt="">
              <img :src="cooperativeResources.web.depth_bytedance02" alt="">
              <img :src="cooperativeResources.web.depth_bytedance03" alt="">
              <img :src="cooperativeResources.web.depth_bytedance04" alt="">
              <img :src="cooperativeResources.web.depth_bytedance05" alt="">
              <img :src="cooperativeResources.web.depth_bytedance06" alt="">
              <img :src="cooperativeResources.web.depth_bytedance07" alt="">
              <img :src="cooperativeResources.web.depth_bytedance08" alt="">
              <img :src="cooperativeResources.web.depth_bytedance09" alt="">
              <img :src="cooperativeResources.web.depth_bytedance10" alt="">
              <img :src="cooperativeResources.web.depth_bytedance11" alt="">
              <img :src="cooperativeResources.web.depth_bytedance12" alt="">
            </div>
          </div>

          <div class="hzzy_depth_media">
            <div class="hzzy_depth_media_total">
              <img src="http://offweb-res.perfect-cn.cn/offweb/web/深度媒体合作-left02.png" width="260" height="85.19">
            </div>
            <div class="hzzy_depth_media_logo">
              <img :src="cooperativeResources.web.depth_baidu01" alt="">
              <img :src="cooperativeResources.web.depth_baidu02" alt="">
              <img :src="cooperativeResources.web.depth_baidu03" alt="">
              <img :src="cooperativeResources.web.depth_baidu04" alt="">
              <img :src="cooperativeResources.web.depth_baidu05" alt="">
              <img :src="cooperativeResources.web.depth_baidu06" alt="">
              <img :src="cooperativeResources.web.depth_baidu07" alt="">
              <img :src="cooperativeResources.web.depth_baidu08" alt="">
              <img :src="cooperativeResources.web.depth_baidu09" alt="">
            </div>
          </div>
          <!-- <img :src="cooperativeResources.web.img_2" alt="">
          <img :src="cooperativeResources.web.img_3" alt="">
          <img :src="cooperativeResources.web.img_4" alt="">
          <img :src="cooperativeResources.web.img_5" alt="">
          <img :src="cooperativeResources.web.img_6" alt="">
          <img :src="cooperativeResources.web.img_7" alt="">
          <img :src="cooperativeResources.web.img_8" alt="">
          <img :src="cooperativeResources.web.img_9" alt="">
          <img :src="cooperativeResources.web.img_10" alt="">
          <img :src="cooperativeResources.web.img_11" alt=""> -->
        </div>
      </div>
      <div class="hzzy_item2 next" :style="{'background-image': 'url(' + cooperativeResources.web.background02  + ')'}">
        <div class="hzzy_info2">
          <div class="hzzy_info2_div">
            <span></span>
            <span>{{ cooperativeResources.web.item_6 }}</span>
          </div>
          <span class="span">
            {{ cooperativeResources.web.item_7 }}
          </span>
          <span class="nextdiv">{{ cooperativeResources.web.item_8 }}</span>
        </div>
        <!-- 全媒体合作资源 移动端 -->
        <div class="hzzy_info2_mobile">
          <span>{{ cooperativeResources.mobile.item_3 }}</span>
          <span>{{ cooperativeResources.mobile.item_4 }}</span>
          <span></span>
          <span></span>
        </div>
        <div class="hzzy_all_media clearfix">
          
            <img :src="cooperativeResources.web.all_media_1" alt="">
            <img :src="cooperativeResources.web.all_media_2" alt="">
            <img :src="cooperativeResources.web.all_media_3" alt="">
            <img :src="cooperativeResources.web.all_media_4" alt="">
            <img :src="cooperativeResources.web.all_media_5" alt="">
            <img :src="cooperativeResources.web.all_media_6" alt="">
            <img :src="cooperativeResources.web.all_media_7" alt="">
            <img :src="cooperativeResources.web.all_media_8" alt="">
            <img :src="cooperativeResources.web.all_media_9" alt="">
            <img :src="cooperativeResources.web.all_media_10" alt="">
            <img :src="cooperativeResources.web.all_media_11" alt="">
            <img :src="cooperativeResources.web.all_media_12" alt="">
            <img :src="cooperativeResources.web.all_media_13" alt="">
            <img :src="cooperativeResources.web.all_media_14" alt="">
            <img :src="cooperativeResources.web.all_media_15" alt="">
            <img :src="cooperativeResources.web.all_media_16" alt="">
            <img :src="cooperativeResources.web.all_media_17" alt="">
            <img :src="cooperativeResources.web.all_media_18" alt="">
            <img :src="cooperativeResources.web.all_media_19" alt="">
            <img :src="cooperativeResources.web.all_media_20" alt="">
            <img :src="cooperativeResources.web.all_media_21" alt="">
            <img :src="cooperativeResources.web.all_media_22" alt="">
            <img :src="cooperativeResources.web.all_media_23" alt="">
            <img :src="cooperativeResources.web.all_media_24" alt="">
            <img :src="cooperativeResources.web.all_media_25" alt="">
            <img :src="cooperativeResources.web.all_media_26" alt="">
            <img :src="cooperativeResources.web.all_media_27" alt="">
            <img :src="cooperativeResources.web.all_media_28" alt="">
            <img :src="cooperativeResources.web.all_media_29" alt="">
            <img :src="cooperativeResources.web.all_media_30" alt="">
            <img :src="cooperativeResources.web.all_media_31" alt="">
            <img :src="cooperativeResources.web.all_media_32" alt="">
            <img :src="cooperativeResources.web.all_media_33" alt="">
            <img :src="cooperativeResources.web.all_media_34" alt="">
            <img :src="cooperativeResources.web.all_media_35" alt="">
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import { cooperativeResources } from "../config/environment";
  export default {
    data(){
      return{
        cooperativeResources,
        reasource:[
          {

          }
        ]
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
 // 屏幕大于 750px 时应用该样式 
@media screen and (min-width: 751px) {
  .hzzy{
    margin-top:1.01rem;
  }
  .hzzy_item .hzzy_info{
    width: 49%;
  }
  .hzzy .hzzy_info span:nth-child(2){
    font-size: 0.32rem;
  }
  .hzzy .hzzy_info span:nth-child(3){
    font-size: 0.18rem;
  }
  .hzzy_info2{
    display: block;
    .hzzy_info2_div{
      margin-bottom:-0.53rem;
    }
    .hzzy_info2_div span:nth-child(1){
      width:0.07rem;
      height: 0.38rem;
      background-color: #5383ec;
      display: inline-block;
      border-radius: 0.04rem;
      margin-right: 0.1rem;
    }
    .hzzy_info2_div span:nth-child(2){
      font-family: MiSans-Bold;
      color:rgb(255, 255, 255);
      font-size: 0.47rem;
    }
    .span{
        font-family: MiSans-Bold;
        color: #999;
        font-size: 0.35rem;
        margin-left: 0.2rem;
        margin-top: 0.4rem;
        display: block;
    }
  }

  .hzzy_all_media {
    width: 1200px;
  }
  .hzzy_all_media img {
    width: 84px;
    height: 84px;
    margin: 40px 0 0 40px;
  }

  .hzzy_depth_media {
    margin: 0 0 2% 0;

    .hzzy_depth_media_total {
      float: left;
    }
    .hzzy_depth_media_logo {
      width: 800px;
      float: left;
      margin: 0 0 0 100px;
    }
    .hzzy_depth_media_logo img{
      width: 84px;
      height: 84px;
      margin: 40px 0 0 40px;
    }
  }
  

  // .hzzy_info2_img img{
  //   width: 10%!important;
  //   display: inline;
  //   margin-right: 5%;
  //   margin-left: 5%;
  //   margin-bottom: 3%;
  // }
  .hzzy_info2_mobile{
    display: none;
  }
}

/* // 屏幕小于 750px 时应用该样式 */
@media screen and (max-width: 750px) {
  .hzzy_item img:nth-child(1){
    height:2.5rem;
  }
  .hzzy .hzzy_info {
    span:nth-child(1) {
        font-size: 0.28rem!important;
        margin-top:5%;
      }
      span:nth-child(2) {
        font-size: 0.18rem!important;
      }

      span:nth-child(3) {
        font-size: 0.14rem!important;
        margin-top:5%;
      }
  }
  .hzzy_info2{
    display: none;
  }
  .hzzy_info2_mobile{
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6% 0;

    span:nth-child(1){
      font-family: MiSans-Bold;
      font-size: 0.28rem;
      color: #FFFFFF;
    }
    span:nth-child(3){
      width: 50%;
      display: block;
      height: 0.02rem;
      background-color: #999;
      margin: 0.14rem 0;
    }
    span:nth-child(4){
      width: 14%;
      display: block;
      height: 0.08rem;
      border-radius: 0.04rem;
      background-color: #5383ec;
      margin-top: -0.18rem;
      margin-bottom: 0.14rem;
    }
    span:nth-child(2){
      font-size: 0.14rem;
      letter-spacing: 2px;
      color: #666;
    }
  }

  .hzzy_all_media {
    width: auto;
  }
  .hzzy_all_media img {
    width: 64px;
    height: 64px;
    margin: 30px 0 0 30px;
  }

  .hzzy_depth_media {
    margin: 0 0 2% 0;

    .hzzy_depth_media_total {
      width: 100%;
      text-align: center;
    }
    .hzzy_depth_media_logo {
      width: auto;
    }
    .hzzy_depth_media_logo img{
      width: 64px;
      height: 64px;
      margin: 30px 0 0 30px;
    }
  }
  // .hzzy_info2_img img{
  //   width: 32%!important;
  //   /* float: left; */
  //   margin-right: 9%;
  //   margin-left: 9%;
  //   margin-bottom: 5%;
  // }
}
// .hzzy img{
//   width: 100%;
//   display: block;
// }

.hzzy .hzzy_info{
  span:nth-child(1){
    display: block;
    color: #fff;
    font-family: MiSans-Bold;
    font-size: 0.4rem;
  }
   span:nth-child(2){
    color: #5383ec;
    font-family: MiSans-Bold;
    display: block;
  }
   span:nth-child(3){
    display: block;
    font-family: MiSans-Normal;
    color: #ccc;
    letter-spacing: 2px;
  }
} 
.hzzy_item{
  position: relative;
  .hzzy_info{
    position: absolute;
    top: 14%;
    left: 8%;
  }
}
.hzzy_item2{
  width: 100%;
  height: auto;
  padding: 4%;
  background-size: 100%  100%;
  // background-color: #f6f6f6;

  .hzzy_info2_img{
    width: 100%;
    height: auto;
    margin-top: 4%;
    display: flex;
    flex-wrap: wrap;
  }
}
.clearfix::after {
    content: '';
    clear: both;
    display: block;
}
.next{
  // background-color: #f3f7fe;
}
.nextdiv{
  display: block;
  color: #999;
  font-size: 0.18rem;
  margin-left: 0.2rem;
  line-height: 0.32rem;
  width: 49%;
  margin-top: 0.1rem;
}
  </style>