<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="jrwm">
    <!-- <div style="">....</div> -->
    <!-- 加入我们 -->
    <div class="jrwm_item">
      <img :src="joinUsData.web.img_1" alt="">
      <div class="jrwm_info">
        <span>{{ joinUsData.web.item_1 }}</span>
        <span>{{ joinUsData.web.item_2 }}</span>
        <span>{{ joinUsData.web.item_3 }}</span>
      </div>
    </div>
    <!-- 营销界的新成员 -->
    <div class="jrwm_item2">
      <div class="jrwm_info2">
        <span>{{ joinUsData.web.item_4 }}</span>
        <span>{{ joinUsData.web.item_5 }}</span>
        <span></span>
        <span></span>
        <span>{{ joinUsData.web.item_6 }}</span>
        <span>{{ joinUsData.web.item_7 }}</span>
      </div>
      <img :src="joinUsData.web.img_2" alt="">
    </div>
    <!-- 致力于为公司培养尖端的人才 -->
    <div class="jrwm_item3">
      <div class="jrwm_info3">
        <div class="jrwm_info3_div">
          <span></span>
          <span>{{ joinUsData.web.item_8 }}</span>
        </div>
        <span class="span">
          {{ joinUsData.web.item_9 }}
        </span>
      </div>
      <!-- 致力于为公司培养尖端的人才 移动端 -->
      <div class="jrwm_info3_mobile">
        <span>{{ joinUsData.web.item_10 }}</span>
        <span></span>
        <span></span>
        <span>{{ joinUsData.web.item_11 }}</span>
      </div>
      <div class="jrwm_info3_text">
        <div class="jrwm_info3_textdiv" v-for="item in contact" :key="item.num">
          <img :src="item.imgurl" alt="">
          <div class="jrwm_info3_span">
            <span>{{ item.name }}</span>
            <span>{{ item.info }}</span>
          </div>
        </div>
      </div>
      <div class="jrwm_info3_num">
        <div class="jrwm_info3_numdiv jianli">
          <span>{{ joinUsData.web.item_12 }}</span>
          <span>{{ joinUsData.web.item_13 }}</span>
          <!-- <span></span> -->
        </div>
        <div class="line"></div>
        <div class="jrwm_info3_numdiv">
          <span>{{ joinUsData.web.item_14 }}</span>
          <div>
            <div class="jrwm_info3_con">
              <img :src="joinUsData.web.img_3" alt="">
              <span>{{ joinUsData.web.item_15 }}</span>
            </div>
            <div class="jrwm_info3_con">
              <img :src="joinUsData.web.img_4" alt="">
              <span>{{ joinUsData.web.item_16 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>
  
<script>
import { joinUsData  } from "../config/environment"
export default {
  data(){
      return{
        joinUsData,

        // 关注员工
        contact:[
          {
            num:1,
            imgurl: joinUsData.web.img_5,
            name: joinUsData.web.item_17,
            info: joinUsData.web.item_18
          },
          {
            num:2,
            imgurl: joinUsData.web.img_6,
            name: joinUsData.web.item_19,
            info: joinUsData.web.item_20
          },
          {
            num:3,
            imgurl: joinUsData.web.img_7,
            name: joinUsData.web.item_21,
            info: joinUsData.web.item_22
          },
        ]
      }
    }
}
</script>
  
<style lang="less" scoped>
// 屏幕大于 750px 时应用该样式 
@media screen and (min-width: 751px) {
  .jrwm {
    margin-top: 1.01rem;
  }

  .jrwm .jrwm_info {
    width: 86%;

    span:nth-child(2) {
      font-size: 0.32rem;
    }

    span:nth-child(3) {
      font-size: 0.18rem;
      width: 70%;
    }
  }

  .jrwm_item2 {
    justify-content: space-between;
    // margin-top: 5%;
    background-color: #f6f6f6;

    .jrwm_info2 {

      span:nth-child(3),
      span:nth-child(4) {
        display: none;
      }
    }

    img {
      width: 40% !important;
    }
  }

  .jrwm_item3 {
    background-color: #fff;

    .jrwm_info3 {
      display: block;

      .jrwm_info3_div span:nth-child(1) {
        width: 0.07rem;
        height: 0.38rem;
        background-color: #5383ec;
        display: inline-block;
        border-radius: 0.04rem;
        margin-right: 0.1rem;
      }

      .jrwm_info3_div span:nth-child(2) {
        font-family: MiSans-Bold;
        color: black;
        font-size: 0.47rem;
      }

      .span {
        font-family: MiSans-Normal;
        color: #999;
        font-size: 0.18rem;
        margin-left: 0.2rem;
        margin-top: -0.1rem;
        margin-bottom: 0.4rem;
        display: block;
      }
    }

    .jrwm_info3_mobile {
      display: none;
    }

    .jrwm_info3_text {
      // display: flex;
      justify-content: space-between;

      // padding:6%;
      // margin-top: 5%;
      .jrwm_info3_textdiv {
        // display: flex;
        // flex-direction: column;
        width: 30%;
        /* height: 400px; */
        // border-radius: 15px;
        background-color: #f6f6f6;
      }
    }

    .jrwm_info3_num {
      border-radius: 0.2rem;
      padding: 3% 5%;
      justify-content: space-between;
      background-color: #f6f6f6;

      .line {
        display: block;
      }

      .jianli {
        width: 56%;
      }
    }
  }
}

/* // 屏幕小于 750px 时应用该样式 */
@media screen and (max-width: 750px) {
  .jrwm_item img:nth-child(1) {
    height: 2.5rem;
  }

  .jrwm .jrwm_info {
    span:nth-child(1) {
      font-size: 0.28rem !important;
      margin-top: 5%;
    }

    span:nth-child(2) {
      font-size: 0.18rem !important;
    }

    span:nth-child(3) {
      font-size: 0.14rem !important;
      margin-top: 5%;
    }
  }

  .jrwm_item2 {
    flex-direction: column;
    background-color: #fff;

    .jrwm_info2 {
      display: flex;
      flex-direction: column;
      align-items: center;

      span:nth-child(1),
      span:nth-child(2) {
        font-size: 0.28rem!important;
      }

      span:nth-child(3) {
        width: 40%;
        display: block;
        height: 0.02rem;
        background-color: #999;
        margin: 0.14rem 0;
      }

      span:nth-child(4) {
        width: 14%;
        display: block;
        height: 0.08rem;
        border-radius: 0.04rem;
        background-color: #5383ec;
        margin-top: -0.18rem;
        margin-bottom: 0.14rem;
      }

      span:nth-child(5),
      span:nth-child(6) {
        text-align: center;
        font-size: 0.18rem!important;
        line-height: 0.25rem!important;
      }
    }

    img {
      // width: 40%;
      margin-top: 5%;
      border-radius: 0.15rem;
    }
  }

  .jrwm_item3 {
    background-color: #f6f6f6;

    .jrwm_info3 {
      display: none;
    }

    .jrwm_info3_mobile {
      display: block;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 6%;

      span:nth-child(1) {
        font-family: MiSans-Bold;
        font-size: 0.28rem;
        width: 70%;
        text-align: center;
        margin-bottom: 3%;
      }

      span:nth-child(2) {
        width: 40%;
        display: block;
        height: 0.02rem;
        background-color: #999;
        margin: 0.14rem 0;
      }

      span:nth-child(3) {
        width: 14%;
        display: block;
        height: 0.08rem;
        border-radius: 0.04rem;
        background-color: #5383ec;
        margin-top: -0.18rem;
        margin-bottom: 0.14rem;
      }

      span:nth-child(4) {
        font-size: 0.18rem;
        letter-spacing: 2px;
        color: #666;
        margin-top: 3%;
      }
    }

    .jrwm_info3_text {
      flex-direction: column;
      padding: 6%;
      .jrwm_info3_textdiv {
        background-color: #fff;
        margin-bottom: 5%;

        .jrwm_info3_span{
          span:nth-child(1){
            font-size: 0.24rem!important;
          }
          span:nth-child(2){
            font-size: 0.16rem!important;
          }
        } 
      }
    }

    .jrwm_info3_num {
      padding: 11% 15%;
      flex-direction: column;
      background-color: #fff;
      margin: -7%;

      .line {
        display: none;
      }

      .jianli {
        margin-bottom: 5%;
        span:nth-child(1){
          font-size: 0.28rem!important;
        }
        span:nth-child(2){
          font-size: 0.16rem!important;
        }
      }

      .jrwm_info3_numdiv{
        span:nth-child(1){
          font-size: 0.28rem!important;
        }
        div:nth-child(2) {
          display: flex;
          justify-content: space-between;
          // align-items: center;
        }
        .jrwm_info3_con {
          align-items: center;
          img{
            width:0.2rem!important;
            height:0.2rem!important;
            margin-right: 0.1rem;
            // vertical-align: middle;
          }
          span{
            font-size: 0.14rem!important;;
          }
        }
      } 
    }
  }
}

.jrwm img {
  width: 100%;
  display: block;
}

.jrwm .jrwm_info {
  span:nth-child(1) {
    display: block;
    color: #fff;
    font-family: MiSans-Bold;
    font-size: 0.4rem;
  }

  span:nth-child(2) {
    color: #5383ec;
    font-family: MiSans-Bold;
    display: block;
  }

  span:nth-child(3) {
    display: block;
    font-family: MiSans-Normal;
    color: #ccc;
    letter-spacing: 2px;
  }
}

.jrwm_item {
  position: relative;
  .jrwm_info {
    position: absolute;
    top: 14%;
    left: 8%;
  }
}

.jrwm_item2 {
  width: 100%;
  padding: 6%;
  display: flex;

  .jrwm_info2 {
    margin-top: 2%;

    span {
      display: block;
    }

    span:nth-child(1),
    span:nth-child(2) {
      font-family: MiSans-Bold;
      color: black;
      font-size: 0.5rem;
    }

    span:nth-child(5),
    span:nth-child(6) {
      font-family: MiSans-Normal;
      font-size: 0.26rem;
      color: #666;
      line-height: 0.4rem;
    }

    span:nth-child(3) {
      margin-top: 0.2rem;
    }
  }

  .jrwm_item2 {
    img {
      border-radius: 0.15rem;
    }
  }
}

  .jrwm_item3 {
    width: 100%;
    height: auto;
    padding: 6%;
  }

  .jrwm_item3 .jrwm_info3_text {
    display: flex;
  }

  .jrwm_item3 .jrwm_info3_textdiv {
    display: flex;
    flex-direction: column;
    border-radius: 0.15rem;
  }

  .jrwm_item3 .jrwm_info3_textdiv img {
    width: 100%;
    height: 40%;
    object-fit: cover;
    // height: auto;
    border-top-left-radius: 0.15rem;
    border-top-right-radius: 0.15rem;
  }

  .jrwm_item3 .jrwm_info3_textdiv .jrwm_info3_span {
    padding: 5% 8% 10% 8%;
    height: 70%;
  }

  .jrwm_item3 .jrwm_info3_textdiv .jrwm_info3_span span {
    display: block;
  }

  .jrwm_info3_span span:nth-child(1) {
    font-family: MiSans-Bold;
    color: black;
    font-size: 0.3rem;
    margin-bottom: 0.05rem;
  }

  .jrwm_info3_span span:nth-child(2) {
    color: #666;
    font-size: 0.18rem;
    font-family: MiSans-Normal;
    line-height: 0.3rem;
  }

  .jrwm_info3_num {
    padding: 3% 5%;
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }

  .jrwm_info3_numdiv span:nth-child(1) {
    font-family: MiSans-Bold;
    color: black;
    font-size: 0.34rem;
    margin-bottom: 0.25rem;
    display: block;
  }

  .jrwm_info3_numdiv span:nth-child(2) {
    display: block;
    color: #999;
    font-size: 0.21rem;
    font-family: MiSans-Normal;
    line-height: 0.34rem;
  }

  .jrwm_info3_con {
    display: flex;
  }

  .jrwm_info3_con img {
    width: 0.3rem;
    height: 0.3rem;
    margin-right: 0.2rem;
  }

  .line {
    width: 1px;
    height: 1.5rem;
    background-color: #ddd;
  }
  </style>