<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="xgyx">
    <!-- 品牌营销 -->
    <div class="xgyx_item">
      <img :src="effectivenessMarketing.web.img_1" alt="">
      <div class="xgyx_info">
        <span>{{ effectivenessMarketing.web.item_1 }}</span>
        <span>{{ effectivenessMarketing.web.item_2 }}</span>
      </div>
    </div>
    <!-- 创意为流量提效 -->
    <div class="xgyx_item2">
      <div class="xgyx_info2">
        <span>{{ effectivenessMarketing.web.item_3 }}</span>
        <span>{{ effectivenessMarketing.web.item_4 }}</span>
        <div class="xgyx_info2_div">
          <span>{{ effectivenessMarketing.web.item_5 }}</span>
          <span>{{ effectivenessMarketing.web.item_6 }}</span>
        </div>
      </div>
      <img :src="effectivenessMarketing.web.img_2" alt="">
    </div>
    <!-- 创意为流量提效 移动端-->
    <div class="xgyx_item2_mobile">
      <div class="xgyx_info2_mobile">
        <span>{{ effectivenessMarketing.mobile.item_1 }}</span>
        <span>{{ effectivenessMarketing.mobile.item_2 }}</span>
        <span></span>
        <span></span>
      </div>
      <img :src="effectivenessMarketing.web.img_3" alt="">
      <div class="xgyx_info2_div_mobile">
        <span>{{ effectivenessMarketing.mobile.item_3 }}</span>
        <span>自{{ effectivenessMarketing.mobile.item_4 }}</span>
      </div>
    </div>
    <!-- 服务 -->
    <div class="xgyx_item3" :style="{ 'background-image': 'url(' + effectivenessMarketing.web.img_0 + ')' }">
      <div class="xgyx_info3">
        <span></span>
        <span>{{ effectivenessMarketing.web.item_7 }}</span>
        <span>{{ effectivenessMarketing.web.item_8 }}</span>
      </div>
      <!-- 服务 移动端  -->
      <div class="xgyx_info3_mobile">
        <span>{{ effectivenessMarketing.mobile.item_5 }}</span>
        <span>{{ effectivenessMarketing.mobile.item_6 }}</span>
        <span></span>
        <span></span>
      </div>
      <div class="xgyx_info3_text">
        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <div class="xgyx_info3_textdiv" v-for="it in servers" :key="it.num">
          <img :src="it.imgurl" alt="">
          <div class="xgyx_info3_span">
            <span>{{ it.name }}</span>
            <span>{{ it.info }}</span>
            <span>{{ it.info_item }}</span>
            <span>{{ it.info2 }}</span>
            <span>{{ it.info2_item }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 案例 -->
    <div class="xgyx_item4">
      <div class="xgyx_in">
        <span></span>
        <span>{{ effectivenessMarketing.web.item_9 }}</span>
        <span>{{ effectivenessMarketing.web.item_10 }}</span>
      </div>
      <!-- 案例   移动端-->
      <div class="xgyx_in_mobile">
        <span>{{ effectivenessMarketing.mobile.item_7 }}</span>
        <span>{{ effectivenessMarketing.mobile.item_8 }}</span>
        <span></span>
        <span></span>
      </div>
      <!-- 案例   移动端 -->
      <div class="xgyx_item4_info_mobile" v-for="item in cases" :key="item.num">
        <div class="xgyx_info4">
          <span>{{ item.name }}</span>
          <span>{{ item.itter }}</span>
          <span>{{ item.info }}</span>
          <span>{{ item.puName }}<span class="blue2"> {{ item.pu }}</span> </span>
          <span>{{ item.huName }}<span class="blue2"> {{ item.hu }}</span></span>
          <!-- <img src="../assets/image/02web端切图（1X）/web_icon11.png" alt=""> -->
        </div>
        <img :src="item.imgurl" alt="">
      </div>
      <!-- 案例   pc端   first -->
      <div class="xgyx_item4_info">
        <div class="xgyx_info4" >
          <span>{{ effectivenessMarketing.web.item_11 }}</span>
          <span>{{ effectivenessMarketing.web.item_12 }}</span>
          <span>{{ effectivenessMarketing.web.item_13 }}</span>
          <span>{{ effectivenessMarketing.web.item_14 }}<span class="blue2"> {{ effectivenessMarketing.web.item_15 }}</span> {{ effectivenessMarketing.web.item_16 }}<span class="blue2"> {{ effectivenessMarketing.web.item_17 }}</span></span>
          <!-- <img :src="effectivenessMarketing.web.img_4" alt=""> -->
        </div>
        <img :src="effectivenessMarketing.web.img_5" alt="">
      </div>

      <el-carousel @change="carouselChange" arrow="always" ref="carousel" :initial-index="1" type="card" :autoplay="false" :loop="false" indicator-position="none">
          <el-carousel-item id="0">
            <div class="xgyx_item4_textdiv">
              <div class="xgyx_item4_textspan" >
                <span>{{ effectivenessMarketing.web.item_18 }}</span>
                <span>{{ effectivenessMarketing.web.item_19 }}</span>
                <span>{{ effectivenessMarketing.web.item_20 }}</span>
              </div>
              <div class="textinfo">
                  <span class="imgtext">{{ effectivenessMarketing.web.item_21 }}<span class="blue2"> {{ effectivenessMarketing.web.item_22 }}</span> </span>
                  <span class="imgtext">{{ effectivenessMarketing.web.item_23 }}<span class="blue2"> {{ effectivenessMarketing.web.item_24 }}</span></span>
                </div>
              <div class="xgyx_item4_textimg" :style="{ 'background-image': 'url(' + effectivenessMarketing.mobile.img_1 + ')' }">
                
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item id="1">
            <div class="xgyx_item4_textdiv">
              <div class="xgyx_item4_textspan" >
                <span>{{ effectivenessMarketing.web.item_25 }}</span>
                <span>{{ effectivenessMarketing.web.item_26 }}</span>
                <span>{{ effectivenessMarketing.web.item_27 }}</span>
              </div>
              <div class="textinfo">
                  <span class="imgtext">{{ effectivenessMarketing.web.item_28 }}<span class="blue2"> {{ effectivenessMarketing.web.item_29 }}</span> </span>
                  <span class="imgtext">{{ effectivenessMarketing.web.item_30 }}<span class="blue2"> {{ effectivenessMarketing.web.item_31 }}</span></span>
                </div>
              <div class="xgyx_item4_textimg" :style="{ 'background-image': 'url(' + effectivenessMarketing.mobile.img_2 + ')' }">
                
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item id="2">
            <div class="xgyx_item4_textdiv">
              <div class="xgyx_item4_textspan" >
                <span>{{ effectivenessMarketing.web.item_32 }}</span>
                <span>{{ effectivenessMarketing.web.item_33 }}</span>
                <span>{{ effectivenessMarketing.web.item_34 }}</span>
              </div>
              <div class="textinfo">
                  <span class="imgtext">{{ effectivenessMarketing.web.item_35 }}<span class="blue2"> {{ effectivenessMarketing.web.item_36 }}</span> </span>
                  <span class="imgtext">{{ effectivenessMarketing.web.item_37 }}<span class="blue2"> {{ effectivenessMarketing.web.item_38 }}</span></span>
                </div>
              <div class="xgyx_item4_textimg" :style="{ 'background-image': 'url(' + effectivenessMarketing.mobile.img_3 + ')' }">
                
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item id="3">
            <div class="xgyx_item4_textdiv">
              <div class="xgyx_item4_textspan" >
                <span>{{ effectivenessMarketing.web.item_39 }}</span>
                <span>{{ effectivenessMarketing.web.item_40 }}</span>
                <span>{{ effectivenessMarketing.web.item_41 }}</span>
              </div>
              <div class="textinfo">
                  <span class="imgtext">{{ effectivenessMarketing.web.item_42 }}<span class="blue2"> {{ effectivenessMarketing.web.item_43 }}</span> </span>
                  <span class="imgtext">{{ effectivenessMarketing.web.item_44 }}<span class="blue2"> {{ effectivenessMarketing.web.item_45 }}</span></span>
                </div>
              <div class="xgyx_item4_textimg" :style="{ 'background-image': 'url(' + effectivenessMarketing.mobile.img_4 + ')' }">
                
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item id="4">
            <div class="xgyx_item4_textdiv">
              <div class="xgyx_item4_textspan" >
                <span>{{ effectivenessMarketing.web.item_46 }}</span>
                <span>{{ effectivenessMarketing.web.item_47 }}</span>
                <span>{{ effectivenessMarketing.web.item_48 }}</span>
              </div>
              <div class="textinfo">
                  <span class="imgtext">{{ effectivenessMarketing.web.item_49 }}<span class="blue2"> {{ effectivenessMarketing.web.item_50 }}</span> </span>
                  <span class="imgtext">{{ effectivenessMarketing.web.item_51 }}<span class="blue2"> {{ effectivenessMarketing.web.item_52 }}</span></span>
                </div>
              <div class="xgyx_item4_textimg" :style="{ 'background-image': 'url(' + effectivenessMarketing.mobile.img_5 + ')' }">
                
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>


      <!-- <div class="xgyx_item4_text">
        <img :src="effectivenessMarketing.web.img_6" alt="">
            <div class="xgyx_item4_textdiv">
              <div class="xgyx_item4_textspan" >
                <span>{{ effectivenessMarketing.web.item_18 }}</span>
                <span>{{ effectivenessMarketing.web.item_19 }}</span>
                <span>{{ effectivenessMarketing.web.item_20 }}</span>
              </div>
              <div class="textinfo">
                  <span class="imgtext">{{ effectivenessMarketing.web.item_21 }}<span class="blue2"> {{ effectivenessMarketing.web.item_22 }}</span> </span>
                  <span class="imgtext">{{ effectivenessMarketing.web.item_23 }}<span class="blue2"> {{ effectivenessMarketing.web.item_24 }}</span></span>
                </div>
              <div class="xgyx_item4_textimg" :style="{ 'background-image': 'url(' + effectivenessMarketing.mobile.img_1 + ')' }">
                
              </div>
            </div>
            <div class="xgyx_item4_textdiv">
              <div class="xgyx_item4_textspan" >
                <span>{{ effectivenessMarketing.web.item_25 }}</span>
                <span>{{ effectivenessMarketing.web.item_26 }}</span>
                <span>{{ effectivenessMarketing.web.item_27 }}</span>
              </div>
              <div class="textinfo">
                  <span class="imgtext">{{ effectivenessMarketing.web.item_28 }}<span class="blue2"> {{ effectivenessMarketing.web.item_29 }}</span> </span>
                  <span class="imgtext">{{ effectivenessMarketing.web.item_30 }}<span class="blue2"> {{ effectivenessMarketing.web.item_31 }}</span></span>
                </div>
              <div class="xgyx_item4_textimg" :style="{ 'background-image': 'url(' + effectivenessMarketing.mobile.img_2 + ')' }">
                
              </div>
            </div>
            <div class="xgyx_item4_textdiv">
              <div class="xgyx_item4_textspan" >
                <span>{{ effectivenessMarketing.web.item_32 }}</span>
                <span>{{ effectivenessMarketing.web.item_33 }}</span>
                <span>{{ effectivenessMarketing.web.item_34 }}</span>
              </div>
              <div class="textinfo">
                  <span class="imgtext">{{ effectivenessMarketing.web.item_35 }}<span class="blue2"> {{ effectivenessMarketing.web.item_36 }}</span> </span>
                  <span class="imgtext">{{ effectivenessMarketing.web.item_37 }}<span class="blue2"> {{ effectivenessMarketing.web.item_38 }}</span></span>
                </div>
              <div class="xgyx_item4_textimg" :style="{ 'background-image': 'url(' + effectivenessMarketing.mobile.img_3 + ')' }">
                
              </div>
            </div>
        
        <img :src="effectivenessMarketing.web.img_7" alt="">
      </div> -->
    </div>
  </div>
</template>

<script>
import { coreBusiness } from '../config/environment';
export default {
  data() {
    return {
      effectivenessMarketing: coreBusiness.effectivenessMarketing,
      // 服务
      servers: [
        {
          num: 1,
          imgurl:  coreBusiness.effectivenessMarketing.web.img_8,
          name: coreBusiness.effectivenessMarketing.mobile.item_9,
          info: coreBusiness.effectivenessMarketing.mobile.item_10,
          info_item: coreBusiness.effectivenessMarketing.mobile.item_11,
          info2: coreBusiness.effectivenessMarketing.mobile.item_12,
          info2_item: coreBusiness.effectivenessMarketing.mobile.item_13,
        },
        {
          num: 2,
          imgurl: coreBusiness.effectivenessMarketing.web.img_9,
          name: coreBusiness.effectivenessMarketing.mobile.item_14,
          info: coreBusiness.effectivenessMarketing.mobile.item_15,
          info_item: coreBusiness.effectivenessMarketing.mobile.item_16,
          info2: coreBusiness.effectivenessMarketing.mobile.item_17,
          info2_item: coreBusiness.effectivenessMarketing.mobile.item_18,
        },
        {
          num: 3,
          imgurl: coreBusiness.effectivenessMarketing.web.img_10,
          name: coreBusiness.effectivenessMarketing.mobile.item_19,
          info: coreBusiness.effectivenessMarketing.mobile.item_20,
          info_item: coreBusiness.effectivenessMarketing.mobile.item_21,
          info2: coreBusiness.effectivenessMarketing.mobile.item_22,
          info2_item: coreBusiness.effectivenessMarketing.mobile.item_23,
        }
      ],
      // 案例 移动端
      cases: [
        {
          num: '1',
          name: coreBusiness.effectivenessMarketing.mobile.item_24,
          itter: coreBusiness.effectivenessMarketing.mobile.item_25,
          info: coreBusiness.effectivenessMarketing.mobile.item_26,
          puName: coreBusiness.effectivenessMarketing.mobile.item_27,
          pu: coreBusiness.effectivenessMarketing.mobile.item_28,
          huName: coreBusiness.effectivenessMarketing.mobile.item_29,
          hu: coreBusiness.effectivenessMarketing.mobile.item_30,
          imgurl: coreBusiness.effectivenessMarketing.web.img_11,
        },
        {
          num: '2',
          name: coreBusiness.effectivenessMarketing.mobile.item_31,
          itter: coreBusiness.effectivenessMarketing.mobile.item_32,
          info: coreBusiness.effectivenessMarketing.mobile.item_33,
          puName: coreBusiness.effectivenessMarketing.mobile.item_34,
          pu: coreBusiness.effectivenessMarketing.mobile.item_35,
          huName: coreBusiness.effectivenessMarketing.mobile.item_36,
          hu: coreBusiness.effectivenessMarketing.mobile.item_37,
          imgurl: coreBusiness.effectivenessMarketing.web.img_12,
        },
        {
          num: '3',
          name: coreBusiness.effectivenessMarketing.mobile.item_38,
          itter: coreBusiness.effectivenessMarketing.mobile.item_39,
          info: coreBusiness.effectivenessMarketing.mobile.item_40,
          puName: coreBusiness.effectivenessMarketing.mobile.item_41,
          pu: coreBusiness.effectivenessMarketing.mobile.item_42,
          huName: coreBusiness.effectivenessMarketing.mobile.item_43,
          hu: coreBusiness.effectivenessMarketing.mobile.item_44,
          imgurl: coreBusiness.effectivenessMarketing.web.img_13,
        },
        {
          num: '4',
          name: coreBusiness.effectivenessMarketing.mobile.item_45,
          itter: coreBusiness.effectivenessMarketing.mobile.item_46,
          info: coreBusiness.effectivenessMarketing.mobile.item_47,
          puName: coreBusiness.effectivenessMarketing.mobile.item_48,
          pu: coreBusiness.effectivenessMarketing.mobile.item_49,
          huName: coreBusiness.effectivenessMarketing.mobile.item_50,
          hu: coreBusiness.effectivenessMarketing.mobile.item_51,
          imgurl: coreBusiness.effectivenessMarketing.web.img_14,
        },
        {
          num: '5',
          name: coreBusiness.effectivenessMarketing.mobile.item_52,
          itter: coreBusiness.effectivenessMarketing.mobile.item_53,
          info: coreBusiness.effectivenessMarketing.mobile.item_54,
          puName: coreBusiness.effectivenessMarketing.mobile.item_55,
          pu: coreBusiness.effectivenessMarketing.mobile.item_56,
          huName: coreBusiness.effectivenessMarketing.mobile.item_57,
          hu: coreBusiness.effectivenessMarketing.mobile.item_58,
          imgurl: coreBusiness.effectivenessMarketing.web.img_15,
        },
        {
          num: '6',
          name: coreBusiness.effectivenessMarketing.mobile.item_59,
          itter: coreBusiness.effectivenessMarketing.mobile.item_60,
          info: coreBusiness.effectivenessMarketing.mobile.item_61,
          puName: coreBusiness.effectivenessMarketing.mobile.item_62,
          pu: coreBusiness.effectivenessMarketing.mobile.item_63,
          huName: coreBusiness.effectivenessMarketing.mobile.item_64,
          hu: coreBusiness.effectivenessMarketing.mobile.item_65,
          imgurl: coreBusiness.effectivenessMarketing.web.img_16,
        }
      ]
    }
  },
  mounted(){
		this.setPY(1);
	},
  methods:{
		carouselChange:function(now){
      console.log(now,"====new");
			this.setPY(now);
		},
		setPY(now){
      if(now == 0){
        // eslint-disable-next-line no-undef
        $("#1").css("left","33%");
        // eslint-disable-next-line no-undef
        $("#0").css("left","0%");
        // eslint-disable-next-line no-undef
        $("#1").next().css("left","66%");
      }
      else{
        // eslint-disable-next-line no-undef
        $("#"+ now).css("left","33%");
        // eslint-disable-next-line no-undef
        $("#"+ now).prev().css("left","0%");
        // eslint-disable-next-line no-undef
        $("#" +now).next().css("left","66%");
      }
		},
	}
}
</script>

<style lang="less" scoped>
// 屏幕大于 750px 时应用该样式 
@media screen and (min-width: 751px) {
  .xgyx_item {
    display: block;
  }

  .xgyx_item2 {
    display: block;
  }

  .xgyx_item2_mobile {
    display: none !important;
  }
.xgyx_item3{
  // background-image: url(../assets/image/02web端切图（1X）/web_banner04.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
  .xgyx_item4 {
    padding: 6%;
  }

  .xgyx_info3,
  .xgyx_in {
    display: block;
  }

  .xgyx_info3_mobile,
  .xgyx_in_mobile,
  .xgyx_item4_info_mobile {
    display: none;
  }

  .xgyx_info3_text {
    display: flex;
    justify-content: space-between;

      .xgyx_info3_textdiv {
      // display: flex;
      // flex-direction: column;
      width: 32%;
      // border-radius: 15px;
      background-color: #f6f6f6;

      .xgyx_info3_span span:nth-child(1) {
        font-size: 0.26rem;
        font-family: MiSans-Normal;
        padding-top: 6%;
        padding-bottom: 9%;
      }

      .xgyx_info3_span span:nth-child(2) {
        font-family: MiSans-Bold;
        font-size: 0.18rem;
        color: #666;
      }
    }
  }

  .xgyx_item4_info,
  .el-carousel--horizontal {
    display: block;
  }
  // .all{
  //   width:100%;
  //   overflow-x: scroll;
  // }
  // .xgyx_item4_textdiv_all{
  //     width:auto;
  //     // overflow-x: scroll;
  //     display: flex;
  //     flex-wrap: nowrap;
      
  //     .xgyx_item4_textdiv{
  //       // margin-right:2%;
  //       // float: left;
  //     }
  // }
}

/* // 屏幕小于 750px 时应用该样式 */
@media screen and (max-width: 750px) {
  .xgyx_item {
    display: none;
  }

  .xgyx_item2 {
    display: none !important;
  }

  .xgyx_item2_mobile {
    display: block !important;
  }

  .xgyx_item3 {
    padding-bottom: 0 !important;
    background-color: #f6f6f6!important;
  }

  .xgyx_info3,
  .xgyx_in,
  .xgyx_item4_info,
  .el-carousel--horizontal {
    display: none !important;
  }

  .xgyx_info3_mobile,
  .xgyx_in_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6% 0;

    span:nth-child(1) {
      font-family: MiSans-Bold;
      font-size: 0.28rem;
    }

    span:nth-child(3) {
      width: 30%;
      display: block;
      height: 0.02rem;
      background-color: #999;
      margin: 0.14rem 0;
    }

    span:nth-child(4) {
      width: 14%;
      display: block;
      height: 0.08rem;
      border-radius: 0.04rem;
      background-color: #5383ec;
      margin-top: -0.18rem;
      margin-bottom: 0.14rem;
    }

    span:nth-child(2) {
      font-size: 0.18rem;
      letter-spacing: 2px;
      color: #666;
    }
  }

  .xgyx_info3_text {
    padding: 5%;

    .xgyx_info3_textdiv {
      width: 100%;
      margin-bottom: 5%;
      background-color: #fff;

      .xgyx_info3_span span:nth-child(1) {
        font-size: 0.28rem;
        font-family: MiSans-Bold;
        // padding-top: 6%;
        padding-bottom: 6%;
      }

      .xgyx_info3_span span:nth-child(2) {
        font-family: MiSans-Normal;
        font-size: 0.18rem;
        color: #666;
      }
    }
  }

  .xgyx_item4 {
    padding: 10%;
  }

  .xgyx_item4_info_mobile {
    display: block;
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6;
    border-radius: 0.15rem;
    margin-bottom: 5%;

    .xgyx_info4 {
      display: flex;
      flex-direction: column;
      padding: 7%;

      span:nth-child(1) {
        font-family: MiSans-Bold;
        font-size: 0.28rem;
      }

      span:nth-child(2) {
        color: #9c9c9c;
        font-family: MiSans-Normal;
        font-size: 0.2rem;
        padding-bottom: 4%;
        border-bottom: 1px solid #ccc;
      }

      span:nth-child(3) {
        font-size: 0.16rem;
        color: #999;
        margin-bottom: 4%;
        padding-top: 4%;
        line-height: 0.3rem;
      }

      span:nth-child(4),
      span:nth-child(5) {
        font-size: 0.24rem;
      }

      .blue2 {
        font-size: 0.3rem !important;
      }
    }

    img {
      height: 40%;
      width: 100%;
      border-bottom-left-radius: 0.15rem;
      border-bottom-right-radius: 0.15rem;
    }
  }
}

/* 品牌营销 */
.xgyx img {
  width: 100%;
  display: block;
}

.xgyx .xgyx_info span:nth-child(1) {
  display: block;
  color: #fff;
  font-family: MiSans-Bold;
  font-size: 0.47rem;
}

.xgyx .xgyx_info span:nth-child(2) {
  color: #5383ec;
  font-family: MiSans-Bold;
  display: block;
  font-size: 0.32rem;
}

.xgyx_item {
  position: relative;
}

.xgyx_item .xgyx_info {
  position: absolute;
  top: 18%;
  left: 8%;
  width: 49%;
}

/* 创意为流量提效 */
.xgyx_item2 {
  width: 100%;
  padding: 6%;
  display: flex;
  justify-content: space-between;

  .xgyx_info2 span {
    display: block;
  }

  .xgyx_info2 span:nth-child(1) {
    font-family: MiSans-Bold;
    color: black;
    font-size: 0.5rem;
  }

  img {
    width: 42%;
    border-radius: 0.15rem;
  }

  .xgyx_info2 {
    width: 50%;
  }

  .xgyx_info2 span:nth-child(2) {
    font-family: MiSans-Normal;
    font-size: 0.28rem;
    color: #666;
    /* line-height: 40px; */
    width: 57%;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .xgyx_info2 .xgyx_info2_div {
    display: flex;
    justify-content: space-between;
  }

  .xgyx_info2 .xgyx_info2_div span {
    font-size: 0.17rem;
    color: black !important;
    font-family: 'MiSans-Normal';
    background-color: #f6f6f6;
    border-radius: 0.15rem;
    // text-align: center;
    width: 48%;
    padding: 5%;
    letter-spacing: 2px;
    line-height: 0.3rem;
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* 内容+运营+数据 移动端 */
.xgyx_item2_mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10%;

  .xgyx_info2_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6% 0;

    span:nth-child(1) {
      font-family: MiSans-Bold;
      font-size: 0.28rem;
    }

    span:nth-child(3) {
      width: 50%;
      display: block;
      height: 0.02rem;
      background-color: #999;
      margin: 0.14rem 0;
    }

    span:nth-child(4) {
      width: 14%;
      display: block;
      height: 0.08rem;
      border-radius: 0.04rem;
      background-color: #5383ec;
      margin-top: -0.18rem;
      margin-bottom: 0.14rem;
    }

    span:nth-child(2) {
      font-size: 0.15rem;
      letter-spacing: 2px;
      color: #666;
    }
  }

  img {
    border-radius: 0.15rem;
    margin-bottom: 5%;
  }

  .xgyx_info2_div_mobile {
    display: flex;
    flex-direction: column;
    font-size: 0.18rem;
    font-family: MiSans-Normal;
    line-height: 0.4rem;
    letter-spacing: 2px;
  }
}

/* 服务 */
.xgyx_item3 {
  width: 100%;
  padding: 8% 6%;
  // background-image: url(../assets/image/02web端切图（1X）/web_banner04.png);
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
}

.xgyx_item3 .xgyx_info3 span:nth-child(1),
.xgyx_item4 .xgyx_in span:nth-child(1) {
  width: 0.07rem;
  height: 0.4rem;
  background-color: #5383ec;
  display: inline-block;
  border-radius: 0.04rem;
  margin-right: 0.1rem;
}

.xgyx_item3 .xgyx_info3 span:nth-child(2),
.xgyx_item4 .xgyx_in span:nth-child(2) {
  font-family: MiSans-Bold;
  color: black;
  font-size: 0.45rem;
}

.xgyx_item3 .xgyx_info3 span:nth-child(3),
.xgyx_item4 .xgyx_in span:nth-child(3) {
  font-family: MiSans-Bold;
  color: #999;
  font-size: 0.3rem;
  margin-left: 0.2rem;
}

.xgyx_item3 .xgyx_info3_text {
  // display: flex;
  // justify-content: space-between;
  margin-top: 4%;
}

.xgyx_item3 .xgyx_info3_textdiv {
  display: flex;
  flex-direction: column;
  // width: 32%;
  border-radius: 0.15rem;
  // background-color: #f6f6f6;
}

.xgyx_item3 .xgyx_info3_textdiv img {
  height: 40% !important;
  border-top-left-radius: 0.15rem;
  border-top-right-radius: 0.15rem;
  object-fit: cover;
}

.xgyx_item3 .xgyx_info3_textdiv .xgyx_info3_span {
  padding: 5% 8% 10% 8%;
  height: 60%;
}

.xgyx_item3 .xgyx_info3_textdiv .xgyx_info3_span span {
  display: block;
  // font-family: MiSans-Normal;
}

// .xgyx_item3 .xgyx_info3_textdiv .xgyx_info3_span span:nth-child(1){
//     font-size: 28px;
//     padding-top: 4%;
//     font-family: MiSans-Bold;
//     padding-bottom: 4%;
// }
.xgyx_item3 .xgyx_info3_textdiv .xgyx_info3_span span:nth-child(2),
.xgyx_item3 .xgyx_info3_textdiv .xgyx_info3_span span:nth-child(4) {
  font-size: 0.18rem;
  color: black;
  line-height: 0.3rem;
}

.xgyx_item3 .xgyx_info3_textdiv .xgyx_info3_span span:nth-child(3),
.xgyx_item3 .xgyx_info3_textdiv .xgyx_info3_span span:nth-child(5) {
  font-size: 0.18rem;
  color: #999;
  line-height: 0.3rem;
}

/* 案例 */
.xgyx_item4 {
  width: 100%;
  // padding: 6%;
  padding-bottom: 10%;

  .xgyx_in {
    margin-bottom: 5%;
  }

  .xgyx_item4_info {
    display: flex;
    justify-content: space-between;
    background-color: #f6f6f6;
    border-radius: 0.15rem;

    img {
      width: 42%;
      border-top-right-radius: 0.15rem;
      border-bottom-right-radius: 0.15rem;
    }

    .xgyx_info4 {
      width: 52%;
      padding: 3% 5%;
    }
  }

  .xgyx_info4 img {
    width: 0.5rem;
    height: 0.5rem;
    margin-top: 5%;
  }

  .xgyx_item4_info .xgyx_info4 span {
    display: block;
  }

  .xgyx_item4_info .xgyx_info4 span:nth-child(1) {
    font-family: MiSans-Bold;
    font-size: 0.3rem;
  }

  .xgyx_item4_info .xgyx_info4 span:nth-child(2) {
    color: #9c9c9c;
    font-family: MiSans-Normal;
    font-size: 0.26rem;
    margin-bottom: 5%;
  }

  .xgyx_item4_info .xgyx_info4 span:nth-child(3) {
    font-size: 0.18rem;
    color: #999;
    margin-bottom: 4%;
    line-height: 0.3rem;
  }

  .xgyx_item4_info .xgyx_info4 span:nth-child(4) {
    font-size: 0.24rem;
  }

  .blue2 {
    color: #5383ec !important;
    font-size: 0.3rem !important;
    font-family: MiSans-Bold !important;
    display: inline !important;
  }

  .el-carousel--horizontal {
    overflow-x: hidden;
    width: 110%;
    height: auto;
    margin-top: 71px;
    margin-left: -5%;
    margin-right: -5%;
    padding: 5% 5% 0;
}
/deep/.el-carousel__container {
    position: relative;
    height: 740px!important;
    width: 100%!important;
}
/deep/.el-carousel__mask{
  display: none;
}

/deep/.el-carousel__item--card.is-in-stage{
  cursor: default;
  border-bottom-left-radius: 0.15rem;
    border-bottom-right-radius: 0.15rem;
}
/deep/.el-carousel__arrow{
  display: block!important;
  transition: none!important;
  transform: none!important;
}
/deep/.el-carousel__arrow--right{
  right: -35px;
  height: 45px;
  width: 45px;
  font-size: 16px;
}
/deep/.el-carousel__arrow--left{
  left: -61px;
  height: 45px;
  width: 45px;
  font-size: 16px;
}

  .xgyx_item4_text,
  .el-carousel__item {
    display: flex;
    justify-content: space-between;
    margin: 0% 0 -2%;
    height: 740px;
    width: 32%;
    margin-right: 1%;
    transform: scale(1)!important;

    .xgyx_item4_textdiv {
      background-color: #f3f7fe;
      border-radius: 0.15rem;

      .xgyx_item4_textspan {
        padding: 9% 6%;
        height: 33%;
        padding-bottom: 0;

        span {
          display: block;
        }

        span:nth-child(1) {
          font-size: 0.26rem;
          font-family: MiSans-Bold;
        }

        span:nth-child(2) {
          font-size: 0.22rem;
          font-family: MiSans-Normal;
          color: #999;
          padding-bottom: 5%;
          border-bottom: 1px solid #ccc;
        }

        span:nth-child(3) {
          color: #666;
          font-size: 0.18rem;
          line-height: 0.3rem;
          padding-top: 5%;
          font-family: MiSans-Normal;
        }
      }

      .xgyx_item4_textimg {
        padding: 9% 6%;
        height: 53%;
        // background-image: url(../assets/image/02web端切图（1X）/web_banner18.png);
        background-size: 100%;
        // background-position: 0 -0px;
        background-repeat: no-repeat;
        border-bottom-left-radius: 0.15rem;
        border-bottom-right-radius: 0.15rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  // .xgyx_item4_text 

  // .xgyx_item4_text .xgyx_item4_textdiv 

  // .xgyx_item4_text .xgyx_item4_textdiv .xgyx_item4_textspan 

  // .xgyx_item4_text .xgyx_item4_textdiv .xgyx_item4_textspan 

  // .xgyx_item4_text .xgyx_item4_textdiv .xgyx_item4_textspan 

  // .xgyx_item4_text .xgyx_item4_textdiv .xgyx_item4_textspan 

  // .xgyx_item4_text .xgyx_item4_textdiv 

  .textinfo {
    width: auto;
    // text-align: center;
    padding-left: 6%;
    padding-bottom: 20px;
  }

  .imgtext {
    display: block;
    // color: #fff;
    font-family: MiSans-Normal;
    font-size: 0.24rem;
  }

  .xgyx_item4_text {
    img {
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
    }

    img:nth-child(1) {
      top: 55%;
      left: -5%;
      transform: rotate(180deg);
    }

    img:nth-child(5) {
      top: 55%;
      right: -5%;
    }

    img:hover {
      scale: 1.2;
    }
  }
}
</style>