<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="lxwm">
      <!-- <div style="">....</div> -->
      <div class="lxwm_item">
        <img :src="contactUs.web.img_1" alt="">
        <div class="lxwm_info">
          <span>{{ contactUs.web.item_1 }}</span>
          <span>{{ contactUs.web.item_2 }}</span>
          <span>{{ contactUs.web.item_3 }}</span>
        </div>
      </div>
      <div class="lxwm_item2">
        <div class="lxwm_info2" v-for="item in contact" :key="item.num">
          <img :src="item.imgurl" alt="">
          <div class="lxwm_info2_text">
            <span>{{ item.area1 }}</span>
            <div class="lxwm_info2_textdiv">
              <img :src="item.imgurl1" alt="">
              <span>
                {{ item.info1 }}
              </span>
            </div>
            <div class="lxwm_info2_textdiv">
              <img :src="item.imgurl2" alt="">
              <span>{{ item.info2 }}</span>
            </div>
            <div class="lxwm_info2_textdiv">
              <img :src="item.imgurl3" alt="">
              <span>{{ item.info3 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import { contactUs } from "../config/environment";
  export default {
    data(){
      return{
        contactUs,

        // 联系我们
        contact:[
          {
            num:1,
            imgurl: contactUs.web.img_2,
            area1: contactUs.web.item_4,
            imgurl1: contactUs.web.img_3,
            info1: contactUs.web.item_5,
            imgurl2: contactUs.web.img_4,
            info2: contactUs.web.item_6,
            imgurl3: contactUs.web.img_5,
            info3: contactUs.web.item_7,
          },
          {
            num:2,
            imgurl: contactUs.web.img_6,
            area1: contactUs.web.item_8,
            imgurl1: contactUs.web.img_7,
            info1: contactUs.web.item_9,
            imgurl2: contactUs.web.img_8,
            info2: contactUs.web.item_10,
            imgurl3: contactUs.web.img_9,
            info3: contactUs.web.item_11,
          },
          {
            num:3,
            imgurl: contactUs.web.img_10,
            area1: contactUs.web.item_12,
            imgurl1: contactUs.web.img_11,
            info1: contactUs.web.item_13,
            imgurl2: contactUs.web.img_12,
            info2: contactUs.web.item_14,
            imgurl3: contactUs.web.img_13,
            info3: contactUs.web.item_15,
          },
        ]
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
   // 屏幕大于 750px 时应用该样式 
@media screen and (min-width: 751px) {
  .lxwm{
    margin-top:1.01rem;
  }
  .lxwm_item .lxwm_info{
    width: 49%;
  }
  .lxwm .lxwm_info {
    span:nth-child(2){
      font-size: 0.32rem;
    }
    span:nth-child(3){
      font-size: 0.18rem;
    }
  }
  .lxwm_item2{
    padding: 5% 6%;
    .lxwm_info2 img{
      width: 50%;
      border-top-left-radius: 0.15rem;
      border-bottom-left-radius: 0.15rem;
    }
    .lxwm_info2_text{
      width: 60%;
    }
  }
}

/* // 屏幕小于 750px 时应用该样式 */
@media screen and (max-width: 750px) {
  .lxwm_item img:nth-child(1){
    height:2.5rem;
  }
  .lxwm .lxwm_info {
    span:nth-child(1) {
        font-size: 0.28rem!important;
        margin-top:15%;
      }
      span:nth-child(2) {
        font-size: 0.18rem!important;
      }

      span:nth-child(3) {
        font-size: 0.14rem!important;
        margin-top:5%;
      }
  }
  .lxwm_item2{
    padding: 8%;
    .lxwm_info2{
      flex-direction: column;
      img{
        border-top-left-radius: 0.15rem;
        border-top-right-radius: 0.15rem;
      }
    }
    .lxwm_info2_text{
      padding-left: 10%;
      padding-bottom: 8%;
      span:nth-child(1){
        font-size: 0.24rem!important;
      }
      .lxwm_info2_textdiv{
        img{
          width:0.2rem!important;
          height:0.2rem!important;
          margin-right:0.1rem!important;
        }
        span{
          font-size: 0.14rem!important;
        }
      } 
    }
  }
}
  .lxwm img{
  width: 100%;
  display: block;
}

.lxwm .lxwm_info{
  span:nth-child(1){
    display: block;
    color: #fff;
    font-family: MiSans-Bold;
    font-size: 0.4rem;
  }
   span:nth-child(2){
    color: #5383ec;
    font-family: MiSans-Bold;
    display: block;
  }
   span:nth-child(3){
    display: block;
    font-family: MiSans-Normal;
    color: #ccc;
    letter-spacing: 2px;
  }
} 
.lxwm_item{
  position: relative;
}
.lxwm_item .lxwm_info{
  position: absolute;
  top: 20%;
  left:8%;
  width: 49%;
}
.lxwm_item2 {
  width:100%;

  .lxwm_info2{
  width:100%;
  background-color: #f6f6f6;
  display: flex;
  margin:0;
  border-radius: 0.15rem;
  margin-bottom: 0.4rem;
}
.lxwm_info2 img{
  margin-right: 5%;
}
.lxwm_info2_text span:nth-child(1){
  font-size: 0.35rem;
  font-family: MiSans-Bold;
  color:black;
  margin-top: 7%;
    display: block;
}
.lxwm_info2_textdiv{
  display: flex;
  color: #666;
  margin-top:0.15rem;
  font-size: 0.18rem;
}
.lxwm_info2 .lxwm_info2_textdiv img {
  width: 0.25rem;
  height:0.25rem;
  margin-right:0.2rem;
  vertical-align: middle;
}
}

  </style>