import Vue from 'vue'
import App from './App.vue'
import './assets/css/reset.css'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// eslint-disable-next-line no-unused-vars
import jquery from 'jquery';

Vue.use(ElementUI);

Vue.config.productionTip = false

// 路由跳转时定位到页面顶部
router.beforeEach((to, from, next) => {    
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})


// eslint-disable-next-line no-undef
Vue.prototype.jquery = $

new Vue({
  router,
  el: '#app',
  render: h => h(App),
}).$mount('#app')
