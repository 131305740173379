<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="xgyx">
    <!-- 直播与短视频解决方案 -->
    <div class="xgyx_item">
      <img :src="liveVideo.web.img_1" alt="">
      <div class="xgyx_info">
        <span>{{ liveVideo.web.item_1 }}</span>
        <span>{{ liveVideo.web.item_2 }}</span>
      </div>
    </div>
    <!-- 媒体即卖场 -->
    <div class="xgyx_item2">
      <div class="xgyx_info2">
        <span>{{ liveVideo.web.item_3 }}</span>
        <span>{{ liveVideo.web.item_4 }}</span>
        <div class="xgyx_info2_div">
          <span>{{ liveVideo.web.item_5 }}</span>
          <span>{{ liveVideo.web.item_15 }}</span>
          <span>{{ liveVideo.web.item_6 }}</span>
        </div>
      </div>
      <img :src="liveVideo.web.img_2" alt="">
    </div>
    <!-- 媒体即卖场  移动端 -->
    <div class="xgyx_item2_mobile">
      <div class="xgyx_info2_mobile">
        <span>{{ liveVideo.mobile.item_1 }}</span>
        <span>{{ liveVideo.mobile.item_2 }}</span>
        <span></span>
        <span></span>
      </div>
      <img :src="liveVideo.web.img_3" alt="">
      <div class="xgyx_info2_div_mobile">
        <span>{{ liveVideo.mobile.item_55 }}</span>
        <span>{{ liveVideo.mobile.item_3 }}</span>
        <span>{{ liveVideo.mobile.item_4 }}</span>
      </div>
    </div>
    <!-- 服务 -->
    <div class="ppyx_item3" :style="{ 'background-image': 'url(' + liveVideo.web.img_17 + ')' }">
      <div class="ppyx_info3">
        <span></span>
        <span>{{ liveVideo.web.item_7 }}</span>
        <span>{{ liveVideo.web.item_8 }}</span>
      </div>
      <!-- 服务 移动端  -->
      <div class="ppyx_info3_mobile">
        <span>{{ liveVideo.mobile.item_5 }}</span>
        <span>{{ liveVideo.mobile.item_6 }}</span>
        <span></span>
        <span></span>
      </div>
      <div class="ppyx_info3_text">
         <!-- eslint-disable-next-line vue/require-v-for-key -->
        <div class="ppyx_info3_textdiv" v-for="ite in servers" :key="ite.num">
          <img :src="ite.imgurl" alt="">
          <div class="ppyx_info3_span">
            <span>{{ ite.name }}</span>
            <span>{{ ite.info }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 短视频 -->
    <div class="ppyx_item6">
      <div class="ppyx_info6">
        <span></span>
        <span>{{ liveVideo.web.item_9 }}</span>
        <span>{{ liveVideo.web.item_10 }}</span>
      </div>
      <!-- 短视频 yidong -->
      <div class="ppyx_info6_mobile">
        <span>{{ liveVideo.mobile.item_7 }}</span>
        <span>{{ liveVideo.mobile.item_8 }}</span>
        <span></span>
        <span></span>
      </div>
      <div class="ppyx_info6_img">
        <div class="ppyx_info6_imgdiv" v-for="item in vedio" :key="item.num">
          <img :src="item.imgurl" alt="">
          <div class="blueCon">
            <span class="blue6">{{ item.size }}</span><span class="blue6 con">{{ item.size1 }}</span>
          </div>
          <span class="dujia">{{ item.text }}</span>
        </div>
      </div>
    </div>
    <!-- 多类型演员与主播 -->
    <div class="ppyx_item5">
      <div class="ppyx_info5">
        <span></span>
        <span>{{ liveVideo.web.item_11 }}</span>
        <span>{{ liveVideo.web.item_12 }}</span>
      </div>
      <!-- 多类型演员与主播 yidong -->
      <div class="ppyx_info5_mobile">
        <span>{{ liveVideo.mobile.item_9 }}</span>
        <span>{{ liveVideo.mobile.item_10 }}</span>
        <span></span>
        <span></span>
      </div>
      <div class="ppyx_info5_img">
        <img :src="liveVideo.web.img_4" alt="">
        <img :src="liveVideo.web.img_5" alt="">
        <img :src="liveVideo.web.img_6" alt="">
        <img :src="liveVideo.web.img_7" alt="">
        <img :src="liveVideo.web.img_8" alt="">
        <img :src="liveVideo.web.img_9" alt="">
        <img :src="liveVideo.web.img_10" alt="">
        <img :src="liveVideo.web.img_11" alt="">
      </div>
    </div>
    <!-- 案例 -->
    <div class="ppyx_item4">
      <div class="ppyx_in">
        <span></span>
        <span>{{ liveVideo.web.item_13 }}</span>
        <span>{{ liveVideo.web.item_14 }}</span>
      </div>
      <!-- 案例   移动端-->
      <div class="ppyx_in_mobile">
        <span>{{ liveVideo.mobile.item_11 }}</span>
        <span>{{ liveVideo.mobile.item_12 }}</span>
        <span></span>
        <span></span>
      </div>
      <!-- 案例   移动端 -->
      <div class="ppyx_item4_info_mobile" v-for="item in cases" :key="item.num">
        <div class="ppyx_info4">
          <span>{{ item.name }}</span>
          <span>{{ item.itter }}</span>
          <span>{{ item.info }}</span>
          <span>{{ item.sub_1 }}<span class="blue2"> {{ item.pu }}</span> </span>
          <span>{{ item.sub_2 }}<span class="blue2"> {{ item.hu }}</span></span>
          <!-- <img src="../assets/image/02web端切图（1X）/web_icon11.png" alt=""> -->
        </div>
        <img :src="item.imgurl" alt="">
      </div>
      <!-- 案例 first web -->
      <div class="ppyx_item4_info">
        <div class="ppyx_info4">
          <span>{{liveVideo.web.item_16}}</span>
          <span>{{liveVideo.web.item_17}}</span>
          <span>{{liveVideo.web.item_18}}</span>
          <span>{{liveVideo.web.item_19}}<span class="blue2"> {{liveVideo.web.item_20}}</span> {{liveVideo.web.item_21}}<span class="blue2"> {{liveVideo.web.item_22}}</span></span>
          <!-- <img :src="liveVideo.web.img_12" alt=""> -->
        </div>
        <img :src="liveVideo.web.img_13" alt="">
      </div>

      <el-carousel @change="carouselChange" arrow="always" ref="carousel" :initial-index="1" type="card" :autoplay="false" :loop="false" indicator-position="none">
          <el-carousel-item id="0">
            <div class="ppyx_item4_textdiv">
          <div class="ppyx_item4_textspan">
            <span>{{liveVideo.web.item_23}}</span>
            <span>{{liveVideo.web.item_24}}</span>
            <span>{{liveVideo.web.item_25}}</span>
          </div>
          <div class="textinfo">
              <span class="imgtext">{{liveVideo.web.item_26}}<span class="blue2"> {{liveVideo.web.item_27}}</span> </span>
              <span class="imgtext">{{liveVideo.web.item_28}}<span class="blue2"> {{liveVideo.web.item_29}}</span></span>
            </div>
          <div class="ppyx_item4_textimg" :style="{ 'background-image': 'url(' + liveVideo.mobile.img_13 + ')' }">
            
          </div>
        </div>
          </el-carousel-item>
          <el-carousel-item id="1">
            <div class="ppyx_item4_textdiv">
          <div class="ppyx_item4_textspan">
            <span>{{liveVideo.web.item_30}}</span>
            <span>{{liveVideo.web.item_31}}</span>
            <span>{{liveVideo.web.item_32}}</span>
          </div>
          <div class="textinfo">
              <span class="imgtext">{{liveVideo.web.item_33}}<span class="blue2"> {{liveVideo.web.item_34}}</span> </span>
              <span class="imgtext">{{liveVideo.web.item_35}}<span class="blue2"> {{liveVideo.web.item_36}}</span></span>
            </div>
          <div class="ppyx_item4_textimg" :style="{ 'background-image': 'url(' + liveVideo.mobile.img_14 + ')' }">
            
          </div>
        </div>
          </el-carousel-item>
          <el-carousel-item id="2">
            <div class="ppyx_item4_textdiv">
          <div class="ppyx_item4_textspan">
            <span>{{liveVideo.web.item_37}}</span>
            <span>{{liveVideo.web.item_38}}</span>
            <span>{{liveVideo.web.item_39}}</span>
          </div>
          <div class="textinfo">
              <span class="imgtext">{{liveVideo.web.item_40}}<span class="blue2"> {{liveVideo.web.item_41}}</span> </span>
              <span class="imgtext">{{liveVideo.web.item_42}}<span class="blue2"> {{liveVideo.web.item_43}}</span></span>
            </div>
          <div class="ppyx_item4_textimg" :style="{ 'background-image': 'url(' + liveVideo.mobile.img_15 + ')' }">
            
          </div>
        </div>
          </el-carousel-item>
          <el-carousel-item id="3">
            <div class="ppyx_item4_textdiv">
          <div class="ppyx_item4_textspan">
            <span>{{liveVideo.web.item_44}}</span>
            <span>{{liveVideo.web.item_45}}</span>
            <span>{{liveVideo.web.item_46}}</span>
          </div>
          <div class="textinfo">
              <span class="imgtext">{{liveVideo.web.item_47}}<span class="blue2"> {{liveVideo.web.item_48}}</span> </span>
              <span class="imgtext">{{liveVideo.web.item_49}}<span class="blue2"> {{liveVideo.web.item_50}}</span></span>
            </div>
          <div class="ppyx_item4_textimg" :style="{ 'background-image': 'url(' + liveVideo.mobile.img_16 + ')' }">
            
          </div>
        </div>
          </el-carousel-item>
        </el-carousel>


      <!-- <div class="ppyx_item4_text">
        <img :src="liveVideo.web.img_15" alt="">
        <div class="ppyx_item4_textdiv">
          <div class="ppyx_item4_textspan">
            <span>{{liveVideo.web.item_23}}</span>
            <span>{{liveVideo.web.item_24}}</span>
            <span>{{liveVideo.web.item_25}}</span>
          </div>
          <div class="textinfo">
              <span class="imgtext">{{liveVideo.web.item_26}}<span class="blue2"> {{liveVideo.web.item_27}}</span> </span>
              <span class="imgtext">{{liveVideo.web.item_28}}<span class="blue2"> {{liveVideo.web.item_29}}</span></span>
            </div>
          <div class="ppyx_item4_textimg" :style="{ 'background-image': 'url(' + liveVideo.mobile.img_13 + ')' }">
            
          </div>
        </div>
        <div class="ppyx_item4_textdiv">
          <div class="ppyx_item4_textspan">
            <span>{{liveVideo.web.item_30}}</span>
            <span>{{liveVideo.web.item_31}}</span>
            <span>{{liveVideo.web.item_32}}</span>
          </div>
          <div class="textinfo">
              <span class="imgtext">{{liveVideo.web.item_33}}<span class="blue2"> {{liveVideo.web.item_34}}</span> </span>
              <span class="imgtext">{{liveVideo.web.item_35}}<span class="blue2"> {{liveVideo.web.item_36}}</span></span>
            </div>
          <div class="ppyx_item4_textimg" :style="{ 'background-image': 'url(' + liveVideo.mobile.img_14 + ')' }">
            
          </div>
        </div>
        <div class="ppyx_item4_textdiv">
          <div class="ppyx_item4_textspan">
            <span>{{liveVideo.web.item_37}}</span>
            <span>{{liveVideo.web.item_38}}</span>
            <span>{{liveVideo.web.item_39}}</span>
          </div>
          <div class="textinfo">
              <span class="imgtext">{{liveVideo.web.item_40}}<span class="blue2"> {{liveVideo.web.item_41}}</span> </span>
              <span class="imgtext">{{liveVideo.web.item_42}}<span class="blue2"> {{liveVideo.web.item_43}}</span></span>
            </div>
          <div class="ppyx_item4_textimg" :style="{ 'background-image': 'url(' + liveVideo.mobile.img_15 + ')' }">
            
          </div>
        </div>
        <img :src="liveVideo.web.img_16" alt="">
      </div> -->
    </div>
  </div>
</template>

<script>
import { coreBusiness } from "../config/environment";
export default {
  data() {
    return {
      liveVideo: coreBusiness.liveVideo,
      // 服务
      servers:[
        {
          num:1,
          imgurl: coreBusiness.liveVideo.mobile.img_1,
          name: coreBusiness.liveVideo.mobile.item_15,
          info: coreBusiness.liveVideo.mobile.item_16,
        },
        {
          num:2,
          imgurl: coreBusiness.liveVideo.mobile.img_2,
          name: coreBusiness.liveVideo.mobile.item_17,
          info: coreBusiness.liveVideo.mobile.item_18,
        },
        {
          num:3,
          imgurl: coreBusiness.liveVideo.mobile.img_3,
          name: coreBusiness.liveVideo.mobile.item_19,
          info: coreBusiness.liveVideo.mobile.item_20,
        },
        {
          num:4,
          imgurl: coreBusiness.liveVideo.mobile.img_4,
          name: coreBusiness.liveVideo.mobile.item_21,
          info: coreBusiness.liveVideo.mobile.item_22,
        }
      ],
      // 案例 移动端
      cases: [
        {
          num: 1,
          name: coreBusiness.liveVideo.mobile.item_23,
          itter: coreBusiness.liveVideo.mobile.item_24,
          info: coreBusiness.liveVideo.mobile.item_25,
          pu: coreBusiness.liveVideo.mobile.item_26,
          hu: coreBusiness.liveVideo.mobile.item_27,
          imgurl: coreBusiness.liveVideo.mobile.img_5,
          sub_1: coreBusiness.liveVideo.mobile.item_56,
          sub_2: coreBusiness.liveVideo.mobile.item_57,
        },
        {
          num: 2,
          name: coreBusiness.liveVideo.mobile.item_28,
          itter: coreBusiness.liveVideo.mobile.item_29,
          info: coreBusiness.liveVideo.mobile.item_30,
          pu: coreBusiness.liveVideo.mobile.item_31,
          hu: coreBusiness.liveVideo.mobile.item_32,
          imgurl: coreBusiness.liveVideo.mobile.img_6,
          sub_1: coreBusiness.liveVideo.mobile.item_58,
          sub_2: coreBusiness.liveVideo.mobile.item_59,
        },
        {
          num: 3,
          name: coreBusiness.liveVideo.mobile.item_33,
          itter: coreBusiness.liveVideo.mobile.item_34,
          info: coreBusiness.liveVideo.mobile.item_35,
          pu: coreBusiness.liveVideo.mobile.item_36,
          hu: coreBusiness.liveVideo.mobile.item_37,
          imgurl: coreBusiness.liveVideo.mobile.img_7,
          sub_1: coreBusiness.liveVideo.mobile.item_60,
          sub_2: coreBusiness.liveVideo.mobile.item_61,
        },
        {
          num: 4,
          name: coreBusiness.liveVideo.mobile.item_38,
          itter: coreBusiness.liveVideo.mobile.item_39,
          info: coreBusiness.liveVideo.mobile.item_40,
          pu: coreBusiness.liveVideo.mobile.item_41,
          hu: coreBusiness.liveVideo.mobile.item_42,
          imgurl: coreBusiness.liveVideo.mobile.img_8,
          sub_1: coreBusiness.liveVideo.mobile.item_62,
          sub_2: coreBusiness.liveVideo.mobile.item_63,
        },
        {
          num: 5,
          name: coreBusiness.liveVideo.mobile.item_64,
          itter: coreBusiness.liveVideo.mobile.item_65,
          info: coreBusiness.liveVideo.mobile.item_66,
          pu: coreBusiness.liveVideo.mobile.item_68,
          hu: coreBusiness.liveVideo.mobile.item_70,
          imgurl: coreBusiness.liveVideo.mobile.img_16,
          sub_1: coreBusiness.liveVideo.mobile.item_67,
          sub_2: coreBusiness.liveVideo.mobile.item_69,
        }
      ],
      // 短视频
      vedio:[
        {
          num:1,
          imgurl: coreBusiness.liveVideo.mobile.img_9,
          size: coreBusiness.liveVideo.mobile.item_43,
          size1: coreBusiness.liveVideo.mobile.item_44,
          text: coreBusiness.liveVideo.mobile.item_45,
        },
        {
          num:2,
          imgurl: coreBusiness.liveVideo.mobile.img_10,
          size: coreBusiness.liveVideo.mobile.item_46,
          size1: coreBusiness.liveVideo.mobile.item_47,
          text: coreBusiness.liveVideo.mobile.item_48,
        },
        {
          num:3,
          imgurl: coreBusiness.liveVideo.mobile.img_11,
          size: coreBusiness.liveVideo.mobile.item_49,
          size1: coreBusiness.liveVideo.mobile.item_50,
          text: coreBusiness.liveVideo.mobile.item_51,
        },
        {
          num:4,
          imgurl: coreBusiness.liveVideo.mobile.img_12,
          size: coreBusiness.liveVideo.mobile.item_52,
          size1: coreBusiness.liveVideo.mobile.item_53,
          text: coreBusiness.liveVideo.mobile.item_54,
        }
      ]
    }
  },
  mounted(){
		this.setPY(1);
	},
  methods:{
		carouselChange:function(now){
      console.log(now,"====new");
			this.setPY(now);
		},
		setPY(now){
      if(now == 0){
        // eslint-disable-next-line no-undef
        $("#1").css("left","33%");
        // eslint-disable-next-line no-undef
        $("#0").css("left","0%");
        // eslint-disable-next-line no-undef
        $("#1").next().css("left","66%");
      }
      else{
        // eslint-disable-next-line no-undef
        $("#"+ now).css("left","33%");
        // eslint-disable-next-line no-undef
        $("#"+ now).prev().css("left","0%");
        // eslint-disable-next-line no-undef
        $("#" +now).next().css("left","66%");
      }
		},
	}
}
</script>

<style lang="less" scoped>
// 屏幕大于 750px 时应用该样式 
@media screen and (min-width: 751px) {
  .xgyx_item {
    display: block;
  }

  .xgyx_item2 {
    display: block;
  }

  .xgyx_item2_mobile {
    display: none !important;
  }
  .ppyx_item3{
    // background-image: url(../assets/image/02web端切图（1X）/web_banner04.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .ppyx_item4 {
    padding: 6%;
  }

  .ppyx_info3,
  .ppyx_in ,
  .ppyx_info5,
  .ppyx_info6 {
    display: block;
  }

  .ppyx_info3_mobile,
  .ppyx_in_mobile,
  .ppyx_item4_info_mobile ,
  .ppyx_info5_mobile,
  .ppyx_info6_mobile {
    display: none;
  }
  .ppyx_info3_text {
    display: flex;
    justify-content: space-between;

    .ppyx_info3_textdiv {
      // display: flex;
      // flex-direction: column;
      width: 32%;
      // border-radius: 15px;
      margin-right: 1%;
      background-color: #f6f6f6;

      .ppyx_info3_span span:nth-child(1) {
        font-size: 0.26rem;
        font-family: MiSans-Normal;
        padding-top: 6%;
        padding-bottom: 9%;
      }

      .ppyx_info3_span span:nth-child(2) {
        font-family: MiSans-Bold;
        font-size: 0.18rem;
        color: #666;
      }
    }
  }

  .ppyx_item4_info,
  .el-carousel--horizontal {
    display: block;
  }
  .ppyx_info6_imgdiv {
    width: 23%;
  }
}

/* // 屏幕小于 750px 时应用该样式 */
@media screen and (max-width: 750px) {
  .xgyx_item {
    display: none;
  }

  .xgyx_item2 {
    display: none !important;
  }

  .xgyx_item2_mobile {
    display: block !important;
  }

  .ppyx_item3 {
    padding-bottom: 0 !important;
    background-color: #f6f6f6!important;
  }

  .ppyx_info3,
  .ppyx_in,
  .ppyx_item4_info,
  .el-carousel--horizontal ,
  .ppyx_info5,
  .ppyx_info6 {
    display: none !important;
  }

  .ppyx_info3_mobile,
  .ppyx_in_mobile ,
  .ppyx_info5_mobile ,
  .ppyx_info6_mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6%;

    span:nth-child(1) {
      font-family: MiSans-Bold;
      font-size: 0.28rem;
    }

    span:nth-child(3) {
      width: 50%;
      display: block;
      height: 0.02rem;
      background-color: #999;
      margin: 0.14rem 0;
    }

    span:nth-child(4) {
      width: 14%;
      display: block;
      height: 0.08rem;
      border-radius: 0.04rem;
      background-color: #5383ec;
      margin-top: -0.18rem;
      margin-bottom: 0.14rem;
    }

    span:nth-child(2) {
      font-size: 0.18rem;
      letter-spacing: 2px;
      color: #666;
    }
  }

  .ppyx_info3_text {
    padding: 5%;

    .ppyx_info3_textdiv {
      width: 100%;
      margin-bottom: 5%;
      background-color: #fff;

      .ppyx_info3_span span:nth-child(1) {
        font-size: 0.24rem;
        font-family: MiSans-Bold;
        // padding-top: 6%;
        padding-bottom: 6%;
      }

      .ppyx_info3_span span:nth-child(2) {
        font-family: MiSans-Normal;
        font-size: 0.12rem;
        color: #666;
      }
    }
  }

  .ppyx_item4 {
    padding: 10%;
  }

  .ppyx_item4_info_mobile {
    display: block;
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6;
    border-radius: 0.15rem;
    margin-bottom: 5%;

    .ppyx_info4 {
      display: flex;
      flex-direction: column;
      padding: 7%;

      span:nth-child(1) {
        font-family: MiSans-Bold;
        font-size: 0.28rem;
      }

      span:nth-child(2) {
        color: #9c9c9c;
        font-family: MiSans-Normal;
        font-size: 0.2rem;
        padding-bottom: 4%;
        border-bottom: 1px solid #ccc;
      }

      span:nth-child(3) {
        font-size: 0.16rem;
        color: #999;
        margin-bottom: 4%;
        padding-top: 4%;
        line-height: 0.3rem;
      }

      span:nth-child(4),
      span:nth-child(5) {
        font-size: 0.24rem;
      }

      .blue2 {
        font-size: 0.3rem !important;
      }
    }

    img {
      height: 40%;
      width: 100%;
      border-bottom-left-radius: 0.15rem;
      border-bottom-right-radius: 0.15rem;
    }
  }
  .ppyx_info6_imgdiv {
    width: 48%;
    margin-bottom:5%;
  }
  .blueCon{
    margin-top:-0.7rem;
    .blue6,.con{
      font-size: 0.24rem!important;
    }
  }
  
  .dujia{
    font-size: 0.18rem!important;
  }
}

/* 直播与短视频解决方案 */
.xgyx{
  img {
  width: 100%;
  display: block;
}

.xgyx_info span:nth-child(1) {
  display: block;
  color: #fff;
  font-family: MiSans-Bold;
  font-size: 0.47rem;
}

.xgyx_info span:nth-child(2) {
  color: #5383ec;
  font-family: MiSans-Bold;
  display: block;
  font-size: 0.32rem;
}

.xgyx_item {
  position: relative;
}

.xgyx_item .xgyx_info {
  position: absolute;
  top: 18%;
  left: 8%;
  width: 49%;
}
} 

/* 媒体即卖场 */
.xgyx_item2 {
  width: 100%;
  padding: 6%;
  display: flex;
  justify-content: space-between;

  .xgyx_info2 span {
    display: block;
  }

  .xgyx_info2 span:nth-child(1) {
    font-family: MiSans-Bold;
    color: black;
    font-size: 0.5rem;
  }

  img {
    width: 42%;
    border-radius: 0.15rem;
  }

  .xgyx_info2 {
    width: 50%;
  }

  .xgyx_info2 span:nth-child(2) {
    font-family: MiSans-Normal;
    font-size: 0.28rem;
    color: #666;
    /* line-height: 40px; */
    /* width: 57%; */
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .xgyx_info2 .xgyx_info2_div {
    display: flex;
    justify-content: space-between;
  }

  .xgyx_info2 .xgyx_info2_div span {
    font-size: 0.17rem;
    color: black !important;
    font-family: 'MiSans-Normal';
    background-color: #f6f6f6;
    border-radius: 0.15rem;
    // text-align: center;
    width: 48%;
    padding: 5%;
    letter-spacing: 2px;
    line-height: 0.3rem;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 20px;
  }
}

/* 内容+运营+数据 移动端 */
.xgyx_item2_mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10%;

  .xgyx_info2_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6% 0;

    span:nth-child(1) {
      font-family: MiSans-Bold;
      font-size: 0.28rem;
    }

    span:nth-child(3) {
      width: 50%;
      display: block;
      height: 0.02rem;
      background-color: #999;
      margin: 0.14rem 0;
    }

    span:nth-child(4) {
      width: 14%;
      display: block;
      height: 0.08rem;
      border-radius: 0.04rem;
      background-color: #5383ec;
      margin-top: -0.18rem;
      margin-bottom: 0.14rem;
    }

    span:nth-child(2) {
      font-size: 0.18rem;
      letter-spacing: 2px;
      color: #666;
    }
  }

  img {
    border-radius: 0.15rem;
    margin-bottom: 5%;
  }

  .xgyx_info2_div_mobile {
    display: flex;
    flex-direction: column;
    font-size: 0.18rem;
    font-family: MiSans-Normal;
    line-height: 0.4rem;
    letter-spacing: 2px;
  }
}

/* 服务 */
.ppyx_item3 {
  width: 100%;
  padding: 7%;
  // background-image: url(../assets/image/02web端切图（1X）/web_banner04.png);
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
}

.ppyx_item5 {
  width: 100%;
  padding: 6% 7%;
  background-color: #f6f6f6;
}

.ppyx_item6 {
  width: 100%;
  padding: 6% 7%;
}

.ppyx_item5 .ppyx_info5_img {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 3%;
}

.ppyx_item5 .ppyx_info5_img img {
  width: 24%;
  height: auto;
  margin-right: 1%;
  margin-bottom: 1%;
  border-radius: 0.15rem;
}

.ppyx_info6_img {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3%;
}

.ppyx_info6_img .ppyx_info6_imgdiv {
  // width: 23%;
  margin-right: 2%;
  padding: 5% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f3f7fe;
  border-radius: 0.15rem;
}

.ppyx_info6_img .ppyx_info6_imgdiv img {
  width: 35%;
  margin-bottom: 4%;
}

.blue6 {
  font-size: 0.37rem;
  font-family: 'MiSans-Bold';
}

.con {
  color: #5383ec;
}

.dujia {
  font-size: 0.3rem;
  color: #666;
}

.ppyx_item3 .ppyx_info3 span:nth-child(1),
.ppyx_item4 .ppyx_in span:nth-child(1),
.ppyx_item5 .ppyx_info5 span:nth-child(1),
.ppyx_item6 .ppyx_info6 span:nth-child(1) {
  width: 0.07rem;
  height: 0.4rem;
  background-color: #5383ec;
  display: inline-block;
  border-radius: 0.04rem;
  margin-right: 0.1rem;
}

.ppyx_item3 .ppyx_info3 span:nth-child(2),
.ppyx_item4 .ppyx_in span:nth-child(2),
.ppyx_item5 .ppyx_info5 span:nth-child(2),
.ppyx_item6 .ppyx_info6 span:nth-child(2) {
  font-family: MiSans-Bold;
  color: black;
  font-size: 0.45rem;
}

.ppyx_item3 .ppyx_info3 span:nth-child(3),
.ppyx_item4 .ppyx_in span:nth-child(3),
.ppyx_item5 .ppyx_info5 span:nth-child(3),
.ppyx_item6 .ppyx_info6 span:nth-child(3) {
  font-family: MiSans-Bold;
  color: #999;
  font-size: 0.3rem;
  margin-left: 0.2rem;
}

.ppyx_item3 .ppyx_info3_text {
  // display: flex;
  // justify-content: space-between;
  margin-top: 5%;
}

.ppyx_item3 .ppyx_info3_textdiv {
  display: flex;
  flex-direction: column;
  // width: 24%;
  border-radius: 0.15rem;
  // background-color: #f6f6f6;
}

.ppyx_item3 .ppyx_info3_textdiv img {
  height: 40% !important;
  border-top-left-radius: 0.15rem;
  border-top-right-radius: 0.15rem;
  object-fit: cover;
}

.ppyx_item3 .ppyx_info3_textdiv .ppyx_info3_span {
  padding: 5% 8% 10% 8%;
  height: 60%;
}

.ppyx_item3 .ppyx_info3_textdiv .ppyx_info3_span span {
  display: block;
}

// .ppyx_item3 .ppyx_info3_textdiv .ppyx_info3_span span:nth-child(1) {
//   font-size: 26px;
//   font-family: MiSans-Normal;
//   padding-top: 6%;
//   padding-bottom: 9%;
// }

.ppyx_item3 .ppyx_info3_textdiv .ppyx_info3_span span:nth-child(2) {
  font-family: MiSans-Bold;
  font-size: 0.18rem;
  color: #666;
}

/* 案例 */
.ppyx_item4 {
  width: 100%;
  // padding: 6%;
  padding-bottom: 10%;

  .ppyx_in {
    margin-bottom: 5%;
  }

  .ppyx_item4_info {
    display: flex;
    justify-content: space-between;
    background-color: #f6f6f6;
    border-radius: 0.15rem;

    img {
      width: 42%;
      border-top-right-radius: 0.15rem;
      border-bottom-right-radius: 0.15rem;
    }

    .ppyx_info4 {
      width: 52%;
      padding: 3% 5%;
    }
  }

  .ppyx_info4 img {
    width: 0.5rem;
    height: 0.5rem;
    margin-top: 5%;
  }

  .ppyx_item4_info .ppyx_info4 span {
    display: block;
  }

  .ppyx_item4_info .ppyx_info4 span:nth-child(1) {
    font-family: MiSans-Bold;
    font-size: 0.3rem;
  }

  .ppyx_item4_info .ppyx_info4 span:nth-child(2) {
    color: #9c9c9c;
    font-family: MiSans-Normal;
    font-size: 0.26rem;
    margin-bottom: 5%;
  }

  .ppyx_item4_info .ppyx_info4 span:nth-child(3) {
    font-size: 0.18rem;
    color: #999;
    margin-bottom: 4%;
    line-height: 0.3rem;
  }

  .ppyx_item4_info .ppyx_info4 span:nth-child(4) {
    font-size: 0.24rem;
  }

  .blue2 {
    color: #5383ec !important;
    font-size: 0.3rem !important;
    font-family: MiSans-Bold !important;
    display: inline !important;
  }

  .el-carousel--horizontal {
    overflow-x: hidden;
    width: 110%;
    height: auto;
    margin-top: 71px;
    margin-left: -5%;
    margin-right: -5%;
    padding: 5% 5% 0;
}
/deep/.el-carousel__container {
    position: relative;
    height: 740px!important;
    width: 100%!important;
}
/deep/.el-carousel__mask{
  display: none;
}

/deep/.el-carousel__item--card.is-in-stage{
  cursor: default;
  border-bottom-left-radius: 0.15rem;
    border-bottom-right-radius: 0.15rem;
}
/deep/.el-carousel__arrow{
  display: block!important;
  transition: none!important;
  transform: none!important;
}
/deep/.el-carousel__arrow--right{
  right: -35px;
  height: 45px;
  width: 45px;
  font-size: 16px;
}
/deep/.el-carousel__arrow--left{
  left: -61px;
  height: 45px;
  width: 45px;
  font-size: 16px;
}

  .el-carousel__item {
    display: flex;
    justify-content: space-between;
    margin: 0% 0 -2%;
    height: 740px;
    width: 32%;
    margin-right: 1%;
    transform: scale(1)!important;

    .ppyx_item4_textdiv {
      background-color: #f3f7fe;
      border-radius: 0.15rem;

      .ppyx_item4_textspan {
        padding: 9% 6%;
        height: 33%;
        padding-bottom: 0;

        span {
          display: block;
        }

        span:nth-child(1) {
          font-size: 0.26rem;
          font-family: MiSans-Bold;
        }

        span:nth-child(2) {
          font-size: 0.22rem;
          font-family: MiSans-Normal;
          color: #999;
          padding-bottom: 5%;
          border-bottom: 1px solid #ccc;
        }

        span:nth-child(3) {
          color: #666;
          font-size: 0.18rem;
          line-height: 0.3rem;
          padding-top: 5%;
          font-family: MiSans-Normal;
        }
      }

      .ppyx_item4_textimg {
        padding: 9% 6%;
        height: 53%;
        // background-image: url(../assets/image/02web端切图（1X）/web_banner18.png);
        background-size: 100%;
        // background-position: 0 -0px;
        background-repeat: no-repeat;
        border-bottom-left-radius: 0.15rem;
        border-bottom-right-radius: 0.15rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  // .ppyx_item4_text 

  // .ppyx_item4_text .ppyx_item4_textdiv 

  // .ppyx_item4_text .ppyx_item4_textdiv .ppyx_item4_textspan 

  // .ppyx_item4_text .ppyx_item4_textdiv .ppyx_item4_textspan 

  // .ppyx_item4_text .ppyx_item4_textdiv .ppyx_item4_textspan 

  // .ppyx_item4_text .ppyx_item4_textdiv .ppyx_item4_textspan 

  // .ppyx_item4_text .ppyx_item4_textdiv 

  .textinfo {
    width: auto;
    // text-align: center;
    padding-left: 6%;
    padding-bottom: 20px;
  }

  .imgtext {
    display: block;
    // color: #fff;
    font-family: MiSans-Normal;
    font-size: 0.24rem;
  }

  .ppyx_item4_text {
    img {
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
    }

    img:nth-child(1) {
      top: 55%;
      left: -5%;
      transform: rotate(180deg);
    }

    img:nth-child(5) {
      top: 55%;
      right: -5%;
    }

    img:hover {
      scale: 1.2;
    }
  }
}
</style>